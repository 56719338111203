import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomInput from "components/CustomInput/CustomInput";
import {
  InputAdornment,
  Button,
  Fab,
  Divider,
  Typography
} from "@material-ui/core";
// import Button from "components/CustomButtons/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomAutoCompleteInput from "components/CustomAutoCompleteInput/CustomAutoCompleteInput";
import { useSelector } from "react-redux";
import { REQUEST_TYPE } from "../../../../src/constants";

const SecurityDetailsStaticInput = props => {
  const { cars } = useSelector(state => state.cars);
  const { agents } = useSelector(state => state.agents);

  return (
    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
      <GridContainer justify="flex-start">
        {props.agents.map((agent, index) => (
          <>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <CustomAutoCompleteInput
                options={agents}
                key={index + ""}
                getOptionDisabled={option =>
                  props.agents.filter(x => x.id === option.id).length > 0
                }
                filterKey="first_name"
                onSelect={obj =>
                  props.onSelectStaticTransport &&
                  props.onSelectStaticTransport("agent_transport", obj, index)
                }
                getOptionDisabled={option =>
                  props.agents.filter(x => x.id == option.id).length > 0
                }
                placeholder="search existing agents"
                autocompleteValue={{ first_name: "" }}
                optionLabel={option => {
                  if (option && option.first_name && option.last_name) {
                    let optionLabel = `${option.first_name} ${option.last_name}`;
                    if (option.phone_number)
                      optionLabel = `${optionLabel} - ${option.phone_number}`;
                    return optionLabel;
                  }
                  return "";
                }}
                id="success"
                noOptionsText="no agent found in your stock"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} style={{ marginTop: 20 }}>
              <CustomInput
                id="success"
                labelText="First Name"
                helperText={
                  agent.agentFirstNameState === "error" && "first name required"
                }
                disabled={true}
                success={agent.agentFirstNameState === "success"}
                error={agent.agentFirstNameState === "error"}
                value={agent.first_name}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} style={{ marginTop: 20 }}>
              <CustomInput
                id="success"
                labelText="Last Name"
                disabled={true}
                helperText={
                  !agent.last_name &&
                  agent.agentLastNameState === "error" &&
                  "last name required"
                }
                success={agent.agentLastNameState === "success"}
                error={!agent.last_name && agent.agentLastNameState === "error"}
                value={agent.last_name}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} style={{ marginTop: 20 }}>
              <CustomInput
                id="success"
                disabled={true}
                labelText={`Phone number ${
                  props.requestType !== REQUEST_TYPE.MONITORING
                    ? "(optional)"
                    : ""
                }`}
                helperText={
                  agent.phone_number &&
                  agent.agentPhoneNumberState === "error" &&
                  "phone number is not valid"
                }
                success={
                  agent.phone_number &&
                  agent.agentPhoneNumberState === "success"
                }
                error={
                  agent.phone_number && agent.agentPhoneNumberState === "error"
                    ? true
                    : false
                }
                value={agent.phone_number}
              />
            </GridItem>
            {agent.id && (
              <GridItem xs={12} sm={12} md={2} style={{ marginTop: 20 }}>
                <Button
                  onClick={() =>
                    props.onSelectStaticTransport(
                      "agent_transport",
                      null,
                      index
                    )
                  }
                  style={{
                    float: "right",
                    backgroundColor: "transparent",
                    color: "orange",
                    borderColor: "orange",
                    marginTop: 15
                  }}
                  size="sm"
                  variant="outlined"
                >
                  <CancelIcon fontSize="small" />
                </Button>
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}>
              {agent.agentFirstNameState === "success" &&
                agent.agentLastNameState === "success" &&
                !agent.id && (
                  <Typography
                    color="primary"
                    style={{ float: "left", fontSize: 12 }}
                    variant="body1"
                  >
                    This new agent will be added to your stock after you send
                    the request..
                  </Typography>
                )}
            </GridItem>
            {index > 0 && (
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  onClick={() => props.onRemoveStaticAgent(index)}
                  style={{
                    float: "left",
                    backgroundColor: "transparent",
                    color: "#F44336",
                    borderColor: "#F44336",
                    marginTop: 15
                  }}
                  size="sm"
                  variant="outlined"
                >
                  Remove agent
                  <DeleteIcon fontSize="small" />
                </Button>
              </GridItem>
            )}
          </>
        ))}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          <Button
            onClick={props.onAddStaticAgent}
            style={{
              float: "left"
            }}
            color="primary"
            variant="contained"
          >
            Add new Agent
          </Button>
        </GridItem>
        <Divider />
        <GridItem style={{ marginTop: 20 }}>
          <Typography style={{ fontSize: 16 }} variant="h6">
            Security cars details (Optional)
          </Typography>
        </GridItem>
        {props.cars.map((car, index) => (
          <>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
              <CustomAutoCompleteInput
                id="success"
                autocompleteValue={{
                  plate_number: ""
                }}
                optionLabel={option => {
                  if (option) {
                    let optionLabel = option.plate_number;
                    if (option.brand)
                      optionLabel = `${optionLabel} - ${option.brand}`;
                    if (option.color)
                      optionLabel = `${optionLabel} - ${option.color}`;

                    return optionLabel;
                  }
                }}
                filterKey="plate_number"
                onSelect={obj =>
                  props.onSelectStaticTransport &&
                  props.onSelectStaticTransport("car", obj, index)
                }
                options={cars}
                getOptionDisabled={option =>
                  props.cars.filter(item => item.id === option.id).length > 0
                }
                noOptionsText="no car found in your stock please add it manually"
                placeholder="search existing cars"
                helperText={
                  car &&
                  car.plateNumberState === "error" &&
                  "plate number is not valid"
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} style={{ marginTop: 40 }}>
              <CustomInput
                id="success"
                freeSolo
                labelText="Plate number"
                helperText={
                  car &&
                  car.plateNumberState === "error" &&
                  "plate number is not valid"
                }
                disabled={!!car.id}
                success={car.plateNumberState === "success"}
                error={car.plateNumberState === "error"}
                onChange={text =>
                  props.onStaticTransportChanged(
                    "car",
                    "plate_number",
                    text.toUpperCase(),
                    index
                  )
                }
                value={car.plate_number}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} style={{ marginTop: 40 }}>
              <CustomInput
                id="success"
                disabled={!!car.id}
                labelText="Brand(optional)"
                onChange={text =>
                  props.onStaticTransportChanged("car", "brand", text, index)
                }
                value={car.brand || ""}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} style={{ marginTop: 40 }}>
              <CustomInput
                id="success"
                disabled={!!car.id}
                labelText="Color(optional)"
                onChange={text =>
                  props.onStaticTransportChanged("car", "color", text, index)
                }
                value={car.color || ""}
              />
            </GridItem>
            {car.id && (
              <GridItem xs={12} sm={12} md={2} style={{ marginTop: 40 }}>
                <Button
                  onClick={() =>
                    props.onSelectStaticTransport("car", null, index)
                  }
                  style={{
                    float: "right",
                    backgroundColor: "transparent",
                    color: "orange",
                    borderColor: "orange",
                    marginTop: 15
                  }}
                  variant="outlined"
                >
                  <CancelIcon fontSize="small" />
                </Button>
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}>
              {car.plateNumberState === "success" && !car.id && (
                <Typography
                  color="primary"
                  style={{ float: "left", fontSize: 12 }}
                  variant="body1"
                >
                  This new car will be added to your stock after you send the
                  request..
                </Typography>
              )}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Button
                onClick={() => props.onRemoveOptionalCars(index)}
                style={{
                  float: "left",
                  backgroundColor: "transparent",
                  color: "#F44336",
                  borderColor: "#F44336",
                  marginTop: 15
                }}
                size="sm"
                variant="outlined"
              >
                Remove security car
                <DeleteIcon fontSize="small" />
              </Button>
            </GridItem>
          </>
        ))}
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 15 }}>
          <Button
            onClick={props.onAddOptionalCars}
            style={{
              float: "left"
            }}
            color="primary"
            variant="contained"
          >
            Add new security car
          </Button>
        </GridItem>
      </GridContainer>
    </GridItem>
  );
};
export default SecurityDetailsStaticInput;
