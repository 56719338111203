import React, { useState } from "react";
import moment from "moment";
// @material-ui/core components
import { Grid, Divider, Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { getUserOffice } from "redux/requests/selectors";
import Table from "components/Table/Table";
// @material-ui/icons
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Primary from "components/Typography/Primary";
import {
  REQUEST_TYPE,
  REQUEST_STATUS,
  primaryColor,
  isAdmin,
  isSubContractor,
} from "../../../../src/constants";
import Typography from "@material-ui/core/Typography";
import RequestPlaceItem from "./RequestPlaceItem";
import RequestDateItem from "./RequestDateItem";
import Checkbox from "@material-ui/core/Checkbox";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import NewRequest from "../new.json";
import Lottie from "react-lottie";
import SubcontractorListDialog from "./SubcontractorListDialog";
import { useSelector } from "react-redux";
import RequestItemConfirmed from "./RequestItemConfirmed";
import RequestItemPending from "./RequestItemPending";
const style = {
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...alertStyles,
};

const useStyles = makeStyles(style);

const generateTransportsDetails = (transports) => {
  let rows = [];
  for (let i = 0; i < transports.length; i++) {
    const transport = transports[i];
    let columns = [];
    columns.push(
      <Grid>
        {transport.truck.plate_number}
        {transport.truck.brand ? <div>{transport.truck.brand}</div> : ""}

        {transport.truck.color ? <div>{transport.truck.color}</div> : ""}
      </Grid>
    );
    if (transport.trailer) {
      columns.push(
        <Grid>
          {transport.trailer.plate_number}
          {transport.trailer.brand ? <div>{transport.trailer.brand}</div> : ""}
          <br />
          {transport.trailer.color ? <div>{transport.trailer.color}</div> : ""}
        </Grid>
      );
    } else {
      columns.push(<Grid>N/A</Grid>);
    }

    const driver1 = transport.driver1;
    const driver2 = transport.driver2;
    columns.push(
      <Grid>
        1. {driver1.first_name} {driver1.last_name}
        <br />
        <div style={{ marginLeft: 15 }}>
          {driver1.phone_number ? driver1.phone_number : ""}
        </div>
        <br />
        {driver2 && (
          <div>
            2. {driver2.first_name} {driver2.last_name}
            <br />
            {driver2.phone_number ? +" " + driver2.phone_number : ""}
          </div>
        )}
      </Grid>
    );
    // if (driver2) {
    //   columns.push(
    //     <Grid>
    //       {driver2.first_name} {driver2.last_name}
    //       <br />
    //       {driver2.phone_number ? driver2.phone_number : ""}
    //     </Grid>
    //   );
    // }

    rows.push(columns);
  }

  return rows;
};

const generateIcon = (type) => {
  switch (type) {
    case REQUEST_TYPE.MONITORING:
      return "tv";
    case REQUEST_TYPE.STATIC:
      return "warehouse";
    case REQUEST_TYPE.ESCORT:
      return "car";
    case REQUEST_TYPE.INTERVENTION:
      return "tachometer-alt";
  }
};

const dateLeft = (date) => {
  const startDate = moment(date);
  const today = moment();
  let diffSeconds = startDate.diff(today, "seconds"); // 86400000
  if (diffSeconds < 0) return "";
  const days = Math.floor(diffSeconds / (3600 * 24));
  diffSeconds -= days * 3600 * 24;
  const hours = Math.floor((diffSeconds % (3600 * 24)) / 3600);
  if (days > 0 && hours > 0) return `${days} day(s) and ${hours} hour(s) left`;
  if (days > 0 && hours === 0) return `${days} day(s) left`;
  if (days === 0 && hours > 0) return `${hours} hour(s) left`;
};

const newOptions = {
  loop: true,
  autoplay: true,
  animationData: NewRequest,
};
const RequestItem = (props) => {
  const isOffice = useSelector(getUserOffice);
  const classes = useStyles();
  const [alert, setAlert] = useState(null);
  const status = props.request.status;
  const request = props.request;
  const startDate = request.start_date;
  const requestIcon = generateIcon(props.request.type);
  let drivers = props.request.agent_transport
    .map((x) => (x.agent ? x.agent.first_name + " " + x.agent.last_name : ""))
    .join(" / ");

  const Subcstatus =
    !!props.request.subcontractor_id || props.request.request_shift.length == 0
      ? props.request.subcontractor_status
      : props.request.request_shift[0].subcontractor_status;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ position: "relative" }}>
        <Card
          style={{
            backgroundColor:
              request.viewed == 1 ? "#fff" : "rgba(220,220,220,0.5)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: -30,
              left: 0,
              right: 0,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Primary>
                <h2
                  style={{ fontWeight: 400, textAlign: "center", marginTop: 0 }}
                >
                  {props.request.type}#{request.identifier}
                </h2>
                {(status == REQUEST_STATUS.PENDING ||
                  status == REQUEST_STATUS.ACCEPTED) && (
                  <Typography style={{ marginTop: -20, fontSize: 14 }}>
                    {dateLeft(startDate)}
                  </Typography>
                )}
              </Primary>
            </div>
          </div>
          <CardHeader>
            <Grid style={{ float: "right", right: 20, top: 10 }}>
              <Grid>
                <Button
                  onClick={() => props.onOpenRequestDetails(props.request)}
                  color="primary"
                >
                  Details
                </Button>
                {isAdmin && !isOffice && (
                  <Button
                    onClick={() => props.onDeleteRequest(props.request)}
                    color="danger"
                  >
                    Delete
                  </Button>
                )}
              </Grid>
            </Grid>
            <GridContainer
              justify="space-between"
              alignItems="center"
              direction="row"
            >
              <GridItem>
                <Typography color="primary">
                  {props.request.branch.client.name},{" "}
                  {props.request.branch.city} ({props.request.branch.country}),{" "}
                  {props.request.branch.address}{" "}
                </Typography>

                {props.request.request_shift &&
                props.request.request_shift.length > 0 ? (
                  <RequestItemConfirmed {...props} />
                ) : (
                  <RequestItemPending {...props} />
                )}
              </GridItem>
            </GridContainer>
            {(props.request.status == REQUEST_STATUS.REJECTED ||
              props.request.status == REQUEST_STATUS.CANCELED) && (
              <GridItem>
                <Typography color="error">
                  Reason : {props.request.rejection_reason}
                </Typography>
              </GridItem>
            )}
            {isAdmin &&
              !isOffice &&
              props.request.type != REQUEST_TYPE.MONITORING && (
                <Grid style={{ float: "right", display: "inline-block" }}>
                  <Button
                    disabled={!!props.request.subcontractor}
                    onClick={() => props.onOpenForwardRequest(props.request)}
                    color="primary"
                  >
                    Forward request
                  </Button>
                  {!!props.request.subcontractor && (
                    <Button
                      onClick={() =>
                        props.onOpenForwardRequest(props.request, true)
                      }
                      color="warning"
                    >
                      Cancel Forward
                    </Button>
                  )}

                  {!!props.request.subcontractor && (
                    <div
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        marginTop: 10,
                      }}
                    >
                      Forwarded all to :{" "}
                      {props.request.subcontractor.company_name}
                      {props.request.subcontractor_status == 1 && (
                        <label
                          style={{ color: "green", fontWeight: "bold" }}
                          color="primary"
                        >
                          {" "}
                          [Accepted]
                        </label>
                      )}
                      {!props.request.subcontractor_status && (
                        <label
                          style={{ color: "orange", fontWeight: "bold" }}
                          color="primary"
                        >
                          [Pending]
                        </label>
                      )}
                      {props.request.subcontractor_status == -1 && (
                        <label
                          style={{ color: "red", fontWeight: "bold" }}
                          color="primary"
                        >
                          [Rejected]
                        </label>
                      )}
                    </div>
                  )}
                </Grid>
              )}
            {isSubContractor && (
              <Grid style={{ marginTop: 20 }}>
                <Grid>
                  <Grid>
                    <label style={{ color: "#000" }}>Status : </label>
                    {Subcstatus == 1 && (
                      <label
                        style={{ color: primaryColor, fontWeight: "bold" }}
                        color="primary"
                      >
                        Accepted
                      </label>
                    )}
                    {!Subcstatus && (
                      <label
                        style={{ color: "orange", fontWeight: "bold" }}
                        color="primary"
                      >
                        Pending
                      </label>
                    )}
                  </Grid>
                </Grid>
                {(props.request.status == REQUEST_STATUS.PENDING ||
                  props.request.status == REQUEST_STATUS.ACCEPTED) && (
                  <Grid>
                    {!Subcstatus && (
                      <Button
                        onClick={() =>
                          props.onSubcontractorRequestChange(props.request, 1)
                        }
                        color="primary"
                      >
                        Accept
                      </Button>
                    )}
                    <Button
                      onClick={() =>
                        props.onSubcontractorRequestChange(props.request, -1)
                      }
                      color="danger"
                    >
                      Reject
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </CardHeader>
          {/* {!request.viewed && (
            <Grid
              style={{
                backgroundColor: primaryColor,
                width: 50,
                borderRadius: 5,
                position: "absolute",
                bottom: 10,
                right: 20
              }}
            >
              <Typography
                style={{
                  color: "#fff",
                  textAlign: "center",
                  fontWeight: "bold"
                }}
              >
                NEW!
              </Typography>
            </Grid>
          )} */}
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

export default RequestItem;
