import React, { useState } from "react";
import moment from "moment";
// @material-ui/core components
import { Grid, Divider, Box, TextareaAutosize } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import EditIcon from "@material-ui/icons/Edit";

import Table from "components/Table/Table";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Primary from "components/Typography/Primary";
import {
  REQUEST_TYPE,
  REQUEST_STATUS,
  isAdmin,
} from "../../../../src/constants";
import Typography from "@material-ui/core/Typography";
import RequestPlaceItem from "./RequestPlaceItem";
import RequestDateItem from "./RequestDateItem";
import SecurityDetailsInput from "./SecurityDetailsInput";
import Checkbox from "@material-ui/core/Checkbox";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../constants";

const style = {
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...alertStyles,
};

const useStyles = makeStyles(style);

const generateTransportsDetails = (transports) => {
  let rows = [];
  for (let i = 0; i < transports.length; i++) {
    const transport = transports[i];
    let columns = [];
    if (transport.truck) {
      columns.push(
        <Grid>
          {transport.truck.plate_number}
          {transport.truck.brand ? <div>{transport.truck.brand}</div> : ""}

          {transport.truck.color ? <div>{transport.truck.color}</div> : ""}
        </Grid>
      );
    } else {
      columns.push(
        <Grid style={{ color: "red" }}>
          Truck{transport.id} : missing information
        </Grid>
      );
    }

    if (transport.trailer) {
      columns.push(
        <Grid>
          {transport.trailer.plate_number}
          {transport.trailer.brand ? <div>{transport.trailer.brand}</div> : ""}
          <br />
          {transport.trailer.color ? <div>{transport.trailer.color}</div> : ""}
        </Grid>
      );
    } else {
      columns.push(<Grid>N/A</Grid>);
    }

    const driver1 = transport.driver1;
    const driver2 = transport.driver2;
    columns.push(
      <Grid>
        {driver1 ? (
          <div>
            1. {driver1.first_name} {driver1.last_name}
            <br />
            <div style={{ marginLeft: 15 }}>
              {driver1.phone_number ? driver1.phone_number : ""}
            </div>
            <br />
          </div>
        ) : (
          <div style={{ color: "red" }}>Driver 1 : missing information</div>
        )}
        {driver2 && (
          <div>
            2. {driver2.first_name} {driver2.last_name}
            <br />
            <div style={{ marginLeft: 15 }}>
              {driver2.phone_number ? driver2.phone_number : ""}
            </div>
          </div>
        )}
      </Grid>
    );
    // columns.push(
    //   <Button
    //     justIcon
    //     round
    //     simple
    //     // onClick={() => {
    //     //   let truck = trucks.find(o => o.id === prop.id);
    //     //   onEdit(truck);
    //     // }}
    //     size="lg"
    //     color="primary"
    //     className="edit"
    //   >
    //     <EditIcon />
    //   </Button>
    // );

    rows.push(columns);
  }

  return rows;
};

const generateIcon = (type) => {
  switch (type) {
    case REQUEST_TYPE.MONITORING:
      return "tv";
    case REQUEST_TYPE.STATIC:
      return "warehouse";
    case REQUEST_TYPE.ESCORT:
      return "car";
    case REQUEST_TYPE.INTERVENTION:
      return "tachometer-alt";
  }
};

const dateLeft = (date) => {
  const startDate = moment(date);
  const today = moment();
  let diffSeconds = startDate.diff(today, "seconds"); // 86400000
  if (diffSeconds < 0) return "";
  const days = Math.floor(diffSeconds / (3600 * 24));
  diffSeconds -= days * 3600 * 24;
  const hours = Math.floor((diffSeconds % (3600 * 24)) / 3600);
  if (days > 0 && hours > 0) return `${days} day(s) and ${hours} hour(s) left`;
  if (days > 0 && hours === 0) return `${days} day(s) left`;
  if (days === 0 && hours > 0) return `${hours} hour(s) left`;
};

const RequestClientDetails = (props) => {
  const [reason, setReason] = useState("");
  const request = props.request;

  return (
    <Card style={{ backgroundColor: "#fff", padding: 20 }}>
      <GridItem xs={12} sm={12} md={12} style={{ position: "relative" }}>
        <GridContainer
          justify="space-between"
          alignItems="center"
          direction="row"
        >
          <GridItem>
            <RequestDateItem
              fromDate={request.start_date}
              toDate={request.end_date}
              original_start_date={request.original_start_date}
            />
            {request.take_over_pup ? (
              <>
                <RequestPlaceItem
                  pup={request.pup}
                  dop={request.take_over_dop}
                />
                <label
                  style={{
                    color: primaryColor,
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Take Over
                </label>
                <RequestDateItem fromDate={request.take_over_start_date} />

                <RequestPlaceItem
                  pup={request.take_over_pup}
                  dop={request.dop}
                />
              </>
            ) : (
              <RequestPlaceItem pup={request.pup} dop={request.dop} />
            )}
            {request.type === REQUEST_TYPE.MONITORING && (
              <GridItem style={{ marginLeft: 5 }}>
                <Grid>
                  <Box display="flex">
                    <p style={{ marginTop: 3, fontSize: 16 }}>
                      <label
                        style={{
                          fontWeight: 500,
                          marginTop: 3,
                          color: "#000",
                          marginRight: 10,
                        }}
                      >
                        Monitoring type :{" "}
                      </label>
                      {request.monitoring_type || request.Monitoring_type}
                    </p>
                  </Box>
                  <Box display="flex">
                    <p style={{ marginTop: 3, fontSize: 16 }}>
                      <label
                        style={{
                          fontWeight: 500,
                          marginTop: 3,
                          color: "#000",
                          marginRight: 10,
                        }}
                      >
                        GPS Url :{" "}
                      </label>
                      {request.gps_url}
                    </p>
                  </Box>
                  <Box display="flex">
                    <p style={{ marginTop: 3, fontSize: 16 }}>
                      <label
                        style={{
                          fontWeight: 500,
                          marginTop: 3,
                          color: "#000",
                          marginRight: 10,
                        }}
                      >
                        GPS Username :{" "}
                      </label>
                      {request.gps_username}
                    </p>
                  </Box>
                  <Box display="flex">
                    <p style={{ marginTop: 3, fontSize: 16 }}>
                      <label
                        style={{
                          fontWeight: 500,
                          marginTop: 3,
                          color: "#000",
                          marginRight: 10,
                        }}
                      >
                        GPS Password :{" "}
                      </label>
                      {request.gps_password}
                    </p>
                  </Box>
                </Grid>
              </GridItem>
            )}
          </GridItem>
        </GridContainer>
        <GridContainer>
          {request.type === REQUEST_TYPE.INTERVENTION && (
            <GridItem style={{ marginLeft: 5 }}>
              <Grid>
                <Box display="flex">
                  <p style={{ marginTop: 3, fontSize: 16 }}>
                    <label
                      style={{
                        fontWeight: 500,
                        marginTop: 3,
                        color: "#000",
                        marginRight: 10,
                      }}
                    >
                      Number of security agents :{" "}
                    </label>
                    {request.escort_number}
                  </p>
                </Box>
              </Grid>
            </GridItem>
          )}
          {request.type === REQUEST_TYPE.ESCORT && (
            <GridItem style={{ marginLeft: 5 }}>
              <Grid>
                <Box display="flex">
                  <p style={{ marginTop: 3, fontSize: 16 }}>
                    <label
                      style={{
                        fontWeight: 500,
                        marginTop: 3,
                        color: "#000",
                        marginRight: 10,
                      }}
                    >
                      Number of security vehicles :{" "}
                    </label>
                    {request.escort_number}
                  </p>
                </Box>
              </Grid>
            </GridItem>
          )}
        </GridContainer>
        <GridContainer>
          <GridItem>
            <h2 style={{ float: "left" }}>
              Client {props.request.type} Details
            </h2>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {request.type !== REQUEST_TYPE.STATIC && (
              <Table
                tableHead={["Trucks", "Trailers", "Drivers"]}
                tableData={generateTransportsDetails(request.client_transport)}
              />
            )}
            {request.type === REQUEST_TYPE.STATIC && (
              <Grid>
                <Box display="flex">
                  <h4 style={{ fontWeight: 400 }}>
                    Number of security guards :{" "}
                  </h4>
                  <h4 style={{ marginLeft: 10 }}> {request.number_agents}</h4>
                </Box>
                <Box display="flex">
                  <h4 style={{ fontWeight: 400 }}>Responsible name : </h4>
                  <h4 style={{ marginLeft: 10 }}>
                    {" "}
                    {request.responsible_name}
                  </h4>
                </Box>
                <Box display="flex">
                  <h4 style={{ fontWeight: 400 }}>Phone number : </h4>
                  <h4 style={{ marginLeft: 10 }}> {request.phone_number}</h4>
                </Box>
                <Box display="flex">
                  <h4 style={{ fontWeight: 400 }}>Intructions : </h4>
                  <h4 style={{ marginLeft: 10 }}> {request.instructions}</h4>
                </Box>
              </Grid>
            )}
            {!!request.notes && (
              <Box style={{ marginTop: 20, float: "left" }}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{
                    fontWeight: 400,
                    textDecoration: "underline",
                    float: "left",
                  }}
                >
                  Notes
                </Typography>
                <br />
                <Typography
                  color="primary"
                  style={{ fontWeight: 400, float: "left" }}
                >
                  {request.notes}
                </Typography>
              </Box>
            )}
          </GridItem>
          {props.request.status !== REQUEST_STATUS.ARCHIVED &&
            props.request.status !== REQUEST_STATUS.CANCELED &&
            isAdmin &&
            !props.isOffice && (
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                <Typography
                  style={{ fontSize: 16, float: "left" }}
                  variant="h6"
                >
                  Reason of rejection
                </Typography>
              </GridItem>
            )}
          {props.request.status !== REQUEST_STATUS.REJECTED &&
            props.request.status !== REQUEST_STATUS.ARCHIVED &&
            props.request.status !== REQUEST_STATUS.CANCELED &&
            isAdmin &&
            !props.isOffice && (
              <>
                <TextareaAutosize
                  style={{
                    width: "90%",
                    fontSize: 20,
                    float: "left",
                    padding: 10,
                    marginLeft: 20,
                    fontFamily: "Helvetica",
                    fontWeight: 200,
                    marginTop: 20,
                  }}
                  value={reason}
                  rows={5}
                  name="note"
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="add notes here..."
                />
                <Button
                  disabled={!reason}
                  onClick={() => props.onRejectRequest(reason, request.id)}
                  style={{ marginTop: 20 }}
                  size="lg"
                  color={reason ? "danger" : "default"}
                >
                  Reject request
                </Button>
              </>
            )}
          {props.request.status === REQUEST_STATUS.REJECTED && (
            <GridItem>
              <Typography color="error">
                {props.request.rejection_reason}
              </Typography>
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
    </Card>
  );
};

export default RequestClientDetails;
