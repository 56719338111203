export const EUserActionTypes = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILED: "USER_LOGIN_FAILED",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  USER_LOGOUT: "USER_LOGOUT",
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILED: "GET_USER_FAILED",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILED: "UPDATE_PASSWORD_FAILED",
  RESET_PROFILE_SUCCESS: "RESET_PROFILE_SUCCESS",
  VERIFY_USER_ACCOUNT: "VERIFY_USER_ACCOUNT",
  CONFIRM_USER_ACCOUNT: "CONFIRM_USER_ACCOUNT",
  CONFIRM_USER_ACCOUNT_SUCCESS: "CONFIRM_USER_ACCOUNT_SUCCESS",
  CONFIRM_USER_ACCOUNT_FAILED: "CONFIRM_USER_ACCOUNT_FAILED"
};

export const userActions = {
  login: payload => ({
    type: EUserActionTypes.USER_LOGIN,
    payload
  }),
  userDetails: () => ({
    type: EUserActionTypes.GET_USER
  }),
  updateProfile: payload => ({
    type: EUserActionTypes.UPDATE_PROFILE,
    payload
  }),
  resetProfileSuccess: () => ({
    type: EUserActionTypes.RESET_PROFILE_SUCCESS
  }),
  updatePassword: payload => ({
    type: EUserActionTypes.UPDATE_PASSWORD,
    payload
  }),
  confirmUserAccount: payload => ({
    type: EUserActionTypes.CONFIRM_USER_ACCOUNT,
    payload
  }),
  refreshToken: payload => ({
    type: EUserActionTypes.REFRESH_TOKEN,
    payload
  }),
  logout: () => ({
    type: EUserActionTypes.USER_LOGOUT
  })
};
