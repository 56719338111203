import React, { useState } from "react";
import moment from "moment";
// @material-ui/core components
import { Grid, Divider, Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { getUserOffice } from "redux/requests/selectors";
import Table from "components/Table/Table";
// @material-ui/icons
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Primary from "components/Typography/Primary";
import {
  REQUEST_TYPE,
  REQUEST_STATUS,
  primaryColor,
  isAdmin,
  isSubContractor,
} from "../../../../src/constants";
import Typography from "@material-ui/core/Typography";
import RequestPlaceItem from "./RequestPlaceItem";
import RequestDateItem from "./RequestDateItem";
import Checkbox from "@material-ui/core/Checkbox";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import NewRequest from "../new.json";
import Lottie from "react-lottie";
import SubcontractorListDialog from "./SubcontractorListDialog";
import { useSelector } from "react-redux";

const RequestItemConfirmed = (props) => {
  const isOffice = useSelector(getUserOffice);
  const [alert, setAlert] = useState(null);
  const status = props.request.status;
  const request = props.request;
  const startDate = request.start_date;
  let drivers = props.request.agent_transport
    .map((x) => (x.agent ? x.agent.first_name + " " + x.agent.last_name : ""))
    .join(" / ");

  return (
    <>
      {props.request.request_shift.map((shift, i) => {
        const teams = shift.request_team;
        const drivers = [].concat(...teams.map((team) => team.agents));
        const cars = teams.map((team) => team.security_car);
        const trucks = []
          .concat(...teams.map((team) => team.team_client_transport))
          .map((x) => x.client_transport)
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

        // [...new Set(array)]

        console.log("drivers : ", drivers);
        console.log("trucks : ", trucks);
        return (
          <>
            <GridContainer key={shift.id} style={{ marginBottom: 10 }}>
              <GridItem xs={12} sm={12} md={6}>
                <RequestDateItem
                  fromDate={shift.start_date}
                  original_start_date={
                    i === 0 ? request.original_start_date : null
                  }
                />
                <RequestPlaceItem pup={shift.pup} dop={shift.dop} />
                <Box display="flex">
                  <label style={{ marginTop: 10, color: "#000" }}>
                    <LocalShippingIcon />
                  </label>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      marginTop: 3,
                    }}
                  >
                    Trucks :
                    <label style={{ color: "#333" }}>
                      {props.request.client_transport.map((item, index) => (
                        <label
                          style={{
                            marginTop: 5,
                            display: "inline-block",
                            borderColor: "#333",
                            borderWidth: 0.5,
                            marginRight: 10,
                            borderRadius: 5,
                            padding: 5,
                            // borderStyle: "solid",
                          }}
                          key={item.id}
                        >
                          <label
                            style={{
                              color: primaryColor,
                              fontWeight: "bold",
                            }}
                          >
                            Truck {index + 1}
                          </label>
                          {item.truck ? (
                            <label
                              style={{
                                color: "#333",
                                marginLeft: 10,
                                fontWeight: "bold",
                              }}
                            >
                              {item.truck.plate_number}{" "}
                              {item.trailer &&
                                "( Trailer: " + item.trailer.plate_number + ")"}
                            </label>
                          ) : (
                            <label style={{ color: "red" }}>
                              {" missing information"}
                            </label>
                          )}

                          <label
                            style={{
                              color: "#333",
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Driver 1 :
                            {item.driver1 ? (
                              <label
                                style={{
                                  color: "#333",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                {item.driver1.first_name}{" "}
                                {item.driver1.last_name}{" "}
                                {item.driver1.phone_number}
                              </label>
                            ) : (
                              <label style={{ color: "red" }}>
                                {" missing information"}
                              </label>
                            )}
                          </label>
                          {!!item.driver2 && (
                            <label
                              style={{
                                color: "#333",
                                marginLeft: 20,
                                fontWeight: "bold",
                              }}
                            >
                              Driver 2 :{item.driver2.first_name}{" "}
                              {item.driver2.last_name}{" "}
                              {item.driver2.phone_number}
                            </label>
                          )}
                        </label>
                      ))}
                    </label>
                  </label>
                </Box>
                {props.request.escort_number != 1 && (
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <label
                      style={{
                        color: "#fff",
                        padding: 5,
                        borderRadius: 10,
                        backgroundColor: "red",
                      }}
                    >
                      Teams : {props.request.escort_number}
                    </label>
                  </div>
                )}

                {i === 0 && request.take_over_pup && (
                  <label
                    style={{
                      color: primaryColor,
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    Take Over
                  </label>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <GridItem>
                  <div
                    style={{
                      fontWeight: "bold",
                      color: primaryColor,
                      fontSize: 16,
                    }}
                  >
                    Shift {i + 1} :
                  </div>
                </GridItem>
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: primaryColor,
                    borderRadius: 5,
                  }}
                >
                  <GridItem>
                    <label
                      style={{
                        color: "#333",
                        fontWeight: "bold",
                        fontSize: 16,
                        marginRight: 10,
                      }}
                    >
                      Trucks ({trucks.length}):
                    </label>
                    {trucks.map((item, i) => (
                      <div
                        key={item.id}
                        style={{
                          display: "inline-block",
                          marginRight: 10,
                          fontSize: 16,
                        }}
                      >
                        {item.truck ? (
                          <label
                            style={{
                              color: "#333",
                              // marginLeft: 10,
                            }}
                          >
                            {item.truck.plate_number}{" "}
                            {item.trailer &&
                              "( Trailer: " + item.trailer.plate_number + ")"}
                          </label>
                        ) : (
                          <label style={{ color: "red" }}>
                            {" missing information"}
                          </label>
                        )}

                        <label
                          style={{
                            color: "#333",
                            marginLeft: 20,
                            fontWeight: "bold",
                          }}
                        >
                          Driver 1 :
                          {item.driver1 ? (
                            <label
                              style={{
                                color: "#333",
                              }}
                            >
                              {" "}
                              {item.driver1.first_name} {item.driver1.last_name}{" "}
                              {item.driver1.phone_number}
                            </label>
                          ) : (
                            <label style={{ color: "red" }}>
                              {" missing information"}
                            </label>
                          )}
                        </label>
                        {item.driver2 && (
                          <label
                            style={{
                              color: "#333",
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}
                          >
                            Driver 2 :{item.driver2.first_name}{" "}
                            {item.driver2.last_name} {item.driver2.phone_number}
                          </label>
                        )}
                      </div>
                    ))}
                  </GridItem>
                  <GridItem style={{ marginTop: 10 }}>
                    <label
                      style={{
                        color: "#333",
                        fontWeight: "bold",
                        fontSize: 16,
                        marginRight: 10,
                      }}
                    >
                      Agents ({drivers.length}):
                    </label>
                    {drivers.map((item, i) => (
                      <div
                        key={item.id}
                        style={{
                          display: "inline-block",
                          marginRight: 10,
                        }}
                      >
                        <div
                          style={{
                            width: 10,
                            height: 10,
                            borderRadius: 10,
                            backgroundColor:
                              item.is_confirmed == 0 ? "red" : "green",
                          }}
                        />
                        <label
                          style={{
                            display: "inline-block",
                            fontSize: 16,
                            color: "#333",
                          }}
                        >
                          {item.agent.first_name} {item.agent.last_name}{" "}
                          {item.agent.phone_number || ""}
                          {i < drivers.length && ", "}
                        </label>
                      </div>
                    ))}
                  </GridItem>
                  <GridItem style={{ marginTop: 10 }}>
                    <label
                      style={{
                        color: "#333",
                        fontWeight: "bold",
                        fontSize: 16,
                        marginRight: 10,
                      }}
                    >
                      Cars ({cars.length}):
                    </label>
                    {cars.map((car, i) => (
                      <label
                        key={car.id}
                        style={{
                          marginRight: 10,
                          display: "inline-block",
                          fontSize: 16,
                          lineHeight: 2,
                          color: "#333",
                        }}
                      >
                        {`${car.plate_number} 
                                  ${car.color ? " - " + car.color : ""}
                                  ${car.brand ? " - " + car.brand : ""}`}
                      </label>
                    ))}
                  </GridItem>
                  <GridItem>
                    {isAdmin &&
                      !isOffice &&
                      props.request.request_shift.length > 1 && (
                        <div style={{ float: "left" }}>
                          <div style={{ marginTop: 10 }}>
                            <Button
                              size="sm"
                              disabled={!!shift.subcontractor}
                              round
                              onClick={() =>
                                props.onOpenForwardShift(shift, props.request)
                              }
                              color="primary"
                            >
                              Forward shift {i + 1}
                            </Button>
                            {!!shift.subcontractor && (
                              <Button
                                size="sm"
                                round
                                onClick={() =>
                                  props.onOpenForwardShift(
                                    shift,
                                    props.request,
                                    true
                                  )
                                }
                                color="warning"
                              >
                                Cancel Forward shift {i + 1}
                              </Button>
                            )}
                          </div>
                          {!!shift.subcontractor && (
                            <div
                              style={{
                                color: "green",
                                fontWeight: "bold",
                                marginTop: 10,
                              }}
                            >
                              Forwarded to : {shift.subcontractor.company_name}
                              {shift.subcontractor_status == 1 && (
                                <label
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                  }}
                                  color="primary"
                                >
                                  {" "}
                                  [Accepted]
                                </label>
                              )}
                              {!shift.subcontractor_status && (
                                <label
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                  }}
                                  color="primary"
                                >
                                  [Pending]
                                </label>
                              )}
                              {shift.subcontractor_status == -1 && (
                                <label
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                  color="primary"
                                >
                                  [Rejected]
                                </label>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                  </GridItem>
                </div>
              </GridItem>
            </GridContainer>

            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
          </>
        );
      })}
    </>
  );
};

export default RequestItemConfirmed;
