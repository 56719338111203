import React from "react";
import { useSelector } from "react-redux";
import CustomTabs from "components/CustomTabs/CustomTabs";
import StopIcon from "@material-ui/icons/Stop";
import ImageIcon from "@material-ui/icons/Image";
import CommentIcon from "@material-ui/icons/Comment";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import RequestTimeline from "./RequestTimeline";
import { getStatus } from "redux/requests/selectors";
import { getPictures } from "redux/requests/selectors";
import { getStops } from "redux/requests/selectors";
import { getNotes } from "redux/requests/selectors";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import RequestAgentRecords from "./RequestAgentRecords";
const RequestHistory = props => {
  const status = useSelector(getStatus);
  const stops = useSelector(getStops);
  const pictures = useSelector(getPictures);
  const notes = useSelector(getNotes);

  let tabs = [
    {
      tabName: "Status",
      tabIcon: TrackChangesIcon,
      tabContent: (
        <RequestTimeline {...props} icon={TrackChangesIcon} data={status} />
      )
    },
    {
      tabName: "Stops",
      tabIcon: StopIcon,
      tabContent: <RequestTimeline {...props} icon={StopIcon} data={stops} />
    },
    {
      tabName: "Pictures",
      tabIcon: ImageIcon,
      tabContent: (
        <RequestTimeline
          {...props}
          type="picture"
          icon={ImageIcon}
          data={pictures}
        />
      )
    },
    {
      tabName: "Notes",
      tabIcon: CommentIcon,
      tabContent: <RequestTimeline {...props} icon={CommentIcon} data={notes} />
    }
  ];

  tabs.push({
    tabName: "Agents records",
    tabIcon: SettingsInputAntennaIcon,
    tabContent: (
      <RequestAgentRecords {...props} icon={CommentIcon} data={notes} />
    )
  });
  return (
    <Card>
      <CustomTabs
        // title="Tasks:"
        headerColor="primary"
        tabs={tabs}
      />
    </Card>
  );
};

export default RequestHistory;
