import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import NewAgentDialog from "./NewAgentDialog";
import { agentsActions } from "redux/agents/actions";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { validateEmail } from "redux/utils";
import { isAdmin } from "../../constants";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  input: {
    paddingTop: 0
  }
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles
});

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
}

const AgentsManagement = () => {
  const dispatch = useDispatch();
  const [isNewTruckVisible, setNewTruckVisible] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [agent, setAgent] = useState({});
  const {
    agents,
    saving,
    savingSuccess,
    deleteSuccess,
    activateSuccess,
    resetSuccess
  } = useSelector(state => state.agents);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (savingSuccess) {
      setNewTruckVisible(false);
      const action = isEdit ? "updated" : "added";
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={`Agent ${action}`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Agent has been {isEdit ? "updated" : "added"}.
        </SweetAlert>
      );
    }
  }, [savingSuccess]);

  useEffect(() => {
    if (activateSuccess) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Activated!"
          onConfirm={onCancelActive}
          onCancel={onCancelActive}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Agent is active and will have the access to the portal again.
        </SweetAlert>
      );
    }
  }, [activateSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Blocked!"
          onConfirm={onCancel}
          onCancel={onCancel}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Agent has been blocked.
        </SweetAlert>
      );
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (resetSuccess) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Account reset!"
          onConfirm={onDismissReset}
          onCancel={onDismissReset}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Account has been reset.
        </SweetAlert>
      );
    }
  }, [resetSuccess]);

  const onCancel = () => {
    setAlert(null);
    dispatch(agentsActions.resetSuccessDelete());
  };
  const onCancelActive = () => {
    setAlert(null);
    dispatch(agentsActions.resetActivateDelete());
  };

  const onDismissSave = () => {
    setAlert(null);
    dispatch(agentsActions.resetSuccessSave());
  };

  const warningWithConfirmMessage = obj => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onDelete(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, Block it!"
        cancelBtnText="Cancel"
        showCancel
      >
        The Agent will not have access to the portal.
      </SweetAlert>
    );
  };
  const activateWithConfirmMessage = obj => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onActivate(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, Activate it!"
        cancelBtnText="Cancel"
        showCancel
      >
        The Agent will have access again to his account.
      </SweetAlert>
    );
  };
  const deleteWithConfirmMessage = obj => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onActivate(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, Remove it!"
        cancelBtnText="Cancel"
        showCancel
      >
        The Agent will be remove permanently.
      </SweetAlert>
    );
  };

  const onDelete = obj => {
    dispatch(agentsActions.deleteAgent(obj.id));
  };
  const onActivate = obj => {
    dispatch(agentsActions.activateAgent(obj.id));
  };

  const onSubmit = () => {
    let firstNameState = agent.firstNameState;
    let lastNameState = agent.lastNameState;
    let phoneNumberState = agent.phoneNumberState;
    let emailState = agent.emailState;

    if (!agent.first_name) {
      firstNameState = "error";
    }
    if (!agent.last_name) {
      lastNameState = "error";
    }
    if (!agent.email) {
      emailState = "error";
    }
    if (
      firstNameState === "error" ||
      lastNameState === "error" ||
      phoneNumberState === "error" ||
      emailState === "error"
    ) {
      setAgent({
        ...agent,
        firstNameState,
        lastNameState,
        phoneNumberState,
        emailState
      });
    } else {
      dispatch(agentsActions.saveAgent(agent));
    }
  };

  const onDataChanged = (key, value) => {
    let firstNameState = agent.firstNameState;
    let lastNameState = agent.lastNameState;
    let phoneNumberState = agent.phoneNumberState;
    let emailState = agent.emailState;
    if (key === "first_name") {
      if (!value) firstNameState = "error";
      else firstNameState = "success";
    }
    if (key === "last_name") {
      if (!value) lastNameState = "error";
      else lastNameState = "success";
    }
    if (key === "email") {
      if (!value || !validateEmail(value)) emailState = "error";
      else emailState = "success";
    }
    if (key === "phone_number") {
      if (value) {
        if (value.length < 9) phoneNumberState = "error";
        else phoneNumberState = "success";
      } else {
        phoneNumberState = "";
      }
    }

    setAgent({
      ...agent,
      [key]: value,
      firstNameState,
      lastNameState,
      phoneNumberState,
      emailState
    });
  };

  const onOpenDialog = () => {
    setAgent({
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      firstNameState: "",
      lastNameState: "",
      phoneNumberState: "",
      emailState: ""
    });
    setEdit(false);
    setNewTruckVisible(true);
  };

  const onEdit = agent => {
    setEdit(true);
    setAgent(agent);
    setNewTruckVisible(true);
  };

  const getTrProps = (state, rowInfo) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.row._original.status == 0 && "#FFCCCB"
          //color: 'white'
        }
      };
    }
    return {};
  };
  const onDismissReset = () => {
    setAlert(null);
    dispatch(agentsActions.resetSuccessReset());
  };
  const onReset = obj => {
    dispatch(agentsActions.resetAccount(obj.user.id));
  };

  const warningWithResetMessage = obj => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onReset(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, Reset it!"
        cancelBtnText="Cancel"
        showCancel
      >
        The Account password will be reset and notified by the account email.
      </SweetAlert>
    );
  };

  useEffect(() => {
    setData(
      agents.map(prop => {
        return {
          id: prop.id,
          first_name: prop.first_name,
          last_name: prop.last_name,
          phone_number: prop.phone_number || "N/A",
          email: prop.email,
          user_id: prop.user_id,
          status: prop.status,
          subcontractor: prop.subcontractor_id
            ? prop.subcontractor.company_name
            : "Protectrans",
          verified: !prop.user ? "0" : prop.user.verified,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {prop.status == 1 && (
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    let agent = agents.find(o => o.id === prop.id);
                    onEdit(agent);
                  }}
                  size="lg"
                  color="primary"
                  className="edit"
                >
                  <EditIcon />
                </Button>
              )}
              <Button
                onClick={() => {
                  const obj = agents.find(o => o.id === prop.id);
                  warningWithResetMessage(obj);
                }}
                size="sm"
                color="warning"
                className="remove"
              >
                Reset
              </Button>
              {/* use this button to remove the data row */}
              {prop.status == 1 ? (
                <Button
                  onClick={() => {
                    const obj = agents.find(o => o.id === prop.id);
                    warningWithConfirmMessage(obj);
                  }}
                  size="sm"
                  color="danger"
                  className="remove"
                >
                  Block
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    const obj = agents.find(o => o.id === prop.id);
                    activateWithConfirmMessage(obj);
                  }}
                  size="sm"
                  color="success"
                >
                  Activate
                </Button>
              )}
              {/* <Button
                onClick={() => {
                  const obj = agents.find(o => o.id === prop.id);
                  deleteWithConfirmMessage(obj);
                }}
                size="sm"
                color="danger"
              >
                <DeleteIcon />
              </Button> */}
            </div>
          )
        };
      })
    );
  }, [agents]);

  const classes = useStyles();

  const columns = isAdmin
    ? [
        {
          Header: "First Name",
          accessor: "first_name"
        },
        {
          Header: "Last Name",
          accessor: "last_name"
        },
        {
          Header: "Phone Number",
          accessor: "phone_number"
        },
        {
          Header: "Email",
          accessor: "email"
        },
        {
          Header: "Verified",
          Cell: props => (
            <label
              style={{
                borderRadius: 5,
                padding: 5,
                backgroundColor: props.original.verified == 1 ? "green" : "red",
                color: "#fff"
              }}
            >
              {props.original.verified == 0 ? "Not verified yet" : "Verified"}
            </label>
          )
        },
        {
          Header: "Subcontractor",
          accessor: "subcontractor"
        },
        {
          Header: "Actions",
          accessor: "actions",
          sortable: false,
          filterable: false
        }
      ]
    : [
        {
          Header: "First Name",
          accessor: "first_name"
        },
        {
          Header: "Last Name",
          accessor: "last_name"
        },
        {
          Header: "Phone Number",
          accessor: "phone_number"
        },
        {
          Header: "Email",
          accessor: "email"
        },
        {
          Header: "Verified",
          Cell: props => (
            <label
              style={{
                borderRadius: 5,
                padding: 5,
                backgroundColor: props.original.verified == 1 ? "green" : "red",
                color: "#fff"
              }}
            >
              {props.original.verified == 0 ? "Not verified yet" : "Verified"}
            </label>
          )
        },
        {
          Header: "Actions",
          accessor: "actions",
          sortable: false,
          filterable: false
        }
      ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button
          onClick={onOpenDialog}
          style={{ float: "right", marginBottom: 10 }}
          size="lg"
          color="primary"
          variant="contained"
        >
          Add New Agent
        </Button>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <SupervisedUserCircleIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Agents Management ({agents.length})
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              FilterComponent={({ filter, onChange }) => (
                <CustomInput
                  value={filter ? filter.value : ""}
                  onChange={value => onChange(value)}
                  id="success"
                  // labelText="Plate number"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.input
                  }}
                  inputProps={{
                    value: filter ? filter.value : ""
                  }}
                />
              )}
              data={data}
              filterable
              getTrProps={getTrProps}
              defaultFilterMethod={filterCaseInsensitive}
              columns={columns}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      <NewAgentDialog
        onClose={() => setNewTruckVisible(false)}
        title={agent.id ? "Edit Agent" : "Add New Agent"}
        onSubmit={onSubmit}
        onDataChanged={onDataChanged}
        data={agent}
        isModalVisible={isNewTruckVisible}
        saving={saving}
      />
      {alert}
    </GridContainer>
  );
};

export default AgentsManagement;
