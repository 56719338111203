import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  getRequestRecords,
  getClientRequestRecords
} from "redux/requests/selectors";
import Table from "components/Table/Table.js";
import { isClient } from "../../../constants";

const RequestAgentRecords = ({ request }) => {
  const records = useSelector(state => getRequestRecords(state, request.type));
  const clientRecords = useSelector(state =>
    getClientRequestRecords(state, request.type)
  );
  if (isClient) {
    return (
      <Grid>
        <Table
          tableHeaderColor="primary"
          tableHead={["Total hours", "Total Kilometers", "Car"]}
          tableData={clientRecords}
          coloredColls={[1, 2]}
          colorsColls={["primary"]}
        />
      </Grid>
    );
  }
  return (
    <Grid>
      <Table
        tableHeaderColor="primary"
        tableHead={[
          "Agent Name",
          "Total costs",
          "Total hours",
          "Total waiting time",
          "Total Kilometers",
          "Car"
        ]}
        tableData={records}
        coloredColls={[1, 2]}
        colorsColls={["primary"]}
      />
    </Grid>
  );
};

export default RequestAgentRecords;
