export const ESubContractorsActionTypes = {
  FETCH_SUBCONTRACTORS: "FETCH_SUBCONTRACTORS",
  FETCH_SUBCONTRACTORS_FAILED: "FETCH_SUBCONTRACTORS_FAILED",
  FETCH_SUBCONTRACTORS_SUCCESS: "FETCH_SUBCONTRACTORS_SUCCESS",
  SAVE_SUBC: "SAVE_SUBC",
  DELETE_SUBC: "DELETE_SUBC",
  SAVING_SUCCESS: "SAVING_SUBC_SUCCESS",
  SAVING_FAILED: "SAVING_SUBC_FAILED",
  DELETING_SUCCESS: "DELETING_SUBC_SUCCESS",
  DELETING_FAILED: "DELETING_SUBC_FAILED",
  RESET_SUCCESS_DELETE: "RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE"
};

export const subcontractorsActions = {
  fetchSubcontractors: () => ({
    type: ESubContractorsActionTypes.FETCH_SUBCONTRACTORS
  }),
  saveSubcontractor: payload => ({
    type: ESubContractorsActionTypes.SAVE_SUBC,
    payload
  }),
  deleteSubcontractor: payload => ({
    type: ESubContractorsActionTypes.DELETE_SUBC,
    payload
  }),
  resetSuccessDelete: () => ({
    type: ESubContractorsActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: ESubContractorsActionTypes.RESET_SUCCESS_SAVE
  })
};
