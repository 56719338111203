import { takeLatest, call, put, select } from "redux-saga/effects";
import { EDriversActionTypes } from "./actions";
import axios from "../axios";
function* fetchDrivers() {
  const { user } = yield select(state => state.user);
  try {
    const response = yield call(
      [axios, axios.get],
      `/api/drivers_by_branch/${user.branch_id}`
    );
    if (response && response.data && response.data.success) {
      yield put({
        type: EDriversActionTypes.GET_DRIVERS_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: EDriversActionTypes.GET_DRIVERS_FAILED });
    }
  } catch (e) {
    yield put({
      type: EDriversActionTypes.GET_DRIVERS_FAILED,
      payload: e.response.statusText
    });
  }
}
function* saveDriver(action) {
  const { user } = yield select(state => state.user);
  let { drivers } = yield select(state => state.drivers);
  let response;
  const request = {
    ...action.payload,
    branch_id: user.branch_id
  };

  try {
    if (action.payload.id) {
      response = yield call(
        [axios, axios.put],
        `/api/drivers/${action.payload.id}`,
        request
      );
    } else {
      response = yield call([axios, axios.post], `/api/drivers`, request);
    }

    if (response && response.status === 200) {
      yield put({
        type: EDriversActionTypes.SAVING_SUCCESS,
        payload: response.data.success
      });
      if (action.payload.id) {
        const driverIndex = drivers.findIndex(x => x.id === action.payload.id);
        drivers[driverIndex] = action.payload;
        yield put({
          type: EDriversActionTypes.GET_DRIVERS_SUCCESS,
          payload: [...drivers]
        });
      } else {
        const id = response.data.success.id;
        const driver = {
          id,
          ...action.payload
        };
        drivers.unshift(driver);
        yield put({
          type: EDriversActionTypes.GET_DRIVERS_SUCCESS,
          payload: [...drivers]
        });
      }
    } else {
      yield put({ type: EDriversActionTypes.SAVING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EDriversActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* deleteDriver(action) {
  try {
    const response = yield call(
      [axios, axios.delete],
      `/api/drivers/${action.payload}`
    );
    if (response.status === 200) {
      const { drivers } = yield select(state => state.drivers);

      yield put({
        type: EDriversActionTypes.DELETING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: EDriversActionTypes.GET_DRIVERS_SUCCESS,
        payload: drivers.filter(x => x.id !== action.payload)
      });
    } else {
      yield put({ type: EDriversActionTypes.DELETING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EDriversActionTypes.DELETING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* requestsSaga() {
  yield takeLatest(EDriversActionTypes.GET_DRIVERS, fetchDrivers);
  yield takeLatest(EDriversActionTypes.SAVE_DRIVER, saveDriver);
  yield takeLatest(EDriversActionTypes.DELETE_DRIVER, deleteDriver);
}

export default requestsSaga;
