import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { ERequestsActionTypes } from "./actions";

const defaultState = {
  requests: [],
  trucks: [],
  trailers: [],
  drivers: [],
  searchedRequest: null,
  filterRequests: [],
  filterRequestsInvoices: [],
  saving: false,
  sendingSuccess: false,
  savingError: false,
  savingSuccess: false,
  editingSuccess: false,
  fetching: false,
  refreshing: false,
  cancelError: false,
  cancelSuccess: false,
  rejectError: false,
  rejectSuccess: false,
  editConfirmSuccess: false,
  editConfirmError: false,
  searching: false,
  archiving: false,
  archiveSuccess: false,
  checkSuccess: false,
  deleteSuccess: false,
  searchSuccess: false,
  loadingHistory: false,
  requestHistory: [],
  activeRequests: [],
  updateHistorySuccess: false,
  updateHistoryError: false,
  editingDate: false,
  editingDateSuccess: false,
  forwarding: false,
  forwardingSuccess: false,
  coords: [],
  teamHistory: [],
  requestsMembers: [],
  loading: false,
  requestsChannel: [],
  images: [],
};

const persistConfig = {
  key: "requests",
  storage,
  whitelist: [],
  blacklist: ["requestsMembers", "requestsChannel", "images"],
};

const requestsReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case ERequestsActionTypes.GET_REQUESTS_MEMBERS_SUCCESS:
        return {
          ...state,
          requestsMembers: action.payload,
          loading: false,
        };
      case ERequestsActionTypes.GET_REQUESTS_MEMBERS:
        return {
          ...state,
          requestsMembers: [],
          loading: true,
        };
      case ERequestsActionTypes.GET_REQUEST_DETAILS:
        return {
          ...state,
          requestsChannel: [],
          loading: true,
        };
      case ERequestsActionTypes.GET_REQUEST_DETAILS_SUCCESS:
        return {
          ...state,
          requestsChannel: action.payload,
          loading: false,
        };
      case ERequestsActionTypes.GET_REQUEST_IMAGES_SUCCESS:
        return {
          ...state,
          images: action.payload,
          loading: false,
        };
      case ERequestsActionTypes.GET_REQUESTS_MEMBERS_FAILED:
      case ERequestsActionTypes.GET_REQUEST_DETAILS_FAILED:
      case ERequestsActionTypes.GET_REQUEST_IMAGES_FAILED:
        return {
          ...state,
          loading: false,
        };
      case ERequestsActionTypes.GET_COORDS_HISTORY:
        return {
          ...state,
          loadingHistory: true,
        };
      case ERequestsActionTypes.GET_COORDS_HISTORY_SUCCESS:
        return {
          ...state,
          coords: action.payload,
          loadingHistory: false,
        };
      case ERequestsActionTypes.GET_COORDS_HISTORY_FAILED:
        return {
          ...state,
          coords: [],
          loadingHistory: false,
        };
      case ERequestsActionTypes.GET_TEAM_HISTORY_SUCCESS:
        return {
          ...state,
          teamHistory: action.payload,
        };
      case ERequestsActionTypes.GET_TEAM_HISTORY_FAILED:
        return {
          ...state,
          teamHistory: [],
        };
      case ERequestsActionTypes.FORWARD_REQUEST:
      case ERequestsActionTypes.FORWARD_SHIFT:
        return {
          ...state,
          forwarding: true,
        };
      case ERequestsActionTypes.FORWARD_REQUEST_FAILED:
      case ERequestsActionTypes.FORWARD_SHIFT_FAILED:
      case ERequestsActionTypes.FORWARD_REQUEST_RESET:
        return {
          ...state,
          forwarding: false,
          forwardingSuccess: false,
        };
      case ERequestsActionTypes.FORWARD_REQUEST_SUCCESS:
      case ERequestsActionTypes.FORWARD_SHIFT_SUCCESS:
        return {
          ...state,
          forwarding: false,
          forwardingSuccess: Math.random(),
        };
      case ERequestsActionTypes.CANCEL_FORWARD_SUCCESS:
        return {
          ...state,
          forwarding: false,
        };
      case ERequestsActionTypes.REFRESH_ACTIVE_REQUESTS:
        return {
          ...state,
          refreshing: true,
        };
      case ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_SUCCESS:
        return {
          ...state,
          saving: false,
          filterRequests: action.payload,
        };
      case ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_SUCCESS_INVOICE:
        return {
          ...state,
          saving: false,
          filterRequestsInvoices: action.payload,
        };
      case ERequestsActionTypes.GET_REQUESTS_HISTORY:
        return {
          ...state,
          loadingHistory: true,
          refreshing: false,
          requestHistory: [],
        };
      case ERequestsActionTypes.GET_ACTIVE_REQUESTS:
        return {
          ...state,
          fetching: true,
          refreshing: false,
          activeRequests: [],
        };
      case ERequestsActionTypes.GET_ACTIVE_REQUESTS_SUCCESS:
        return {
          ...state,
          fetching: false,
          refreshing: false,
          activeRequests: action.payload,
        };
      case ERequestsActionTypes.GET_ACTIVE_REQUESTS_FAILED:
        return {
          ...state,
          fetching: false,
          refreshing: false,
        };
      case ERequestsActionTypes.GET_REQUESTS_HISTORY_SUCCESS:
        return {
          ...state,
          loadingHistory: false,
          refreshing: false,
          requestHistory: action.payload,
        };
      case ERequestsActionTypes.GET_REQUESTS_HISTORY_FAILED:
        return {
          ...state,
          loadingHistory: false,
          refreshing: false,
        };
      case ERequestsActionTypes.ADD_REQUEST:
      case ERequestsActionTypes.SEND_ESCORT_REQUESTS_EXCEL:
      case ERequestsActionTypes.EDIT_REQUEST:
      case ERequestsActionTypes.ADD_TRANSPORT_REQUEST:
      case ERequestsActionTypes.EXPORT_ESCORTS:
      case ERequestsActionTypes.EXPORT_ESCORTS_INVOICE:
      case ERequestsActionTypes.FILTER_ESCORTS_BY_DATE:
      case ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_INVOICE:
        return {
          ...state,
          saving: true,
        };
      case ERequestsActionTypes.ARCHIVE_REQUEST:
      case ERequestsActionTypes.CHECK_REQUEST:
        return {
          ...state,
          archiving: true,
        };
      case ERequestsActionTypes.ARCHIVE_REQUEST_SUCCESS:
        return {
          ...state,
          archiving: false,
          archiveSuccess: Math.random(),
        };
      case ERequestsActionTypes.DELETE_REQUEST_SUCCESS:
        return {
          ...state,
          deleteSuccess: Math.random(),
        };
      case ERequestsActionTypes.RESET_DELETE_CHECK_REQUEST_SUCCESS:
        return {
          ...state,
          deleteSuccess: Math.random(),
        };
      case ERequestsActionTypes.ARCHIVE_REQUEST_FAILED:
        return {
          ...state,
          archiving: false,
          archiveSuccess: false,
        };
      case ERequestsActionTypes.CHECK_REQUEST_SUCCESS:
        return {
          ...state,
          archiving: false,
          checkSuccess: Math.random(),
        };
      case ERequestsActionTypes.RESET_CHECK_REQUEST_SUCCESS:
      case ERequestsActionTypes.CHECK_REQUEST_FAILED:
        return {
          ...state,
          archiving: false,
          checkSuccess: false,
        };
      case ERequestsActionTypes.EDIT_REQUEST_SUCCESS:
      case ERequestsActionTypes.ADD_TRANSPORT_REQUEST_SUCCESS:
        return {
          ...state,
          saving: false,
          editingSuccess: Math.random(),
        };
      case ERequestsActionTypes.CONFIRM_REQUEST:
        return {
          ...state,
          saving: true,
        };
      case ERequestsActionTypes.EDIT_CONFIRM_REQUEST:
        return {
          ...state,
          saving: true,
        };
      case ERequestsActionTypes.CONFIRMING_SUCCESS:
        return {
          ...state,
          saving: false,
          savingSuccess: Math.random(),
        };
      case ERequestsActionTypes.SEND_ESCORT_REQUESTS_EXCEL_SUCCESS:
        return {
          ...state,
          saving: false,
          sendingSuccess: Math.random(),
        };
      case ERequestsActionTypes.SEND_ESCORT_REQUESTS_EXCEL_FAILED:
      case ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_FAILED:
      case ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_FAILED_INVOICE:
      case ERequestsActionTypes.EXPORT_ESCORTS_FAILED:
      case ERequestsActionTypes.EXPORT_ESCORTS_FAILED_INVOICE:
      case ERequestsActionTypes.EXPORT_ESCORTS_SUCCESS:
      case ERequestsActionTypes.EXPORT_ESCORTS_SUCCESS_INVOICE:
        return {
          ...state,
          saving: false,
          sendingSuccess: false,
        };
      case ERequestsActionTypes.CONFIRMING_FAILED:
        return {
          ...state,
          saving: false,
          savingError: Math.random(),
        };
      case ERequestsActionTypes.EDIT_CONFIRMING_SUCCESS:
        return {
          ...state,
          saving: false,
          editConfirmSuccess: Math.random(),
        };
      case ERequestsActionTypes.EDIT_CONFIRMING_FAILED:
        return {
          ...state,
          saving: false,
          editConfirmError: Math.random(),
        };
      case ERequestsActionTypes.CANCEL_REQUEST_FAILED:
        return {
          ...state,
          cancelError: Math.random(),
        };
      case ERequestsActionTypes.CANCEL_REQUEST_SUCCESS:
        return {
          ...state,
          cancelError: false,
          cancelSuccess: Math.random(),
        };
      case ERequestsActionTypes.REJECT_REQUEST_FAILED:
        return {
          ...state,
          rejectError: Math.random(),
        };
      case ERequestsActionTypes.REJECT_REQUEST_SUCCESS:
        return {
          ...state,
          rejectError: false,
          rejectSuccess: Math.random(),
        };
      case ERequestsActionTypes.ADDING_SUCCESS:
        return {
          ...state,
          saving: false,
          savingSuccess: Math.random(),
        };
      case ERequestsActionTypes.ADDING_FAILED:
        return {
          ...state,
          saving: false,
          savingSuccess: false,
          savingError: Math.random(),
        };
      case ERequestsActionTypes.GET_REQUESTS_SUCCESS:
        return {
          ...state,
          requests: action.payload,
          fetching: false,
          cancelError: false,
        };
      case ERequestsActionTypes.GET_REQUESTS_FAILED:
        return {
          ...state,
          fetching: false,
        };
      case ERequestsActionTypes.GET_REQUESTS:
        return {
          ...state,
          fetching: true,
        };
      case ERequestsActionTypes.SEARCH_REQUEST:
        return {
          ...state,
          searching: true,
        };
      case ERequestsActionTypes.SEARCH_REQUEST_SUCCESS:
        return {
          ...state,
          searching: false,
          searchSuccess: Math.random(),
          searchedRequest: action.payload,
        };
      case ERequestsActionTypes.SEARCH_REQUEST_FAILED:
        return {
          ...state,
          searching: false,
        };
      case ERequestsActionTypes.RESET_SUCCESS_SEARCH:
        return {
          ...state,
          searchSuccess: false,
        };
      case ERequestsActionTypes.RESET_SUCCESS_EDIT:
        return {
          ...state,
          editingSuccess: false,
        };
      case ERequestsActionTypes.RESET_SUCCESS_SAVE:
        return {
          ...state,
          savingSuccess: false,
          sendingSuccess: false,
        };
      case ERequestsActionTypes.RESET_SUCCESS_CANCEL:
        return {
          ...state,
          cancelSuccess: false,
        };
      case ERequestsActionTypes.RESET_SUCCESS_REJECT:
        return {
          ...state,
          rejectSuccess: false,
        };
      case ERequestsActionTypes.RESET_ARCHIVE_SUCCESS:
        return {
          ...state,
          archiveSuccess: false,
        };
      case ERequestsActionTypes.SET_REQUEST_HISTORY_SUCCESS:
        return {
          ...state,
          updateHistorySuccess: Math.random(),
          updateHistoryError: false,
        };
      case ERequestsActionTypes.SET_REQUEST_HISTORY_FAILED:
        return {
          ...state,
          updateHistoryError: Math.random(),
          updateHistorySuccess: false,
        };
      case ERequestsActionTypes.EDIT_DATE_REQUEST:
        return {
          ...state,
          editingDate: true,
        };
      case ERequestsActionTypes.EDIT_DATE_REQUEST_FAILED:
        return {
          ...state,
          editingDate: false,
        };
      case ERequestsActionTypes.EDIT_DATE_REQUEST_SUCCESS:
        return {
          ...state,
          editingDateSuccess: Math.random(),
          editingDate: false,
        };
      default:
        return state;
    }
  }
);

export default requestsReducer;
