import React from "react";
import GridContainer from "components/Grid/GridContainer";
import TransportDetails from "../NewRequest/Steps/Step2/TransportDetails";
import {
  Slide,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import Datetime from "react-datetime";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { requestsActions } from "redux/requests/actions";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import moment from "moment";
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EditDateRequestDialog = ({
  original_start_date,
  start_date,
  isModalVisible,
  start_date_key,
  request,
  onClose
}) => {
  const dispatch = useDispatch();
  const classes = useStyles(styles);
  const [startDate, setStartDate] = React.useState(
    moment(start_date).format("DD-MM-YYYY HH:mm")
  );
  const requests = useSelector(state => state);
  const { editingDate } = useSelector(state => state.requests);
  const before = moment(original_start_date)
    .add(-4, "hours")
    .add(-1, "minutes");
  const after = moment(original_start_date)
    .add(4, "hours")
    .add(1, "minutes");
  const rangeDateTime = () => {
    const current = moment(startDate, "DD-MM-YYYY HH:mm");
    return (
      (current.isBefore(after) || current === after) &&
      (current.isAfter(before) || current === before)
    );
  };

  const validateDate = () => {
    if (!moment(startDate, "DD-MM-YYYY HH:mm").isValid())
      return "start date is not valid";
    if (!rangeDateTime())
      return "you are allowed to change only 4 hours more or less from the original date";
    return "";
  };

  const onSubmit = () => {
    const start_date = moment(startDate, "DD-MM-YYYY HH:mm").format(
      "YYYY-MM-DD HH:mm:ss"
    );
    dispatch(
      requestsActions.editDateRequest({
        id: request.id,
        start_date,
        start_date_key
      })
    );
  };

  const isDateEqual = () => {
    const edit_date = moment(startDate, "DD-MM-YYYY HH:mm").format(
      "YYYY-MM-DD HH:mm"
    );
    const start_date = moment(start_date).format("YYYY-MM-DD HH:mm");
    return edit_date.toString() == start_date;
  };

  return (
    <Dialog
      fullScreen
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal
      }}
      open={isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>Edit Request Date</h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: 20 }}>
            <CustomInput
              id="success"
              labelText="Start Date"
              helperText={validateDate()}
              value={startDate}
              onChange={event => setStartDate(event)}
              // success={this.state.startDateState === "success"}
              error={validateDate() != ""}
            />
            {/* <Datetime
              timeFormat="HH:mm"
              dateFormat="DD/MM/YYYY"
              isValidDate={valid}
              value={startDate}
              onChange={date => setStartDate(date)}
              renderInput={(props, openCalendar, closeCalendar) => {
                return (
                
                );
              }}
            /> */}
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={onClose} variant="contained" color="primary" simple>
          Close
        </Button>
        {editingDate ? (
          <CircularProgress
            size={30}
            color="primary"
            style={{ marginRight: 30 }}
          />
        ) : (
          <Button
            onClick={() => onSubmit()}
            color="primary"
            variant="contained"
            disabled={validateDate() != "" || isDateEqual()}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditDateRequestDialog;
