export const EAccountsActionTypes = {
  GET_ACCOUNTS: "SUBC_GET_ACCOUNTS",
  GET_ACCOUNTS_SUCCESS: "SUBC_GET_ACCOUNTS_SUCCESS",
  GET_ACCOUNTS_FAILED: "SUBC_GET_ACCOUNTS_FAILED",
  SAVE_ACCOUNT: "SUBC_SAVE_ACCOUNT",
  DELETE_ACCOUNT: "SUBC_DELETE_ACCOUNT",
  SAVING_SUCCESS: "SUBC_SAVING_ACCOUNT_SUCCESS",
  SAVING_FAILED: "SUBC_SAVING_ACCOUNT_FAILED",
  DELETING_SUCCESS: "SUBC_DELETING_ACCOUNT_SUCCESS",
  DELETING_FAILED: "SUBC_DELETING_ACCOUNT_FAILED",
  RESET_SUCCESS_DELETE: "SUBC_RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "SUBC_RESET_SUCCESS_SAVE",
  ACTIVATE_ACCOUNT: "SUBC_ACTIVATE_ACCOUNT",
  ACTIVATING_SUCCESS: "SUBC_ACTIVATING_ACCOUNT_SUCCESS",
  RESETTING_SUCCESS: "SUBC_RESETTING_ACCOUNT_SUCCESS",
  RESETTING_FAILED: "SUBC_RESETTING_ACCOUNT_FAILEd",
  ACTIVATING_FAILED: "SUBC_ACTIVATING_ACCOUNT_FAILED",
  RESET_SUCCESS_ACTIVATE: "SUBC_RESET_SUCCESS_ACTIVATE",
  RESET_SUCCESS_RESET: "SUBC_RESET_SUCCESS_RESET",
  RESET_ACCOUNT: "SUBC_RESET_ACCOUNT"
};

export const accountsActions = {
  saveAccount: payload => ({
    type: EAccountsActionTypes.SAVE_ACCOUNT,
    payload
  }),
  deleteAccount: payload => ({
    type: EAccountsActionTypes.DELETE_ACCOUNT,
    payload
  }),
  fetchAccounts: payload => ({
    type: EAccountsActionTypes.GET_ACCOUNTS,
    payload
  }),
  resetSuccessDelete: () => ({
    type: EAccountsActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: EAccountsActionTypes.RESET_SUCCESS_SAVE
  }),
  activateAccount: payload => ({
    type: EAccountsActionTypes.ACTIVATE_ACCOUNT,
    payload
  }),
  resetActivateDelete: () => ({
    type: EAccountsActionTypes.RESET_SUCCESS_ACTIVATE
  }),
  resetAccount: payload => ({
    type: EAccountsActionTypes.RESET_ACCOUNT,
    payload
  }),
  resetSuccessReset: () => ({
    type: EAccountsActionTypes.RESET_SUCCESS_RESET
  })
};
