import { takeLatest, call, put, select } from "redux-saga/effects";
import axios, { axiosWithoutTokenInterceptors } from "../axios";
import { generateOauth } from "./oauth";
import { EUserActionTypes } from "./actions";
import { setAuthToken } from "../axios";
import { ETrucksActionTypes } from "redux/trucks/actions";
import { ETrailersActionTypes } from "redux/trailers/actions";
import { EDriversActionTypes } from "redux/drivers/actions";
import { ERequestsActionTypes } from "redux/requests/actions";
import { history } from "../../redux/store";
import { ECarsActionTypes } from "redux/cars/actions";
import { EAgentsActionTypes } from "redux/agents/actions";
import { ESubContractorsActionTypes } from "redux/subcontractors/actions";
import { isClient, isAdmin, isSubContractor } from "../../constants";
import moment from "moment";
import { EClientsActionTypes } from "redux/clients/actions";

function* login(action) {
  try {
    const request = {
      ...generateOauth("password"),
      ...action.payload,
    };
    const response = yield call(
      [axiosWithoutTokenInterceptors, axiosWithoutTokenInterceptors.post],
      "/api/oauth/token",
      request
    );
    if (response && response.data && !response.data.error) {
      setAuthToken(response.data.access_token);
      yield put({
        type: EUserActionTypes.USER_LOGIN_SUCCESS,
        payload: response.data,
      });
      yield put({ type: EUserActionTypes.GET_USER });
    } else {
      yield put({
        type: EUserActionTypes.USER_LOGIN_FAILED,
        payload: "Incorrect username/password",
      });
    }
  } catch {
    yield put({
      type: EUserActionTypes.USER_LOGIN_FAILED,
      payload: "Incorrect username/password",
    });
  }
}
function* userDetails() {
  try {
    let role = process.env.REACT_APP_ROLE;
    // if (role == "subc") role = "admin";
    axios.defaults.withCredentials = true;
    const response = yield call([axios, axios.get], `/api/${role}/user`);
    if (response && response.data && response.data.success) {
      const user = response.data.success;
      if (user.verified == 1) {
        localStorage.setItem("isUserLoggedIn", true);
        yield put({
          type: EUserActionTypes.GET_USER_SUCCESS,
          payload: response.data.success,
        });
      } else {
        yield put({ type: EUserActionTypes.VERIFY_USER_ACCOUNT });
      }
    } else {
      setAuthToken();
      localStorage.removeItem("isUserLoggedIn");
      yield put({
        type: EUserActionTypes.GET_USER_FAILED,
        payload: "Incorrect username/password",
      });
    }
  } catch {
    setAuthToken();
    localStorage.removeItem("isUserLoggedIn");
    yield put({
      type: EUserActionTypes.GET_USER_FAILED,
      payload: "Incorrect username/password",
    });
  }
}
function* updateProfile(action) {
  try {
    let role = process.env.REACT_APP_ROLE;
    // if (role == "subc") role = "admin";
    const response = yield call(
      [axios, axios.put],
      `/api/${role}/user`,
      action.payload
    );
    if (response && response.data && response.data.success) {
      yield put({
        type: EUserActionTypes.UPDATE_PROFILE_SUCCESS,
        payload: response.data.success,
      });
    } else {
      yield put({ type: EUserActionTypes.UPDATE_PROFILE_FAILED });
    }
  } catch (e) {
    yield put({
      type: EUserActionTypes.UPDATE_PROFILE_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* updatePassword(action) {
  let response;
  try {
    let role = process.env.REACT_APP_ROLE;
    // if (role == "subc") role = "admin";
    response = yield call(
      [axios, axios.put],
      `/api/${role}/user/password`,
      action.payload
    );
    if (response && response.data && response.data.token) {
      setAuthToken(response.data.token);
      yield put({
        type: EUserActionTypes.UPDATE_PASSWORD_SUCCESS,
      });
    } else {
      yield put({ type: EUserActionTypes.UPDATE_PASSWORD_FAILED });
    }
  } catch (e) {
    yield put({
      type: EUserActionTypes.UPDATE_PASSWORD_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* confirmUserAccount(action) {
  let response;
  try {
    let role = process.env.REACT_APP_ROLE;
    // if (role == "subc") role = "admin";
    response = yield call(
      [axios, axios.put],
      `/api/${role}/user/verify`,
      action.payload
    );
    if (response && response.data && response.data.token) {
      setAuthToken(response.data.token);
      yield put({
        type: EUserActionTypes.CONFIRM_USER_ACCOUNT_SUCCESS,
      });
      yield put({ type: EUserActionTypes.GET_USER });
    } else {
      yield put({ type: EUserActionTypes.CONFIRM_USER_ACCOUNT_FAILED });
    }
  } catch (e) {
    yield put({
      type: EUserActionTypes.CONFIRM_USER_ACCOUNT_FAILED,
      payload: e.response.statusText,
    });
  }
}

function* refreshToken(action) {
  try {
    const request = generateOauth("refresh_token");
    const response = yield call(
      [axiosWithoutTokenInterceptors, axiosWithoutTokenInterceptors.post],
      "/api/oauth/token",
      request
    );
    if (response && response.data && !response.data.error) {
      setAuthToken(response.data.access_token);
      yield put({
        type: EUserActionTypes.REFRESH_TOKEN_SUCCESS,
        payload: response.data,
      });
      if (action) {
        yield put({ type: EUserActionTypes.GET_USER });
        history.replace(action.payload);
      }
    } else {
      yield put({
        type: EUserActionTypes.USER_LOGIN_FAILED,
        payload: "Session Expired, please login again.",
      });
      localStorage.removeItem("isUserLoggedIn");
      setAuthToken();
      history.replace("/auth/login-page");
    }
  } catch {
    yield put({
      type: EUserActionTypes.USER_LOGIN_FAILED,
      payload: "Session Expired, please login again.",
    });
    localStorage.removeItem("isUserLoggedIn");
    setAuthToken();
    history.replace("/auth/login-page");
  }
}

function* logout() {
  localStorage.removeItem("isUserLoggedIn");
  let role = process.env.REACT_APP_ROLE;
  // if (role == "subc") role = "admin";
  yield call([axios, axios.post], `/api/${role}/logout`);
  setAuthToken();
  yield put({ type: EUserActionTypes.USER_LOGOUT_SUCCESS });
  //window.location.reload();
}

function* fetchTransports() {
  yield put({
    type: ERequestsActionTypes.GET_REQUESTS,
    payload: {
      startDate: moment()
        .add(-7, "days")
        .format("YYYY-MM-DD 00:00"),
      endDate: moment()
        .add(1, "months")
        .format("YYYY-MM-DD 23:59"),
      clientId: -1,
    },
  });
  if (isClient) {
    yield put({ type: ETrucksActionTypes.GET_TRUCKS });
    yield put({ type: ETrailersActionTypes.GET_TRAILERS });
    yield put({ type: EDriversActionTypes.GET_DRIVERS });
  } else if (isAdmin) {
    yield put({ type: ECarsActionTypes.GET_CARS });
    yield put({ type: EAgentsActionTypes.GET_AGENTS });
    yield put({ type: ESubContractorsActionTypes.FETCH_SUBCONTRACTORS });
    yield put({ type: ESubContractorsActionTypes.FETCH_SUBCONTRACTORS });
    yield put({ type: EClientsActionTypes.GET_CLIENTS });
  } else if (isSubContractor) {
    yield put({ type: ECarsActionTypes.GET_CARS });
    yield put({ type: EAgentsActionTypes.GET_AGENTS });
  }
}

function* requestsSaga() {
  yield takeLatest(EUserActionTypes.USER_LOGIN, login);
  yield takeLatest(EUserActionTypes.GET_USER, userDetails);
  yield takeLatest(EUserActionTypes.REFRESH_TOKEN, refreshToken);
  yield takeLatest(EUserActionTypes.UPDATE_PROFILE, updateProfile);
  yield takeLatest(EUserActionTypes.UPDATE_PASSWORD, updatePassword);
  yield takeLatest(EUserActionTypes.CONFIRM_USER_ACCOUNT, confirmUserAccount);
  yield takeLatest(EUserActionTypes.USER_LOGOUT, logout);

  yield takeLatest(
    [EUserActionTypes.REFRESH_TOKEN_SUCCESS, EUserActionTypes.GET_USER_SUCCESS],
    fetchTransports
  );
}

export default requestsSaga;
