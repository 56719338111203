import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomInput from "components/CustomInput/CustomInput";
import {
  InputAdornment,
  Button,
  Fab,
  Divider,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomAutoCompleteInput from "components/CustomAutoCompleteInput/CustomAutoCompleteInput";
import { useSelector } from "react-redux";
import { REQUEST_TYPE } from "../../../../../../src/constants";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const useStyles = makeStyles(customCheckboxRadioSwitch);

const TransportDetails = (props) => {
  const classes = useStyles();
  const { trucks } = useSelector((state) => state.trucks);
  const { trailers } = useSelector((state) => state.trailers);
  const { drivers } = useSelector((state) => state.drivers);

  let editedDrivers = props.transport.drivers || [];
  const existingDrivers = props.transports.map((x) =>
    x.drivers ? x.drivers.map((x) => x) : { ...x.driver1, ...x.driver2 }
  );

  if (existingDrivers) {
    editedDrivers = editedDrivers
      .concat(existingDrivers)
      .reduce((acc, val) => acc.concat(val), []);
  }

  return (
    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
      <GridContainer justify="flex-start">
        <GridContainer justify="flex-end">
          {props.allowRemove && (
            <Button
              style={{
                float: "right",
                backgroundColor: "transparent",
                color: "#F44336",
              }}
              size="sm"
              variant="text"
              onClick={props.onRemoveTransportDetails}
            >
              <DeleteIcon fontSize="small" />
              Remove Truck details
            </Button>
          )}
        </GridContainer>
        {!props.isEdit && (
          <GridItem xs={12} sm={12} md={12}>
            <FormControlLabel
              style={{ marginLeft: 10 }}
              control={
                <Radio
                  checked={props.transport.known}
                  onChange={() => props.onChangeTransportToggle("known", true)}
                  value={true}
                  name="radio button demo"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="I know the truck details"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={!props.transport.known}
                  onChange={() => props.onChangeTransportToggle("known", false)}
                  value={false}
                  name="radio button demo"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label={"I don't know the truck details yet "}
            />
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <h5 style={{ float: "left" }}>Truck Details</h5>
        </GridItem>
        {props.transport.known || props.isEdit ? (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <CustomAutoCompleteInput
                id="success"
                autocompleteValue={{
                  plate_number: "",
                }}
                getOptionDisabled={(option) =>
                  props.transports.filter(
                    (item) => item.truck && item.truck.id === option.id
                  ).length > 0
                }
                optionLabel={(option) => {
                  if (option) {
                    let optionLabel = option.plate_number;
                    if (option.brand)
                      optionLabel = `${optionLabel} - ${option.brand}`;
                    if (option.color)
                      optionLabel = `${optionLabel} - ${option.color}`;

                    return optionLabel;
                  }
                }}
                filterKey="plate_number"
                onSelect={(obj) =>
                  props.onSelect && props.onSelect("truck", obj)
                }
                options={trucks}
                noOptionsText="no truck found in your stock please add it manually"
                placeholder="search existing trucks"
                helperText={
                  props.transport.truck &&
                  props.transport.truck.plateNumberState === "error" &&
                  "plate number is not valid"
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} style={{ marginTop: 40 }}>
              <CustomInput
                id="success"
                freeSolo
                labelText="Plate number"
                helperText={
                  props.transport.truck &&
                  props.transport.truck.plateNumberState === "error" &&
                  "plate number is not valid"
                }
                disabled={props.transport.truck && !!props.transport.truck.id}
                success={
                  props.transport.truck &&
                  props.transport.truck.plateNumberState === "success"
                }
                error={
                  props.transport.truck &&
                  props.transport.truck.plateNumberState === "error"
                }
                onChange={(text) =>
                  props.onChange("truck", "plate_number", text.toUpperCase())
                }
                value={
                  props.transport.truck && props.transport.truck.plate_number
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} style={{ marginTop: 40 }}>
              <CustomInput
                id="success"
                disabled={props.transport.truck && !!props.transport.truck.id}
                labelText="Brand(optional)"
                onChange={(text) => props.onChange("truck", "brand", text)}
                value={
                  (props.transport.truck && props.transport.truck.brand) || ""
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} style={{ marginTop: 40 }}>
              <CustomInput
                id="success"
                disabled={props.transport.truck && !!props.transport.truck.id}
                labelText="Color(optional)"
                onChange={(text) => props.onChange("truck", "color", text)}
                value={
                  (props.transport.truck && props.transport.truck.color) || ""
                }
              />
            </GridItem>
            {props.transport.truck && props.transport.truck.id && (
              <GridItem xs={12} sm={12} md={2} style={{ marginTop: 40 }}>
                <Button
                  onClick={() => props.onSelect("truck", null)}
                  style={{
                    float: "right",
                    backgroundColor: "transparent",
                    color: "orange",
                    borderColor: "orange",
                    marginTop: 15,
                  }}
                  variant="outlined"
                >
                  <CancelIcon fontSize="small" />
                </Button>
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}>
              {props.transport.truck &&
                props.transport.truck.plateNumberState === "success" &&
                !props.transport.truck.id && (
                  <Typography
                    color="primary"
                    style={{ float: "left", fontSize: 12 }}
                    variant="body1"
                  >
                    This new truck will be added to your stock after you send
                    the request..
                  </Typography>
                )}
            </GridItem>
          </>
        ) : (
          <Typography color="primary" style={{ marginLeft: 20 }}>
            -> You can update the truck details later
          </Typography>
        )}
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
          <h5 style={{ float: "left" }}>
            Trailer Details{" "}
            {props.requestType !== REQUEST_TYPE.MONITORING && "(optional)"}
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomAutoCompleteInput
            id="success"
            autocompleteValue={{
              plate_number: "",
            }}
            getOptionDisabled={(option) =>
              props.transports.filter(
                (item) => item.trailer && item.trailer.id === option.id
              ).length > 0
            }
            optionLabel={(option) => {
              if (option) {
                let optionLabel = option.plate_number;
                if (option.brand)
                  optionLabel = `${optionLabel} - ${option.brand}`;
                if (option.color)
                  optionLabel = `${optionLabel} - ${option.color}`;

                return optionLabel;
              }
            }}
            filterKey="plate_number"
            onSelect={(obj) => props.onSelect && props.onSelect("trailer", obj)}
            options={trailers}
            noOptionsText="no trailer found in your stock please add it manually"
            placeholder="search existing trailers"
            helperText={
              props.transport.trailer &&
              props.transport.trailer.plateNumberState === "error" &&
              "plate number is not valid"
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4} style={{ marginTop: 40 }}>
          <CustomInput
            id="success"
            labelText={`Plate number ${
              props.requestType !== REQUEST_TYPE.MONITORING ? "(optional)" : ""
            }`}
            helperText={
              props.transport.trailer &&
              (props.transport.trailer.plate_number ||
                props.requestType === REQUEST_TYPE.MONITORING) &&
              props.transport.trailer.plateNumberState === "error" &&
              "plate number is not valid"
            }
            success={
              props.transport.trailer &&
              props.transport.trailer.plate_number &&
              props.transport.trailer.plateNumberState === "success"
            }
            error={
              props.transport.trailer &&
              (props.transport.trailer.plate_number ||
                props.requestType === REQUEST_TYPE.MONITORING) &&
              props.transport.trailer.plateNumberState === "error"
            }
            onChange={(text) =>
              props.onChange("trailer", "plate_number", text.toUpperCase())
            }
            disabled={props.transport.trailer && !!props.transport.trailer.id}
            value={
              props.transport.trailer && props.transport.trailer.plate_number
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} style={{ marginTop: 40 }}>
          <CustomInput
            id="success"
            disabled={props.transport.trailer && !!props.transport.trailer.id}
            labelText="Brand(optional)"
            onChange={(text) => props.onChange("trailer", "brand", text)}
            value={
              (props.transport.trailer && props.transport.trailer.brand) || ""
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} style={{ marginTop: 40 }}>
          <CustomInput
            id="success"
            labelText="Color(optional)"
            disabled={props.transport.trailer && !!props.transport.trailer.id}
            onChange={(text) => props.onChange("trailer", "color", text)}
            value={
              (props.transport.trailer && props.transport.trailer.color) || ""
            }
          />
        </GridItem>
        {props.transport.trailer && props.transport.trailer.id && (
          <GridItem xs={12} sm={12} md={2} style={{ marginTop: 40 }}>
            <Button
              onClick={() => props.onSelect("trailer", null)}
              style={{
                float: "right",
                backgroundColor: "transparent",
                color: "orange",
                borderColor: "orange",
                marginTop: 15,
              }}
              size="sm"
              variant="outlined"
            >
              <CancelIcon fontSize="small" />
            </Button>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          {props.transport.trailer &&
            props.transport.trailer.plateNumberState === "success" &&
            props.transport.trailer &&
            !props.transport.trailer.id && (
              <Typography
                color="primary"
                style={{ float: "left", fontSize: 12 }}
                variant="body1"
              >
                This new trailer will be added to your stock after you send the
                request.
              </Typography>
            )}
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{ marginTop: 40, alignItems: "center" }}
        >
          <Divider />
        </GridItem>
        {!props.isEdit && (
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
            <FormControlLabel
              style={{ marginLeft: 10 }}
              control={
                <Radio
                  checked={props.transport.known_drivers}
                  onChange={() =>
                    props.onChangeTransportToggle("known_drivers", true)
                  }
                  value={true}
                  name="radio button demo"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="I know the driver(s) details"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={!props.transport.known_drivers}
                  onChange={() =>
                    props.onChangeTransportToggle("known_drivers", false)
                  }
                  value={false}
                  name="radio button demo"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label={"I don't know the driver(s) details yet "}
            />
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
          <h5 style={{ float: "left" }}>
            Drivers Details( Maximum 2 drivers per truck)
          </h5>
        </GridItem>
        {props.transport.known_drivers || props.isEdit ? (
          <>
            {props.transport &&
              props.transport.drivers &&
              props.transport.drivers.map((driver, index) => (
                <>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    key={index + ""}
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <CustomAutoCompleteInput
                      options={drivers}
                      key={index + ""}
                      getOptionDisabled={(option) =>
                        editedDrivers.filter((x) => x && x.id === option.id)
                          .length > 0
                      }
                      // getOptionDisabled={option =>
                      //   props.transports.filter(item =>
                      //     item.drivers
                      //       ? item.drivers.filter(x => x && x.id === option.id)
                      //           .length > 0
                      //       : editedDrivers.filter(x => x && x.id === option.id)
                      //           .length > 0
                      //   ).length > 0
                      // }
                      filterKey="first_name"
                      onSelect={(obj) =>
                        props.onSelect && props.onSelect("drivers", obj, index)
                      }
                      placeholder="search existing drivers"
                      autocompleteValue={{ first_name: "" }}
                      optionLabel={(option) => {
                        if (option && option.first_name && option.last_name) {
                          let optionLabel = `${option.first_name} ${option.last_name}`;
                          if (option.phone_number)
                            optionLabel = `${optionLabel} - ${option.phone_number}`;
                          return optionLabel;
                        }
                        return "";
                      }}
                      id="success"
                      noOptionsText="no driver found in your stock please add it manually"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} style={{ marginTop: 20 }}>
                    <CustomInput
                      id="success"
                      labelText="First Name"
                      onChange={(text) =>
                        props.onChange("drivers", "first_name", text, index)
                      }
                      helperText={
                        driver &&
                        driver.driverFirstNameState === "error" &&
                        "first name required"
                      }
                      disabled={driver && driver.id}
                      success={
                        driver && driver.driverFirstNameState === "success"
                      }
                      error={driver && driver.driverFirstNameState === "error"}
                      value={driver && driver.first_name}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} style={{ marginTop: 20 }}>
                    <CustomInput
                      id="success"
                      labelText="Last Name"
                      disabled={driver && driver.id}
                      onChange={(text) =>
                        props.onChange("drivers", "last_name", text, index)
                      }
                      helperText={
                        driver &&
                        !driver.last_name &&
                        driver.driverLastNameState === "error" &&
                        "last name required"
                      }
                      success={
                        driver && driver.driverLastNameState === "success"
                      }
                      error={
                        driver &&
                        !driver.last_name &&
                        driver.driverLastNameState === "error"
                      }
                      value={driver && driver.last_name}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: 20 }}>
                    <CustomInput
                      id="success"
                      disabled={driver && driver.id}
                      labelText={`Phone number ${
                        props.requestType !== REQUEST_TYPE.MONITORING
                          ? "(optional)"
                          : ""
                      }`}
                      onChange={(text) =>
                        props.onChange("drivers", "phone_number", text, index)
                      }
                      helperText={
                        driver &&
                        driver.phone_number &&
                        driver.driverPhoneNumberState === "error" &&
                        "phone number is not valid"
                      }
                      success={
                        driver &&
                        driver.phone_number &&
                        driver.driverPhoneNumberState === "success"
                      }
                      error={
                        driver &&
                        driver.phone_number &&
                        driver.driverPhoneNumberState === "error"
                          ? true
                          : false
                      }
                      value={driver && driver.phone_number}
                    />
                  </GridItem>
                  {driver && driver.id && (
                    <GridItem xs={12} sm={12} md={2} style={{ marginTop: 20 }}>
                      <Button
                        onClick={() => props.onSelect("drivers", null, index)}
                        style={{
                          float: "right",
                          backgroundColor: "transparent",
                          color: "orange",
                          borderColor: "orange",
                          marginTop: 15,
                        }}
                        size="sm"
                        variant="outlined"
                      >
                        <CancelIcon fontSize="small" />
                      </Button>
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={12} md={12}>
                    {driver &&
                      driver.driverFirstNameState === "success" &&
                      driver.driverLastNameState === "success" &&
                      !driver.id && (
                        <Typography
                          color="primary"
                          style={{ float: "left", fontSize: 12 }}
                          variant="body1"
                        >
                          This new driver will be added to your stock after you
                          send the request..
                        </Typography>
                      )}
                  </GridItem>
                  {index === 1 && (
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        onClick={() => props.onRemoveDriver(index)}
                        style={{
                          float: "left",
                          backgroundColor: "transparent",
                          color: "#F44336",
                          borderColor: "#F44336",
                          marginTop: 15,
                        }}
                        size="sm"
                        variant="outlined"
                      >
                        Remove driver
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </GridItem>
                  )}
                </>
              ))}
            {/* <Button  size="sm" color="primary" variant="contained">Remove Driver</Button> */}

            {props.transport.drivers.length < 2 && (
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                <Button
                  onClick={props.onAddDriver}
                  style={{
                    float: "left",
                  }}
                  color="primary"
                  variant="contained"
                >
                  Add Driver
                </Button>
              </GridItem>
            )}
          </>
        ) : (
          <Typography color="primary" style={{ marginLeft: 20 }}>
            -> You can update the drivers details later
          </Typography>
        )}
      </GridContainer>
    </GridItem>
  );
};
export default TransportDetails;
