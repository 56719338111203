import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";

import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  ...styles,
  ...{
    disabledButton: {
      color: "#607D8B"
    }
  }
});

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    labelText,
    id,
    inputProps,
    error,
    helperText,
    disabled,
    style,
    maxLength,
    InputProps,
    type,
    placeholder,
    multiline
  } = props;

  return (
    <TextField
      style={style}
      fullWidth
      multiline={multiline}
      disabled={disabled}
      error={error}
      maxLength={maxLength}
      helperText={helperText}
      placeholder={placeholder}
      label={labelText}
      id={id}
      InputLabelProps={{
        shrink: true
      }}
      autoComplete="new-password"
      type={type}
      inputProps={{
        autoComplete: "new-password",
        form: {
          autocomplete: "new-password"
        },
        ...inputProps
      }}
      InputProps={{
        ...InputProps,
        classes: { disabled: classes.disabledButton }
      }}
      value={props.value}
      onChange={event => props.onChange && props.onChange(event.target.value)}
    />
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  // error: PropTypes.bool,
  // success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  onChange: PropTypes.func
};
