import {
  CircularProgress,
  List,
  ListItem,
  Menu,
  MenuItem,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "../../../constants";
import React from "react";
import {
  CameraAlt,
  Check,
  Group,
  GroupWork,
  NotificationsOff,
} from "@material-ui/icons";
import { getMessageDate } from "redux/utils";
import { useDispatch, useSelector } from "react-redux";
import { chatActions } from "redux/chat/actions";
import { getRequestStatus } from "redux/utils";

const initialState = {
  mouseX: null,
  mouseY: null,
};

const ChatGroupList = ({
  data,
  selectMode,
  selectedRequests,
  onClick,
  onCombine,
  type,
  search,
}) => {
  const {
    unreadMessages,
    searching,
    loading,
    error,
    paginatedRecentChannelsUrl,
  } = useSelector((state) => state.chat);
  console.log("loading : ", loading);
  const dispatch = useDispatch();
  const [state, setState] = React.useState(initialState);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const { user } = useSelector((state) => state.user);

  const onRightClick = (event, item) => {
    event.preventDefault();
    setSelectedGroup(item);
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  const handleClose = () => {
    setState(initialState);
    setSelectedGroup(null);
  };

  const onToggleMute = () => {
    dispatch(
      chatActions.updateChannelGroup({
        id: selectedGroup.id,
        muted: selectedGroup.muted == 1 ? 0 : 1,
        request_status: getRequestStatus(selectedGroup.channels.requests),
      })
    );
    handleClose();
  };

  const onScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      !searching &&
        type == "recent" &&
        paginatedRecentChannelsUrl &&
        paginatedRecentChannelsUrl != -1 &&
        dispatch(chatActions.getRecentChannels({ pageNumber: 2, search }));
    }
  };
  return (
    <div onScroll={onScroll} className="chat-frame">
      <GridItem>
        <List>
          {data.map((item) => {
            const isSelected = selectedRequests.find((x) => x.id == item.id);
            const { last_message, name } = item.channels;
            // const unreadMessagesCount = { total: 13 };
            const unreadMessagesCount = unreadMessages.find(
              (x) => x.channel_id == item.channel_id
            );
            return (
              <ListItem
                onClick={() => onClick(item)}
                onContextMenu={(e) => onRightClick(e, item)}
                style={{ paddingLeft: 0, paddingRight: 0, height: 80 }}
                button
                divider
                key={item.id}
              >
                <div
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 50,
                    top: 5,
                    backgroundColor:
                      selectMode === true
                        ? primaryColor
                        : item.channels.requests.length > 1
                        ? primaryColor
                        : "#c6c6c6",
                    justifyContent: "center",
                    alignItems: "center",
                    right: 0,
                    left: 0,
                    padding: 12,
                  }}
                >
                  {selectMode ? (
                    isSelected ? (
                      <Check style={{ color: "#fff" }} />
                    ) : null
                  ) : (
                    <div style={{ justifyContent: "center" }}>
                      {item.channels.requests.length > 1 ? (
                        <GroupWork style={{ color: "#fff" }} />
                      ) : (
                        <Group style={{ color: primaryColor }} />
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: "85%",

                    borderBottomColor: "#333",
                    borderBottomWidth: 0.3,
                    marginLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      numberOfLines={1}
                      style={{
                        width: "75%",
                        color: "#333",
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      {name}
                    </div>
                    <div
                      style={{
                        color: unreadMessagesCount ? primaryColor : "#9c9c9c",
                      }}
                    >
                      {getMessageDate(
                        last_message ? last_message.created_at : item.created_at
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      justifyContent: "center",
                      marginTop: 10,
                      marginRight: 30,
                      top: 5,
                      overflow: "hidden",
                      "white-space": "nowrap",
                      "text-overflow": "ellipsis",
                    }}
                  >
                    {last_message ? (
                      <>
                        {last_message.user && last_message.user.first_name ? (
                          <label style={{ color: "#9c9c9c" }} numberOfLines={2}>
                            {last_message.user.id == user.id
                              ? "You: "
                              : `${last_message.user.first_name} ${last_message.user.last_name}: `}
                            {last_message.text ? (
                              <label>{last_message.text}</label>
                            ) : last_message.image ? (
                              <label>sent image</label>
                            ) : (
                              ""
                            )}
                            {last_message.image && (
                              <>
                                {" "}
                                <CameraAlt
                                  name="camera-alt"
                                  style={{ fontSize: 10 }}
                                />
                              </>
                            )}
                          </label>
                        ) : (
                          <label style={{ color: "#9c9c9c" }} numberOfLines={2}>
                            {last_message.text}
                          </label>
                        )}
                      </>
                    ) : (
                      <label style={{ color: "#9c9c9c" }}>no messages</label>
                    )}
                  </div>
                  <div>
                    {unreadMessagesCount ? (
                      <div
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 35,
                          width: 20,
                          height: 20,
                          borderRadius: 20,
                          backgroundColor: primaryColor,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            color: "#fff",
                            fontSize: 12,
                            textAlign: "center",
                            marginTop: 2,
                          }}
                        >
                          {unreadMessagesCount.total}
                        </div>
                      </div>
                    ) : null}
                    {item.muted == 1 && (
                      <NotificationsOff
                        style={{
                          position: "absolute",
                          right: 10,
                          bottom: 5,
                          fontSize: 17,
                        }}
                      />
                    )}
                  </div>
                </div>
              </ListItem>
            );
          })}
        </List>
      </GridItem>
      {selectedGroup && !selectMode && (
        <Menu
          keepMounted
          style={{ zIndex: 9999999 }}
          open={state.mouseY !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={onToggleMute}>
            {selectedGroup && selectedGroup.muted == 1 ? "Unmute" : "Mute"}
          </MenuItem>
          <MenuItem onClick={() => onCombine(selectedGroup)}>Combine</MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default ChatGroupList;
