import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import Lottie from "react-lottie";
import Loading from "../loading.json";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { getTrackingRequests, getActiveAgents } from "redux/requests/selectors";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { requestsActions } from "redux/requests/actions";
import { getAllTeamLocation } from "redux/requests/selectors.js";
import CustomMap from "../CustomMap.js";
import RequestItemDialog from "../Requests/RequestItemDialog.js";
import { getActiveLocations } from "redux/utils";
import ActiveShifts from "./ActiveShifts";
import { primaryColor } from "../../../constants";
const pin = require("assets/img/pin.png");

const getMapOptions = (maps) => {
  return {
    maxZoom: 18,
    disableDefaultUI: true,
    mapTypeControl: true,
    streetViewControl: true,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "on" }],
      },
    ],
  };
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading,
};

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  input: {
    paddingTop: 0,
  },
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles,
});

export const RequestTracking = (props) => {
  const mapRef = useRef();
  const dispatch = useDispatch();
  const [mapApi, setMapApi] = useState(null);
  const [map, setMap] = useState(null);
  const [alert, setAlert] = useState(null);
  const {
    loadingHistory,
    fetching,
    refreshing,
    searchSuccess,
    searchedRequest,
    coords,
  } = useSelector((state) => state.requests);
  const [requestDetailsVisible, setRequestDetailsVisible] = useState(false);
  const requests = useSelector(getTrackingRequests);
  const teamsLocations = useSelector((state) =>
    getAllTeamLocation(state, selectedShift, selectedAgent)
  );
  const { teamHistory } = useSelector((state) => state.requests);
  const [teamHistoryChecked, setTeamHistoryChecked] = useState([]);
  const [checkedRouteAgents, setCheckedRouteAgents] = useState([]);
  const agents = getActiveAgents(requests);
  const [selectedShift, setSelectedShift] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [selectedAgent, setSelectedAgent] = useState(null);

  useEffect(() => {
    dispatch(requestsActions.getActiveRequests({ archived: props.archived }));
  }, []);
  // useEffect(() => {
  //   if (teamHistory.length > 0) {
  //     setTeamHistoryChecked(teamHistory.map((x) => x.id));
  //   }
  // }, [teamHistory]);
  useEffect(() => {
    if (searchSuccess && !props.archived) {
      onOpenRequestDetails(searchedRequest);
    }
  }, [searchSuccess]);

  if (fetching) {
    return <Lottie options={defaultOptions} height={300} width={300} />;
  }

  const onCloseDialog = () => {
    setRequestDetailsVisible(false);
    if (searchSuccess) {
      dispatch(requestsActions.resetSuccessSearch());
    }
  };
  const onSelectShift = (shift, request) => {
    dispatch(requestsActions.clearCoords());
    setSelectedTeam(null);
    if (shift.id != selectedShift.id) {
      setSelectedAgent(null);
      setSelectedShift({ ...shift });
      setSelectedRequest(request);
    } else {
      setSelectedShift({});
      setSelectedRequest({});
    }
  };

  const onSelectTeam = (team, request) => {
    if (!selectedAgent || selectedAgent.team_id != team.id) {
      setCheckedRouteAgents(team.agents.map((x) => x.agent.id));
      setSelectedRequest(request);
      setTeamHistoryChecked([]);
      setSelectedShift({});
      setSelectedTeam(team);
      setSelectedAgent(null);
      dispatch(requestsActions.clearCoords());
      if (!props.archived) dispatch(requestsActions.getTeamHistory(team.id));
    }
  };

  const onOpenRequestDetails = (request) => {
    setRequestDetailsVisible(true);
    setSelectedRequest(request);
    if (!request.viewed) {
      dispatch(requestsActions.setViewRequest(request.id));
    }
  };

  const onChangeRouteAgents = (event, agentId) => {
    if (!event.target.checked) {
      setCheckedRouteAgents(checkedRouteAgents.filter((x) => x != agentId));
    } else {
      setCheckedRouteAgents([...checkedRouteAgents, agentId]);
    }
  };
  const onChangeTeamHistoryChecked = (event, historyId) => {
    if (!event.target.checked) {
      setTeamHistoryChecked(teamHistoryChecked.filter((x) => x != historyId));
    } else {
      setTeamHistoryChecked([...teamHistoryChecked, historyId]);
    }
  };

  let locations = [...teamsLocations];

  return (
    <GridContainer>
      <GridItem xs={3} sm={3} md={3}>
        {!props.archived && (
          <Button
            disabled={refreshing}
            onClick={() => dispatch(requestsActions.refreshActiveRequests({}))}
            color="primary"
            variant="contained"
          >
            {refreshing ? "Refreshing..." : "Refresh"}
          </Button>
        )}
        <div
          style={{
            color: primaryColor,
            fontSize: 20,
            fontWeight: "bold",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {props.archived
            ? `Shifts (${requests[0] ? requests[0].request_shift.length : 0})`
            : `Active Shifts (${requests.length})`}
        </div>
        <div className="frame">
          <ActiveShifts
            selectedRequest={selectedRequest}
            selectedShift={selectedShift}
            selectedTeam={selectedTeam}
            checkedRouteAgents={checkedRouteAgents}
            onSelectShift={onSelectShift}
            onSelectTeam={onSelectTeam}
            onChangeRouteAgents={onChangeRouteAgents}
            teamHistory={teamHistory}
            teamHistoryChecked={teamHistoryChecked}
            archived={props.archived}
            onChangeTeamHistoryChecked={onChangeTeamHistoryChecked}
          />
        </div>

        {/* </div> */}
      </GridItem>

      <GridItem xs={9} sm={9} md={9}>
        {!props.archived && (
          <Autocomplete
            multiple={false}
            fullWidth
            value={selectedAgent}
            id="tags-outlined"
            options={
              agents && agents.length > 0
                ? agents.map((x) => (x.agent ? x.agent : x))
                : []
            }
            onChange={(option, value) => {
              setSelectedShift({});
              setSelectedTeam(null);
              setSelectedAgent(value);
              dispatch(requestsActions.clearCoords());
            }}
            getOptionLabel={(option) => {
              if (option && option.first_name && option.last_name) {
                let optionLabel = `${option.first_name} ${option.last_name}`;
                if (option.phone_number)
                  optionLabel = `${optionLabel} - ${option.phone_number}`;
                return optionLabel;
              }
              return "";
            }}
            // filterSelectedOptions
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                variant="outlined"
                label="Select security agents"
                placeholder="Security agents"
              />
            )}
          />
        )}
        <CustomMap
          selectedRequest={selectedRequest}
          teamsLocations={teamsLocations}
          selectedTeam={selectedTeam}
          locations={getActiveLocations(
            locations,
            selectedShift,
            selectedAgent,
            selectedTeam
          )}
          coords={coords}
          checkedRouteAgents={checkedRouteAgents}
          teamHistory={teamHistory}
          teamHistoryChecked={teamHistoryChecked}
        />
      </GridItem>
      {requestDetailsVisible && (
        <RequestItemDialog
          tracking
          onClose={onCloseDialog}
          isModalVisible={requestDetailsVisible}
          request={selectedRequest}
        />
      )}
    </GridContainer>
  );
};

export default RequestTracking;
