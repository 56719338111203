import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { ESubContractorsActionTypes } from "./actions";

const defaultState = {
  list: [],
  saving: false,
  savingError: false,
  savingSuccess: false,
  deleteSuccess: false,
  deleteFailed: false
};

const persistConfig = {
  key: "subcontractors",
  storage,
  whitelist: []
};

const requestsReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case ESubContractorsActionTypes.FETCH_SUBCONTRACTORS_SUCCESS:
        return {
          ...state,
          list: action.payload
        };
      case ESubContractorsActionTypes.SAVE_CLIENT:
        return {
          ...state,
          saving: true
        };
      case ESubContractorsActionTypes.SAVING_FAILED:
        return {
          ...state,
          saving: false,
          savingError: Math.random(),
          savingSuccess: false
        };
      case ESubContractorsActionTypes.SAVING_SUCCESS:
        return {
          ...state,
          saving: false,
          savingError: false,
          savingSuccess: Math.random()
        };
      case ESubContractorsActionTypes.DELETING_SUCCESS:
        return {
          ...state,
          deleteSuccess: Math.random()
        };
      case ESubContractorsActionTypes.RESET_SUCCESS_DELETE:
        return {
          ...state,
          deleteSuccess: false
        };
      case ESubContractorsActionTypes.RESET_SUCCESS_SAVE:
        return {
          ...state,
          savingSuccess: false
        };
      default:
        return state;
    }
  }
);

export default requestsReducer;
