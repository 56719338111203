import { takeLatest, call, put, select } from "redux-saga/effects";
import { ECarsActionTypes } from "./actions";
import axios from "../axios";

function* fetchCars() {
  const { user } = yield select(state => state.user);
  try {
    const response = yield call([axios, axios.get], `/api/cars`);
    if (response && response.data && response.data.success) {
      yield put({
        type: ECarsActionTypes.GET_CARS_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: ECarsActionTypes.GET_CARS_FAILED });
    }
  } catch (e) {
    yield put({
      type: ECarsActionTypes.GET_CARS_FAILED,
      payload: e.response.statusText
    });
  }
}

function* saveCar(action) {
  const { cars } = yield select(state => state.cars);
  let response;
  const request = action.payload;

  try {
    if (action.payload.id) {
      response = yield call(
        [axios, axios.put],
        `/api/cars/${action.payload.id}`,
        request
      );
    } else {
      response = yield call([axios, axios.post], `/api/cars`, request);
    }

    if (response && response.status === 200) {
      yield put({
        type: ECarsActionTypes.SAVING_SUCCESS,
        payload: response.data.success
      });
      if (action.payload.id) {
        const carIndex = cars.findIndex(x => x.id === action.payload.id);
        cars[carIndex] = action.payload;
        yield put({
          type: ECarsActionTypes.GET_CARS_SUCCESS,
          payload: [...cars]
        });
      } else {
        const id = response.data.success.id;
        const car = {
          id,
          ...action.payload
        };
        cars.unshift(car);
        yield put({
          type: ECarsActionTypes.GET_CARS_SUCCESS,
          payload: [...cars]
        });
      }
    } else {
      yield put({ type: ECarsActionTypes.SAVING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ECarsActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* deleteCar(action) {
  try {
    const response = yield call(
      [axios, axios.delete],
      `/api/cars/${action.payload}`
    );
    if (response.status === 200) {
      const { cars } = yield select(state => state.cars);

      yield put({
        type: ECarsActionTypes.DELETING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: ECarsActionTypes.GET_CARS_SUCCESS,
        payload: cars.filter(x => x.id !== action.payload)
      });
    } else {
      yield put({ type: ECarsActionTypes.DELETING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ECarsActionTypes.DELETING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* requestsSaga() {
  yield takeLatest(ECarsActionTypes.GET_CARS, fetchCars);
  yield takeLatest(ECarsActionTypes.SAVE_CAR, saveCar);
  yield takeLatest(ECarsActionTypes.DELETE_CAR, deleteCar);
}

export default requestsSaga;
