import { ListItem, Typography } from "@material-ui/core";
import { primaryColor } from "../../../constants";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const RequestDetails = () => {
  const { requestsChannel: requests } = useSelector((state) => state.requests);

  //const shifts = request.request_shift;

  return (
    <div className="shift-frame" style={{ margin: 10 }}>
      {/* <Text
          style={{
            color: '#333',
            fontSize: sizes.XXL,
            marginLeft: 20,
            marginTop: 10,
            fontWeight: 'bold',
          }}>
          Shifts {shifts && `(${shifts.length})`}
        </Text> */}
      {requests.map((request) => (
        <div key={request.identifier}>
          <Typography
            style={{
              color: primaryColor,
              fontSize: 20,
              marginHorizontal: 20,
              marginTop: 10,
            }}
          >
            #{request.identifier}
          </Typography>
          {request.request_shift &&
            request.request_shift.map((shift, index) => (
              <div
                key={shift.id}
                style={{ marginHorizontal: 20, marginTop: 10 }}
              >
                <div
                  style={{
                    padding: 10,
                    borderColor: primaryColor,
                    borderRadius: 10,
                    //borderWidth: 1,
                    backgroundColor: "#f8f8f8",
                  }}
                >
                  <Typography style={{ color: primaryColor, fontSize: 16 }}>
                    {"Shift: " + (index + 1)}
                  </Typography>
                  <ListItem>
                    <Typography style={{ color: "#333", display: "flex" }}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        PUP :
                      </Typography>

                      <Typography style={{ marginLeft: 5 }}>
                        {shift.pup}
                      </Typography>
                    </Typography>
                    {/* <Icon color={primaryColor} name="map" /> */}
                  </ListItem>
                  <ListItem>
                    <Typography style={{ color: "#333", display: "flex" }}>
                      <Typography style={{ fontWeight: "bold", color: "#333" }}>
                        DOP :
                      </Typography>
                      <Typography style={{ marginLeft: 5 }}>
                        {shift.dop}
                      </Typography>
                    </Typography>
                    {/* <Icon color={primaryColor} name="map" /> */}
                  </ListItem>
                  <ListItem>
                    <Typography style={{ color: "#333", display: "flex" }}>
                      <Typography style={{ fontWeight: "bold", color: "#333" }}>
                        Agents :
                      </Typography>
                      <Typography style={{ marginLeft: 5 }}>
                        {[
                          ...new Set(
                            request.agent_transport.map(
                              (item) =>
                                item.agent.first_name +
                                " " +
                                item.agent.last_name
                            )
                          ),
                        ].join(" / ")}
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography style={{ color: "#333", display: "flex" }}>
                      <Typography style={{ fontWeight: "bold", color: "#333" }}>
                        Cars :
                      </Typography>
                      <Typography style={{ marginLeft: 5 }}>
                        {shift.request_team
                          .map(
                            (team) =>
                              `${team.security_car.plate_number} ${
                                team.security_car.color
                                  ? " - " + team.security_car.color
                                  : ""
                              }${
                                team.security_car.brand
                                  ? " - " + team.security_car.brand
                                  : ""
                              }`
                          )
                          .join(" / ")}
                      </Typography>
                    </Typography>
                  </ListItem>
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default RequestDetails;
