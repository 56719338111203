import { LOCATION_CHANGE } from "connected-react-router";
import { userActions } from "./user/actions";
import { history } from "../redux/store";
import { errorsActions } from "./errors/actions";

const loadingMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE) {
    store.dispatch(errorsActions.resetErrorMessage());
    const isFirstRendering = action.payload.isFirstRendering;
    const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
    if (isFirstRendering && isUserLoggedIn) {
      const currentRoute = history.location.pathname;
      history.replace("/refreshing");
      store.dispatch(userActions.refreshToken(currentRoute));
    }
  }
  // pass onto next middleware
  return next(action);
};

export default loadingMiddleware;
