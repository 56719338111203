import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { EUserActionTypes } from "./actions";

const defaultState = {
  auth: {},
  user: {},
  isUserLoggedIn: false,
  refreshing: true,
  errorMessage: "",
  successProfile: false,
  successPassword: false,
  logging: false,
  toVerify: false
};

const persistConfig = {
  key: "user",
  storage,
  blacklist: ["auth", "refreshing"],
  whitelist: ["user"]
};

const userReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case EUserActionTypes.USER_LOGIN_SUCCESS:
        return {
          ...state,
          auth: action.payload
        };
      case EUserActionTypes.USER_LOGIN:
        return {
          ...state,
          logging: true,
          errorMessage: ""
        };
      case EUserActionTypes.CONFIRM_USER_ACCOUNT:
        return {
          ...state,
          logging: true,
          errorMessage: ""
        };
      case EUserActionTypes.REFRESH_TOKEN_SUCCESS:
        return {
          ...state,
          auth: action.payload,
          refreshing: false
        };
      case EUserActionTypes.GET_USER_SUCCESS:
        return {
          ...state,
          user: action.payload,
          isUserLoggedIn: true,
          errorMessage: "",
          logging: false,
          refreshing: false
        };
      case EUserActionTypes.VERIFY_USER_ACCOUNT:
        return {
          ...state,
          toVerify: true,
          errorMessage: "",
          logging: false,
          refreshing: false
        };
      case EUserActionTypes.UPDATE_PROFILE:
      case EUserActionTypes.UPDATE_PASSWORD:
      case EUserActionTypes.RESET_PROFILE_SUCCESS:
        return {
          ...state,
          successProfile: false,
          successPassword: false
        };
      case EUserActionTypes.UPDATE_PROFILE_SUCCESS:
        return {
          ...state,
          successProfile: Math.random(),
          user: {
            ...state.user.user,
            ...action.payload
          }
        };
      case EUserActionTypes.UPDATE_PASSWORD_SUCCESS:
        return {
          ...state,
          successPassword: Math.random()
        };
      case EUserActionTypes.GET_USER_FAILED:
      case EUserActionTypes.USER_LOGIN_FAILED:
      case EUserActionTypes.CONFIRM_USER_ACCOUNT_FAILED:
        return {
          ...state,
          isUserLoggedIn: false,
          refreshing: false,
          auth: {},
          user: {},
          errorMessage: action.payload,
          logging: false
        };
      case EUserActionTypes.USER_LOGOUT:
        return {
          ...state,
          isUserLoggedIn: false,
          auth: {},
          user: {}
        };
      default:
        return state;
    }
  }
);

export default userReducer;
