import React, { useState } from "react";

// core components
import Wizard from "./Wizard";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2/Step2";
import Step3 from "./Steps/Step3";
import { useSelector } from "react-redux";

export default function NewRequestStepper(props) {
  const { saving } = useSelector(state => state.requests);
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Wizard
          saving={saving}
          finishButtonText={props.request ? "Edit Request" : "Send Request"}
          validate
          request={props.request}
          steps={[
            { stepName: "Logistic", stepComponent: Step1, stepId: "step1" },
            { stepName: "Details", stepComponent: Step2, stepId: "step2" },
            { stepName: "Notes", stepComponent: Step3, stepId: "step3" }
          ]}
          finishButtonClick={props.onFinish}
        />
      </GridItem>
    </GridContainer>
  );
}
