import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/user/actions";
import { useSelector } from "react-redux";
import { Typography, CircularProgress } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles(styles);

export default function LoginPage({ history }) {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const dispatch = useDispatch();
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  // const [username, setUserName] = useState("");
  // const [username, setUserName] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { errorMessage, logging, toVerify } = useSelector(
    (state) => state.user
  );

  ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
    if (value !== newPassword) {
      return false;
    }
    return true;
  });
  const onLogin = () => {
    dispatch(userActions.login({ username, password }));
  };

  const onUpdatePassword = () => {
    dispatch(
      userActions.confirmUserAccount({
        email: username,
        old_password: password,
        password: newPassword,
        c_password: confirmPassword,
      })
    );
  };

  return (
    <div className={classes.container}>
      <video
        id="background-video"
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
          minWidth: "100%",
          minHeight: "100%",
          width: "auto",
          height: "auto",
          zIndex: -100,
        }}
        loop
        autoPlay
      >
        <source
          src="https://www.protectrans.com/slider/monitoring.mp4"
          type="video/mp4"
        />
        <source
          src="https://www.protectrans.com/slider/monitoring.mp4"
          type="video/ogg"
        />
      </video>
      <GridContainer justify="center">
        <Grid justify="center" container>
          <img
            style={{ width: 300, position: "absolute", top: 10, left: 30 }}
            src={require("assets/img/logo.png")}
          />
        </Grid>
        {toVerify ? (
          <GridItem xs={12} sm={6} md={4}>
            <ValidatorForm onSubmit={onUpdatePassword}>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>Choose Strong Password</h4>
                </CardHeader>
                <CardBody>
                  <TextValidator
                    label="New Password"
                    validators={["required"]}
                    errorMessages={["Password is required"]}
                    id="password"
                    fullWidth
                    formControlProps={{
                      fullWidth: true,
                    }}
                    style={{ marginTop: 10 }}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                    }}
                  />
                  <TextValidator
                    label="Confirm Password"
                    validators={["isPasswordMatch"]}
                    errorMessages={[
                      "Confirm Password does not match with your new password!",
                    ]}
                    id="password"
                    fullWidth
                    formControlProps={{
                      fullWidth: true,
                    }}
                    style={{ marginTop: 10 }}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                    }}
                  />
                  <Typography variant="body2" style={{ color: "red" }}>
                    {errorMessage}{" "}
                  </Typography>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  {logging ? (
                    <CircularProgress
                      size={30}
                      color="primary"
                      style={{ marginBottom: 20 }}
                    />
                  ) : (
                    <Button
                      type="submit"
                      color="primary"
                      simple
                      size="lg"
                      block
                    >
                      Confirm
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </ValidatorForm>
          </GridItem>
        ) : (
          <GridItem xs={12} sm={6} md={4}>
            <ValidatorForm instantValidate={false} onSubmit={onLogin}>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                  <TextValidator
                    label="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    validators={["required", "isEmail"]}
                    errorMessages={["Email is not valid", "Email is not valid"]}
                    fullWidth
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    inputProps={{
                      autoComplete: "new-password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextValidator
                    label="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    validators={["required"]}
                    errorMessages={["Password is required"]}
                    fullWidth
                    style={{ marginTop: 10 }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                    }}
                  />
                  <Typography variant="body2" style={{ color: "red" }}>
                    {errorMessage}{" "}
                  </Typography>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  {logging ? (
                    <CircularProgress
                      size={30}
                      color="primary"
                      style={{ marginBottom: 20 }}
                    />
                  ) : (
                    <Button
                      type="submit"
                      color="primary"
                      simple
                      size="lg"
                      block
                    >
                      Let{"'"}s Go
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </ValidatorForm>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
