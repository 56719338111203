import { AxiosResponse } from "axios";
import { REQUEST_STATUS } from "../constants";
import moment from "moment";
export const isSuccessResponse = ({
  status,
}: AxiosResponse<unknown>): boolean => status >= 200 && status < 400;

export const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const polylinecolors = ["#b71c1c", "#0d47a1", "#1b5e20", "#263238"];

export const getActiveTabStatus = (index) => {
  switch (index) {
    case 0:
      return REQUEST_STATUS.PENDING;
    case 1:
      return REQUEST_STATUS.ACCEPTED;
    case 2:
      return REQUEST_STATUS.REJECTED;
    case 3:
      return REQUEST_STATUS.CANCELED;
    case 4:
      return REQUEST_STATUS.FINISHED;
    case 5:
      return REQUEST_STATUS.CHECKED;
    case 6:
      return REQUEST_STATUS.ARCHIVED;
    default:
      return REQUEST_STATUS.PENDING;
  }
};
export const getClientActiveTabStatus = (index) => {
  switch (index) {
    case 0:
      return REQUEST_STATUS.PENDING;
    case 1:
      return REQUEST_STATUS.ACCEPTED;
    case 2:
      return REQUEST_STATUS.REJECTED;
    case 3:
      return REQUEST_STATUS.CANCELED;
    case 4:
      return REQUEST_STATUS.CHECKED;
    case 5:
      return REQUEST_STATUS.ARCHIVED;
    default:
      return REQUEST_STATUS.PENDING;
  }
};

export const getActiveLocations = (locations, shift, agent, team) => {
  console.log("agent : ", agent);
  if (agent) return locations.filter((x) => x.id == agent.team_id);
  if (team) return locations.filter((x) => x.id == team.id);
  if (shift && shift.id) return locations.filter((x) => x.shift_id == shift.id);

  return locations;
};
export const getMessageDate = (date) => {
  const formattedDate = moment(date).format("YYYY/MM/DD HH:mm");
  let dateToday = new Date().toDateString();
  let longDateYesterday = new Date();
  longDateYesterday.setDate(new Date().getDate() - 1);
  let dateYesterday = longDateYesterday.toDateString();
  let today = dateToday.slice(0, dateToday.length - 5);
  let yesterday = dateYesterday.slice(0, dateToday.length - 5);

  const wholeDate = new Date(formattedDate + " UTC").toDateString();

  const messageDateString = wholeDate.slice(0, wholeDate.length - 5);

  if (
    new Date(formattedDate + " UTC").getFullYear() === new Date().getFullYear()
  ) {
    if (messageDateString === today) {
      return moment
        .utc(date)
        .local()
        .format("HH:mm");
    } else if (messageDateString === yesterday) {
      return "Yesterday";
    } else {
      return messageDateString;
    }
  } else {
    return wholeDate;
  }
};

// {
//   position: "left",
//   avatar:
//     "https://spng.pngfind.com/pngs/s/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png",
//   type: "text",
//   text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit",
//   date: new Date(),
//   title: "Elit magnaaa",
//   avatarFlexible: true,
// }
export const giftedChatType = (messages, currentChannel, user) => {
  const copyMessages = [...messages];
  const data = [];
  for (let i = 0; i < copyMessages.length; i++) {
    const message = copyMessages[i];
    message.position =
      message.user && message.user.id == user.id ? "right" : "left";
    message.type = message.user ? (message.image ? "photo" : "text") : "system";
    // message.avatar =
    //   "https://spng.pngfind.com/pngs/s/5-52097_avatar-png-pic-vector-avatar-icon-png-transparent.png";
    message.date = new Date(moment.utc(message.created_at).local());
    if (message.user) {
      message.title = `${message.user.first_name} ${message.user.last_name}`;
      if (message.user.id == user.id) {
        message.status = !message.message_status
          ? "waiting"
          : message.message_status && message.message_status.length > 0
          ? "sent"
          : "";
      }

      message.sent =
        message.message_status && message.message_status.length > 0;
    } else {
      message.system = true;
    }
    if (message.image && currentChannel) {
      const request = currentChannel.channels.requests[0].request;

      message.lazyLoadingImage =
        message.image && message.image.indexOf("https") == -1
          ? currentChannel.channels.requests.length > 1
            ? `${process.env.REACT_APP_IMAGE_URL}channels/channel_${currentChannel.channel_id}/${message.image}`
            : `${process.env.REACT_APP_IMAGE_URL}requests/'${request.type}_${request.identifier}/${message.image}`
          : message.image;
      // message.photo = message.lazyLoadingImage;
      // message.image = message.lazyLoadingImage;
      message.data = {
        uri: message.lazyLoadingImage,
        status: {
          click: true,
          loading: 0,
        },
      };
    }

    // message.image = message.image
    //   ? `${Config.IMAGE_URL}requests/'${request.type}_${request.identifier}/${message.image}`
    //   : null;
    // message.received =
    //   message.message_status &&
    //   message.message_status.filter((x) => x.status > 0).length > 0
    //     ? true
    //     : false;
    copyMessages[i] = message;
    data.push(message);
  }
  return data;
};

export const getRequestStatus = (requests) => {
  return requests.filter((x) => x.request.status == 1).length > 0
    ? "active"
    : "recent"; //confirmed;
};
