export const EClientsActionTypes = {
  GET_CLIENTS: "GET_CLIENTS",
  GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
  GET_CLIENTS_FAILED: "GET_CLIENTS_FAILED",
  SAVE_CLIENT: "SAVE_CLIENT",
  DELETE_CLIENT: "DELETE_CLIENT",
  SAVING_SUCCESS: "SAVING_CLIENT_SUCCESS",
  SAVING_FAILED: "SAVING_CLIENT_FAILED",
  DELETING_SUCCESS: "DELETING_CLIENT_SUCCESS",
  DELETING_FAILED: "DELETING_CLIENT_FAILED",
  RESET_SUCCESS_DELETE: "RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE",
  SAVE_CLIENT_WITH_LOGO: "SAVE_CLIENT_WITH_LOGO"
};

export const clientsActions = {
  fetchClients: () => ({
    type: EClientsActionTypes.GET_CLIENTS
  }),
  saveClient: payload => ({
    type: EClientsActionTypes.SAVE_CLIENT,
    payload
  }),
  saveClientWithLogo: payload => ({
    type: EClientsActionTypes.SAVE_CLIENT_WITH_LOGO,
    payload
  }),
  deleteClient: payload => ({
    type: EClientsActionTypes.DELETE_CLIENT,
    payload
  }),
  resetSuccessDelete: () => ({
    type: EClientsActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: EClientsActionTypes.RESET_SUCCESS_SAVE
  })
};
