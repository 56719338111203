import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { ECarsActionTypes } from "./actions";

const defaultState = {
  cars: [],
  saving: false,
  savingError: false,
  savingSuccess: false,
  deleteSuccess: false,
  deleteFailed: false
};

const persistConfig = {
  key: "cars",
  storage,
  whitelist: ["cars"]
};

const carsReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case ECarsActionTypes.GET_CARS_SUCCESS:
        return {
          ...state,
          cars: action.payload
        };
      case ECarsActionTypes.SAVE_CAR:
        return {
          ...state,
          saving: true
        };
      case ECarsActionTypes.SAVING_FAILED:
        return {
          ...state,
          saving: false,
          savingError: Math.random(),
          savingSuccess: false
        };
      case ECarsActionTypes.SAVING_SUCCESS:
        return {
          ...state,
          saving: false,
          savingError: false,
          savingSuccess: Math.random()
        };
      case ECarsActionTypes.DELETING_SUCCESS:
        return {
          ...state,
          deleteSuccess: Math.random()
        };
      case ECarsActionTypes.RESET_SUCCESS_DELETE:
        return {
          ...state,
          deleteSuccess: false
        };
      case ECarsActionTypes.RESET_SUCCESS_SAVE:
        return {
          ...state,
          savingSuccess: false
        };
      default:
        return state;
    }
  }
);

export default carsReducer;
