import React from "react";
import { Grid, Box } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import moment from "moment";

const RequestDateItem = props => {
  const fromDate = moment(props.fromDate);
  const toDate = moment(props.toDate);
  return (
    <Grid>
      <Box display="flex">
        <TodayIcon style={{ marginTop: 10 }} />
        <h4 style={{ fontWeight: 400, marginLeft: 10 }}>
          {fromDate.format("dddd, LL")}
        </h4>
        <ScheduleIcon style={{ marginTop: 10, marginLeft: 10 }} />
        <h4 style={{ fontWeight: 400, marginLeft: 10 }}>
          {fromDate.format("HH:mm")}
        </h4>
      </Box>
      {props.original_start_date && (
        <div style={{ marginLeft: 5, marginTop: -5, fontSize: 12 }}>
          ( Original date:{" "}
          {moment(props.original_start_date).format("dddd, LL HH:mm") + " "})
        </div>
      )}
      {props.toDate && (
        <Box display="flex">
          <EventIcon style={{ marginTop: 10 }} />
          <h4 style={{ fontWeight: 400, marginLeft: 10 }}>
            {fromDate.format("dddd, LL")}
          </h4>
          <ScheduleIcon style={{ marginTop: 10, marginLeft: 10 }} />
          <h4 style={{ fontWeight: 400, marginLeft: 10 }}>
            {fromDate.format("HH:mm")}
          </h4>
        </Box>
      )}
    </Grid>
  );
};

export default RequestDateItem;
