import React from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import NewRequestStepper from "./NewRequestStepper";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NewRequestDialog = props => {
  const classes = useStyles(styles);
  return (
    <Dialog
      fullScreen
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal
      }}
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>
          {props.request ? "Edit Request" : "Add New Request"}
        </h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <NewRequestStepper request={props.request} onFinish={props.onFinish} />
      </DialogContent>
    </Dialog>
  );
};
export default NewRequestDialog;

NewRequestDialog.prototype = {
  isModalVisible: PropTypes.bool
};
