import React from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import { InputAdornment, DialogActions } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { COUNTRIES } from "../../../src/constants";

const useStyles = makeStyles({
  ...styles,
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const countryToFlag = isoCode => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

const NewBranchDialog = props => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal
      }}
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>{props.title}</h3>
      </DialogTitle>
      <ValidatorForm onSubmit={props.onSubmit}>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <Autocomplete
            options={COUNTRIES}
            classes={{
              option: classes.option
            }}
            autoHighlight
            inputValue={props.data.country}
            onSelect={e =>
              e.target && props.onDataChanged("country", e.target.value)
            }
            getOptionLabel={option => option.label}
            renderOption={option => (
              <React.Fragment>
                <span>{countryToFlag(option.code)}</span>
                {option.label} ({option.code})
              </React.Fragment>
            )}
            renderInput={params => (
              <TextValidator
                {...params}
                value={props.data.country}
                label="Choose a country"
                validators={["required"]}
                errorMessages={["Country is required"]}
                variant="outlined"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
          <TextValidator
            style={{ marginTop: 20 }}
            value={props.data.city}
            onChange={e => props.onDataChanged("city", e.target.value)}
            label="City"
            validators={["required"]}
            errorMessages={["City is required"]}
            fullWidth
          />
          <TextValidator
            style={{ marginTop: 20 }}
            value={props.data.postcode}
            onChange={e => props.onDataChanged("postcode", e.target.value)}
            label="PostCode"
            validators={["required"]}
            errorMessages={["PostCode is required"]}
            fullWidth
          />
          <TextValidator
            style={{ marginTop: 20 }}
            value={props.data.address}
            onChange={e => props.onDataChanged("address", e.target.value)}
            label="Address"
            validators={["required"]}
            errorMessages={["Address is required"]}
            fullWidth
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={props.onClose}
            variant="contained"
            color="primary"
            simple
          >
            Close
          </Button>
          {props.saving ? (
            <CircularProgress
              size={30}
              color="primary"
              style={{ marginRight: 30 }}
            />
          ) : (
            <Button
              type="submit"
              onClick={props.onSubmit}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};
export default NewBranchDialog;

NewBranchDialog.prototype = {
  isModalVisible: PropTypes.bool
};
