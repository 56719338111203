export const ETrucksActionTypes = {
  GET_TRUCKS: "GET_TRUCKS",
  GET_TRUCKS_SUCCESS: "GET_TRUCKS_SUCCESS",
  GET_TRUCKS_FAILED: "GET_TRUCKS_FAILED",
  SAVE_TRUCK: "SAVE_TRUCK",
  DELETE_TRUCK: "DELETE_TRUCK",
  SAVING_SUCCESS: "SAVING_TRUCK_SUCCESS",
  SAVING_FAILED: "SAVING_TRUCK_FAILED",
  DELETING_SUCCESS: "DELETING_TRUCK_SUCCESS",
  DELETING_FAILED: "DELETING_TRUCK_FAILED",
  RESET_SUCCESS_DELETE: "RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE"
};

export const trucksActions = {
  fetchTrucks: () => ({
    type: ETrucksActionTypes.GET_TRUCKS
  }),
  saveTruck: payload => ({
    type: ETrucksActionTypes.SAVE_TRUCK,
    payload
  }),
  deleteTruck: payload => ({
    type: ETrucksActionTypes.DELETE_TRUCK,
    payload
  }),
  resetSuccessDelete: () => ({
    type: ETrucksActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: ETrucksActionTypes.RESET_SUCCESS_SAVE
  })
};
