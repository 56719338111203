import React, { useState } from "react";
import { useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import {
  TextareaAutosize,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { REQUEST_TYPE, REQUEST_STATUS } from "../../../../src/constants";
import Accordion from "components/Accordion/Accordion";
import CarsDetails from "./CarsDetails";
import SecurityDetailsStaticInput from "./SecurityDetailsStaticInput";

const SecurityDetailsInput = props => {
  const emptyagent = {
    first_name: "",
    last_name: "",
    phone_number: ""
  };
  const emptyCar = {
    id: Math.random(),
    security_car: {
      plate_number: "",
      color: "",
      brand: ""
    },
    agent_transport: [emptyagent]
  };
  const emptyOptionalCar = {
    plate_number: "",
    color: "",
    brand: ""
  };

  const prepareTransport = transport => {
    const securityTransport = [...transport];
    for (let i = 0; i < securityTransport.length; i++) {
      for (let j = 0; j < securityTransport[i].agent_transport.length; j++) {
        securityTransport[i].agent_transport[j] =
          securityTransport[i].agent_transport[j].agent ||
          securityTransport[i].agent_transport[j];
      }
    }
    return [...securityTransport];
  };
  const prepareOptionalCars = transport => {
    const securityTransport = [...transport];
    for (let i = 0; i < securityTransport.length; i++) {
      securityTransport[i] =
        securityTransport[i].security_car || securityTransport[i];
    }
    return [...securityTransport];
  };
  const prepareStaticAgents = agenttransport => {
    const securityAgentsTransport = [...agenttransport];
    for (let i = 0; i < securityAgentsTransport.length; i++) {
      securityAgentsTransport[i] =
        securityAgentsTransport[i].agent || securityAgentsTransport[i];
    }
    return [...securityAgentsTransport];
  };
  const [isAttachError, setAttachError] = useState(false);
  const [trucks, setTrucks] = useState(props.request.client_transport);
  const [cars, setCars] = useState(
    props.request.security_transport.length > 0
      ? prepareTransport(props.request.security_transport)
      : [emptyCar]
  );
  const [optionalCars, setOptionalCars] = useState(
    props.request.security_transport.length > 0
      ? prepareOptionalCars(props.request.security_transport)
      : []
  );
  const [staticAgents, setStaticAgents] = useState(
    props.request.agent_transport.length > 0
      ? prepareStaticAgents(props.request.agent_transport)
      : [emptyagent]
  );
  const [securityNotes, setSecurityNotes] = useState(
    props.request.security_notes
  );
  const { cars: stockCars } = useSelector(state => state.cars);
  const { saving } = useSelector(state => state.requests);

  const onSelectTransport = (key, value, index, agentIndex) => {
    let updatedTransport;
    if (agentIndex != undefined) {
      let agent_transport = cars[index].agent_transport;
      if (value) {
        agent_transport[agentIndex] = {
          ...value,
          agentFirstNameState: "success",
          agentLastNameState: "success",
          agentPhoneNumberState: "success"
        };
      } else {
        agent_transport[agentIndex] = {
          first_name: "",
          last_name: "",
          phone_number: "",
          agentFirstNameState: "",
          agentLastNameState: "",
          agentPhoneNumberState: ""
        };
      }

      updatedTransport = {
        ...cars[index],
        [key]: [...agent_transport]
      };
    } else {
      if (value) {
        updatedTransport = {
          ...cars[index],
          [key]: {
            ...value,
            plateNumberState: "success"
          }
        };
      } else {
        updatedTransport = {
          ...cars[index],
          [key]: {
            plate_number: "",
            color: "",
            brand: "",
            plateNumberState: ""
          }
        };
      }
    }
    let updatedCars = [...cars];
    updatedCars[index] = updatedTransport;
    setCars(updatedCars);
  };
  const onSelectStaticTransport = (key, value, index) => {
    if (key === "agent_transport") {
      let updatedAgents = [...staticAgents];
      if (value) {
        updatedAgents[index] = {
          ...updatedAgents[index],
          ...value,
          agentFirstNameState: "success",
          agentLastNameState: "success",
          agentPhoneNumberState: "success"
        };
      } else {
        updatedAgents[index] = {
          ...updatedAgents[index],
          id: null,
          first_name: "",
          last_name: "",
          phone_number: "",
          agentFirstNameState: "",
          agentLastNameState: "",
          agentPhoneNumberState: ""
        };
      }
      setStaticAgents(updatedAgents);
    } else {
      let updatedCars = [...optionalCars];
      if (value) {
        updatedCars[index] = {
          ...updatedCars[index],
          ...value,
          plateNumberState: "success"
        };
      } else {
        updatedCars[index] = {
          ...updatedCars[index],
          id: null,
          plate_number: "",
          color: "",
          brand: "",
          plateNumberState: ""
        };
      }
      setOptionalCars(updatedCars);
    }
  };

  const onTransportChanged = (key, nestedKey, value, index, agentIndex) => {
    let updatedTransport;
    let updatedCars = [...cars];
    if (agentIndex !== undefined) {
      let agentFirstNameState =
        cars[index][key][agentIndex].agentFirstNameState;
      let agentLastNameState = cars[index][key][agentIndex].agentLastNameState;
      let agentPhoneNumberState =
        cars[index][key][agentIndex].agentPhoneNumberState;

      if (nestedKey === "first_name") {
        if (!value) agentFirstNameState = "error";
        else agentFirstNameState = "success";
      } else if (nestedKey === "last_name") {
        if (!value) agentLastNameState = "error";
        else agentLastNameState = "success";
      } else if (nestedKey === "phone_number") {
        if (!value) agentPhoneNumberState = "";
        else {
          if (value.length < 9) agentPhoneNumberState = "error";
          else agentPhoneNumberState = "success";
        }
      }

      let agent_transport = cars[index][key];
      agent_transport[agentIndex] = {
        ...agent_transport[agentIndex],
        [nestedKey]: value,
        agentFirstNameState,
        agentLastNameState,
        agentPhoneNumberState
      };

      updatedTransport = {
        ...cars[index],
        [key]: [...agent_transport]
      };
      updatedCars[index] = updatedTransport;
      setCars(updatedCars);
    } else {
      let plateNumberState = cars[index][key].plateNumberState;
      if (nestedKey === "plate_number") {
        if (value.length < 5) plateNumberState = "error";
        else plateNumberState = "success";
      }
      if (nestedKey === "plate_number" && plateNumberState === "success") {
        const existTransport = stockCars.find(
          x => x.plate_number.toUpperCase() === value.toUpperCase()
        );
        if (existTransport) {
          updatedTransport = {
            ...cars[index],
            [key]: {
              ...existTransport,
              plateNumberState
            }
          };
          updatedCars[index] = updatedTransport;
          setCars(updatedCars);
          return;
        }
      }
      updatedTransport = {
        ...cars[index],
        [key]: {
          ...cars[index][key],
          [nestedKey]: value,
          id: undefined,
          plateNumberState
        }
      };
      updatedCars[index] = updatedTransport;
      setCars(updatedCars);
    }
  };
  const onStaticTransportChanged = (key, nestedKey, value, index) => {
    let updatedCars = [...optionalCars];
    let plateNumberState = updatedCars[index].plateNumberState;
    if (nestedKey === "plate_number") {
      if (value.length < 5) plateNumberState = "error";
      else plateNumberState = "success";
    }
    if (nestedKey === "plate_number" && plateNumberState === "success") {
      const existTransport = stockCars.find(
        x => x.plate_number.toUpperCase() === value.toUpperCase()
      );
      if (existTransport) {
        updatedCars[index] = {
          ...updatedCars[index],
          ...existTransport,
          plateNumberState
        };
        setOptionalCars(updatedCars);
        return;
      }
    }
    updatedCars[index] = {
      ...updatedCars[index],
      [nestedKey]: value,
      id: undefined,
      plateNumberState
    };
    setOptionalCars(updatedCars);
  };

  const onAddAgent = index => {
    let updatedCars = [...cars];
    const agent_transport = [...updatedCars[index].agent_transport];
    agent_transport.push({ key: Math.random() });
    updatedCars[index].agent_transport = [...agent_transport];
    setCars(updatedCars);
  };

  const onRemoveAgent = (index, agent) => {
    let updatedCars = [...cars];
    const agent_transport = updatedCars[index].agent_transport;
    agent_transport.splice(agent, 1);
    updatedCars[index].agent_transport = agent_transport;
    setCars(updatedCars);
  };
  const onAddStaticAgent = index => {
    let updatedAgents = [...staticAgents];
    updatedAgents.push({ key: Math.random() });
    setStaticAgents(updatedAgents);
  };

  const onRemoveStaticAgent = (index, agent) => {
    let updatedAgents = [...staticAgents];
    updatedAgents.splice(agent, 1);
    setStaticAgents(updatedAgents);
  };
  const onAddOptionalCars = index => {
    let updatedCars = [...optionalCars];
    updatedCars.push({ key: Math.random(), plate_number: "" });
    setOptionalCars(updatedCars);
  };

  const onRemoveOptionalCars = (index, car) => {
    let updatedCars = [...optionalCars];
    updatedCars.splice(car, 1);
    setOptionalCars(updatedCars);
  };

  const onRemoveTransportDetails = index => {
    let updatedCars = [...cars];
    const car = updatedCars[index];
    updatedCars.splice(car, 1);
    setCars(updatedCars);
  };

  const generateCars = () => {
    const accordion = [];
    for (let i = 0; i < cars.length; i++) {
      accordion.push({
        title: `${i + 1}. Security Cars - Agents`,
        content: (
          <CarsDetails
            key={i + ""}
            onSetTrucks={trucks => {
              setTrucks(trucks);
              setAttachError(false);
            }}
            trucks={trucks}
            onSelect={(key, value, agentIndex) =>
              onSelectTransport(key, value, i, agentIndex)
            }
            transport={cars[i]}
            transports={cars}
            onChange={(key, nestedKey, value, agentIndex) =>
              onTransportChanged(key, nestedKey, value, i, agentIndex)
            }
            request={props.request}
            onRemoveAgent={agent => onRemoveAgent(i, agent)}
            onAddAgent={() => onAddAgent(i)}
            allowRemove={i > 0}
            //requestType={selectedRequest}
            onRemoveTransportDetails={() => onRemoveTransportDetails(i)}
          />
        )
      });
    }
    return accordion;
  };

  const onAddNewCar = () => {
    const updatedCars = [...cars];
    updatedCars.push({
      ...emptyCar,
      id: Math.random()
    });
    setCars(updatedCars);
  };
  const validateTransport = (transport, key, nestedKey, value) => {
    let plateNumberState = transport[key].plateNumberState;
    let isValidTransport = true;
    if (nestedKey === "plate_number") {
      if (key === "security_car") {
        if (value.length < 5) {
          plateNumberState = "error";
          isValidTransport = false;
        }
      } else {
        if (value && value.length < 5) {
          plateNumberState = "error";
          isValidTransport = false;
        }
      }
    }
    let updatedTransport = {
      ...transport,
      [key]: {
        ...transport[key],
        [nestedKey]: value,
        plateNumberState
      }
    };
    return { updatedTransport, isValidTransport };
  };

  const validateAgent = (transport, key, nestedKey, value, agentIndex) => {
    let agentFirstNameState = transport[key][agentIndex].agentFirstNameState;
    let agentLastNameState = transport[key][agentIndex].agentLastNameState;
    let agentPhoneNumberState =
      transport[key][agentIndex].agentPhoneNumberState;
    let isValid = true;

    if (nestedKey === "first_name") {
      if (!value) {
        agentFirstNameState = "error";
        isValid = false;
      }
    } else if (nestedKey === "last_name") {
      if (!value) {
        agentLastNameState = "error";
        isValid = false;
      }
    } else if (nestedKey === "phone_number") {
      if (!value) agentPhoneNumberState = "";
      else {
        if (value.length < 9) {
          agentPhoneNumberState = "error";
          isValid = false;
        }
      }
    }

    let agent = transport[key][agentIndex];
    agent = {
      ...agent,
      agentFirstNameState,
      agentLastNameState,
      agentPhoneNumberState
    };

    return { agent, isValid };
  };

  const validateStaticCar = (nestedKey, value, updatedCar) => {
    let plateNumberState = updatedCar.plateNumberState;
    let isValidTransport = true;
    if (nestedKey === "plate_number") {
      if (value.length < 5) {
        plateNumberState = "error";
        isValidTransport = false;
      }
    }
    let car = {
      ...updatedCar,
      [nestedKey]: value,
      plateNumberState
    };
    return { security_car: car, isValid: isValidTransport };
  };
  const validateStaticAgent = (nestedKey, value, agentIndex, updatedAgent) => {
    let agentFirstNameState = updatedAgent.agentFirstNameState;
    let agentLastNameState = updatedAgent.agentLastNameState;
    let agentPhoneNumberState = updatedAgent.agentPhoneNumberState;
    let isValid = true;

    if (nestedKey === "first_name") {
      if (!value) {
        agentFirstNameState = "error";
        isValid = false;
      }
    } else if (nestedKey === "last_name") {
      if (!value) {
        agentLastNameState = "error";
        isValid = false;
      }
    } else if (nestedKey === "phone_number") {
      if (!value) agentPhoneNumberState = "";
      else {
        if (value.length < 9) {
          agentPhoneNumberState = "error";
          isValid = false;
        }
      }
    }
    const agent = {
      ...updatedAgent,
      agentFirstNameState,
      agentLastNameState,
      agentPhoneNumberState
    };

    return { agent, isValid };
  };

  const validateEscortIntervention = () => {
    let transports = [...cars];
    let isValid = true;
    for (let index = 0; index < transports.length; index++) {
      let updatedTruckTransport = validateTransport(
        transports[index],
        "security_car",
        "plate_number",
        transports[index].security_car.plate_number
      );
      transports[index] = updatedTruckTransport.updatedTransport;
      if (updatedTruckTransport.isValidTransport === false) isValid = false;

      let agent_transport = transports[index].agent_transport;
      let updatedTransport = transports[index];
      for (
        let agentIndex = 0;
        agentIndex < agent_transport.length;
        agentIndex++
      ) {
        let updatedAgent = validateAgent(
          transports[index],
          "agent_transport",
          "first_name",
          agent_transport[agentIndex].first_name,
          agentIndex
        );
        agent_transport[agentIndex] = updatedAgent.agent;
        if (updatedAgent.isValid === false) isValid = false;
        updatedTransport = {
          ...transports[index],
          agent_transport: [...agent_transport]
        };
        updatedAgent = validateAgent(
          updatedTransport,
          "agent_transport",
          "last_name",
          updatedAgent.agent.last_name,
          agentIndex
        );
        agent_transport[agentIndex] = updatedAgent.agent;
        if (updatedAgent.isValid === false) isValid = false;
        updatedTransport = {
          ...transports[index],
          agent_transport: [...agent_transport]
        };
        updatedAgent = validateAgent(
          updatedTransport,
          "agent_transport",
          "phone_number",
          updatedAgent.agent.phone_number,
          agentIndex
        );
        agent_transport[agentIndex] = updatedAgent.agent;
        if (updatedAgent.isValid === false) isValid = false;
        updatedTransport = {
          ...transports[index],
          agent_transport: [...agent_transport]
        };
      }
      transports[index] = updatedTransport;
    }
    setCars(transports);
    return isValid;
  };

  const validateStatic = () => {
    let updatedStaticAgents = [...staticAgents];
    let updatedStaticCars = [...optionalCars];
    let isValid = true;
    for (let agentIndex = 0; agentIndex < staticAgents.length; agentIndex++) {
      let updatedAgent = validateStaticAgent(
        "first_name",
        staticAgents[agentIndex].first_name,
        agentIndex,
        staticAgents[agentIndex]
      );
      if (updatedAgent.isValid === false) isValid = false;
      updatedAgent = validateStaticAgent(
        "last_name",
        updatedAgent.agent.last_name,
        agentIndex,
        updatedAgent.agent
      );
      if (updatedAgent.isValid === false) isValid = false;
      updatedAgent = validateStaticAgent(
        "phone_number",
        updatedAgent.agent.phone_number,
        agentIndex,
        updatedAgent.agent
      );
      if (updatedAgent.isValid === false) isValid = false;
      updatedStaticAgents[agentIndex] = updatedAgent.agent;
    }
    for (let index = 0; index < optionalCars.length; index++) {
      let updatedCar = validateStaticCar(
        "plate_number",
        updatedStaticCars[index].plate_number,
        updatedStaticCars[index]
      );
      if (updatedCar.isValid === false) isValid = false;
      updatedStaticCars[index] = updatedCar.security_car;
    }
    setStaticAgents(updatedStaticAgents);
    setOptionalCars(updatedStaticCars);
    return isValid;
  };
  const validateRequest = request => {
    if (
      request.type === REQUEST_TYPE.ESCORT ||
      request.type === REQUEST_TYPE.INTERVENTION
    ) {
      return validateEscortIntervention();
    }
    if (request.type === REQUEST_TYPE.STATIC) {
      return validateStatic();
    }

    return true;
  };

  const groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  return (
    <GridItem xs={12} sm={12} md={12}>
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12}>
          {(props.request.type === REQUEST_TYPE.ESCORT ||
            props.request.type === REQUEST_TYPE.INTERVENTION) && (
            <>
              <Button
                disabled={
                  props.request.status === REQUEST_STATUS.ARCHIVED ||
                  props.request.status === REQUEST_STATUS.FINISHED ||
                  props.request.status === REQUEST_STATUS.CHECKED
                }
                onClick={onAddNewCar}
                variant="outlined"
                color="primary"
              >
                Add new Car details
              </Button>
              <Accordion active={cars.length - 1} collapses={generateCars()} />
            </>
          )}
          {props.request.type === REQUEST_TYPE.STATIC && (
            <SecurityDetailsStaticInput
              agents={staticAgents}
              cars={optionalCars}
              onAddStaticAgent={onAddStaticAgent}
              onRemoveStaticAgent={onRemoveStaticAgent}
              onAddOptionalCars={onAddOptionalCars}
              onRemoveOptionalCars={onRemoveOptionalCars}
              onSelectStaticTransport={onSelectStaticTransport}
              onStaticTransportChanged={onStaticTransportChanged}
              disabled={
                props.request.status === REQUEST_STATUS.ARCHIVED ||
                props.request.status === REQUEST_STATUS.FINISHED ||
                props.request.status === REQUEST_STATUS.CHECKED
              }
            />
          )}
          <GridItem style={{ marginTop: 20 }}>
            <Typography style={{ fontSize: 16, float: "left" }} variant="h6">
              Notes (Optional)
            </Typography>
          </GridItem>
          <TextareaAutosize
            style={{
              width: "90%",
              fontSize: 20,
              float: "left",
              padding: 10,
              marginLeft: 20,
              fontFamily: "Helvetica",
              fontWeight: 200,
              marginTop: 20
            }}
            readOnly={
              props.request.status === REQUEST_STATUS.ARCHIVED ||
              props.request.status === REQUEST_STATUS.CHECKED
            }
            value={securityNotes}
            rows={5}
            name="note"
            onChange={event => setSecurityNotes(event.target.value)}
            placeholder="add notes here..."
          />
          {isAttachError && (
            <Typography color="error">
              Please attach all the trucks to your security cars!
            </Typography>
          )}
          {saving ? (
            <CircularProgress
              size={30}
              color="primary"
              style={{ marginRight: 30 }}
            />
          ) : (
            <Button
              disabled={
                props.request.status === REQUEST_STATUS.ARCHIVED ||
                props.request.status === REQUEST_STATUS.FINISHED ||
                props.request.status === REQUEST_STATUS.CHECKED
              }
              onClick={() => {
                if (props.request.type == REQUEST_TYPE.ESCORT) {
                  if (
                    trucks.filter(x => !x.security_transport_id).length > 0 ||
                    Object.keys(groupBy(trucks, "security_transport_id"))
                      .length !== cars.length
                  ) {
                    setAttachError(true);
                    return;
                  }
                }
                validateRequest(props.request) &&
                  props.onConfirmOldRequest(
                    props.request,
                    cars,
                    optionalCars,
                    staticAgents,
                    securityNotes,
                    trucks
                  );
              }}
              style={{ marginTop: 20 }}
              size="lg"
              color="primary"
            >
              {props.request.status === REQUEST_STATUS.ACCEPTED ? "Edit" : ""}{" "}
              Confirm request
            </Button>
          )}
        </GridItem>
      </GridContainer>
    </GridItem>
  );
};

export default SecurityDetailsInput;
