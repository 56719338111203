import React from "react";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import Loader from "loader.json";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader
};

const SplashScreen = ({ history }) => {
  const { refreshing } = useSelector(state => state.user);
  const logo = require("assets/img/logo.png");
  if (refreshing) {
    return (
      <GridContainer
        justify="center"
        style={{
          backgroundColor: "#607D8B",
          height: "100vh",
          alignItens: "center"
        }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{
              marginLeft: "calc(50% - 180px)",
              marginTop: "calc(25% - 64px)",
              position: "absolute"
            }}
          >
            <img
              align="middle"
              src={logo}
              width={360}
              height={128}
              style={{ position: "absolute", left: 0, top: 0 }}
            />
            <Lottie options={defaultOptions} height={200} width={360} />
          </div>
        </GridItem>
      </GridContainer>
    );
  }

  return null;
};

export default SplashScreen;
