import React from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isSubContractor, REQUEST_STATUS } from "../../../../src/constants";
import SecurityDetailsInput from "./SecurityDetailsInput";
import Card from "components/Card/Card";
import RequestShiftTeam from "./RequestShiftTeam";
import { Grid } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

const RequestSecurityDetails = (props) => {
  const isAccepted =
    (props.request.request_shift.length > 0 &&
      props.request.request_shift[0].subcontractor_status == 1) ||
    props.request.subcontractor_status == 1;
  if (!isAccepted && isSubContractor) {
    return (
      <Card style={{ backgroundColor: "#fff", padding: 20 }}>
        <GridItem xs={12} sm={12} md={12} style={{ position: "relative" }}>
          You have to accept the request to be able to add your agents.
        </GridItem>
        {(props.request.status == REQUEST_STATUS.PENDING ||
          props.request.status == REQUEST_STATUS.ACCEPTED) && (
          <Grid>
            <Button
              onClick={() =>
                props.onSubcontractorRequestChange(props.request, 1)
              }
              color="primary"
            >
              Accept
            </Button>

            <Button
              onClick={() =>
                props.onSubcontractorRequestChange(props.request, -1)
              }
              color="danger"
            >
              Reject
            </Button>
          </Grid>
        )}
      </Card>
    );
  }
  return (
    <Card style={{ backgroundColor: "#fff", padding: 20 }}>
      <GridItem xs={12} sm={12} md={12} style={{ position: "relative" }}>
        {props.request.status !== REQUEST_STATUS.REJECTED &&
        props.request.status !== REQUEST_STATUS.CANCELED ? (
          <GridContainer>
            {props.request.is_new == 1 ? (
              <GridItem xs={12} sm={12} md={12}>
                <RequestShiftTeam
                  isOffice={props.isOffice}
                  onConfirmRequest={props.onConfirmRequest}
                  {...props}
                />
              </GridItem>
            ) : (
              <GridItem xs={6} sm={6} md={6}>
                <h3 style={{ fontWeight: "bold" }}>Security Details</h3>
                <SecurityDetailsInput
                  isOffice={props.isOffice}
                  onConfirmRequest={props.onConfirmRequest}
                  onConfirmOldRequest={props.onConfirmOldRequest}
                  {...props}
                />
              </GridItem>
            )}
          </GridContainer>
        ) : (
          <GridItem>
            <h2 style={{ float: "left" }}>Security Details</h2>
          </GridItem>
        )}
      </GridItem>
    </Card>
  );
};
export default RequestSecurityDetails;
