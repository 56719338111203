import React from "react";
import { useSelector } from "react-redux";

const ChannelImages = () => {
  const { images, requestsChannel: requests } = useSelector(
    (state) => state.requests
  );

  return (
    <div className="shift-frame" style={{ alignItems: "center" }}>
      {images.map((item) => {
        const request = requests.find((x) => x.id == item.request_id);
        if (request)
          return (
            <div
              className="request_card"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_IMAGE_URL}requests/'${request.type}_${request.identifier}/${item.image}`
                )
              }
            >
              <img
                resizeMode="contain"
                style={{
                  height: 250,
                  marginTop: 10,
                  display: "block",
                  "margin-left": "auto",
                  "margin-right": "auto",
                }}
                src={`${process.env.REACT_APP_IMAGE_URL}requests/'${request.type}_${request.identifier}/${item.image}`}
              />
            </div>
          );

        return (
          <div
            className="request_card"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_IMAGE_URL}channels/channel_${item.channel_id}/${item.image}`
              )
            }
          >
            <img
              resizeMode="contain"
              style={{
                height: 250,
                marginTop: 10,
                display: "block",
                "margin-left": "auto",
                "margin-right": "auto",
              }}
              src={`${process.env.REACT_APP_IMAGE_URL}channels/channel_${item.channel_id}/${item.image}`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ChannelImages;
