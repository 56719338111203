import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { EChatActionTypes } from "./actions";

const defaultState = {
  activeChannels: [],
  recentChannels: [],
  searching: false,
  paginatedRecentChannelsUrl: null,
  messages: {},
  unreadMessages: [],
  notificationMessage: null,
  fetchingChannelsSuccess: false,
  currentChannel: null,
  refreshing: true,
  loading: false,
  fetchingMessages: false,
  isRehydrated: false,
  appState: "active",
  isChatWidgetVisible: false,
};

const persistConfig = {
  key: "chat",
  storage,
  blacklist: [
    "refreshing",
    "isUserLoggedIn",
    "isRehydrated",
    "notificationMessage",
    "recentChannels",
    "searching",
    "currentChannel",
  ],
  whitelist: ["unreadMessages", "appState", "activeChannels"],
};

const chatReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case EChatActionTypes.TOGGLE_CHAT_WIDGET:
        return {
          ...state,
          isChatWidgetVisible: action.payload,
        };
      case EChatActionTypes.GET_MESSAGES_HISTORY:
        return {
          ...state,
          fetchingMessages: true,
        };
      case EChatActionTypes.SET_APP_STATE:
        return {
          ...state,
          appState: action.payload,
        };
      case EChatActionTypes.GET_UNREAD_MESSAGE_SUCCESS:
        return {
          ...state,
          unreadMessages: action.payload,
        };
      case EChatActionTypes.GET_ACTIVE_CHANNELS_SUCCESS:
        return {
          ...state,
          activeChannels: action.payload,
          fetchingChannelsSuccess: Math.random(),
          searching: false,
        };
      case EChatActionTypes.GET_ACTIVE_CHANNELS:
        return {
          ...state,
          searching: true,
        };
      case EChatActionTypes.GET_RECENT_CHANNELS_SUCCESS:
        return {
          ...state,
          recentChannels: action.payload.recentChannels,
          paginatedRecentChannelsUrl: action.payload.paginatedRecentChannelsUrl,
          searching: false,
        };
      case EChatActionTypes.GET_RECENT_CHANNELS:
        return {
          ...state,
          searching: true,
        };
      case EChatActionTypes.SET_ACTIVE_CHANNELS_SUCCESS:
        return {
          ...state,
          [action.payload.request_status + "Channels"]: action.payload.channels,
        };
      case EChatActionTypes.SET_CURRENT_CHANNEL:
        return {
          ...state,
          currentChannel: action.payload,
          messages: {
            ...state.messages,
            nextPageUrl: null,
          },
        };
      case EChatActionTypes.SET_MESSAGES_SUCCESS:
        return {
          ...state,
          messages: {
            ...state.messages,
            [action.payload.channelId]: action.payload.messages || [],
          },
        };
      case EChatActionTypes.SET_MESSAGES_HISTORY_SUCCESS:
        return {
          ...state,
          fetchingMessages: false,
          messages: {
            ...state.messages,
            [action.payload.channelId]: action.payload.messages || [],
            nextPageUrl: action.payload.nextPageUrl,
          },
        };
      case EChatActionTypes.GET_MESSAGES_HISTORY_FAILED:
        return {
          ...state,
          fetchingMessages: false,
        };
      case EChatActionTypes.SET_NOTIFICATION_MESSAGE:
        return {
          ...state,
          notificationMessage: action.payload,
        };
      case EChatActionTypes.UPLOAD_IMAGE_HISTORY:
      case EChatActionTypes.CREATE_NEW_CHANNEL:
        return {
          ...state,
          loading: true,
        };
      case EChatActionTypes.UPLOAD_IMAGE_HISTORY_FAILED:
      case EChatActionTypes.CREATE_NEW_CHANNEL_FAILED:
        return {
          ...state,
          loading: false,
          error: Math.random(),
        };
      case EChatActionTypes.CREATE_NEW_CHANNEL_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
      case EChatActionTypes.UPLOAD_IMAGE_HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
      default:
        return state;
    }
  }
);

export default chatReducer;
