import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomInput from "components/CustomInput/CustomInput";
import {
  InputAdornment,
  Button,
  Fab,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText
} from "@material-ui/core";
// import Button from "components/CustomButtons/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomAutoCompleteInput from "components/CustomAutoCompleteInput/CustomAutoCompleteInput";
import { useSelector } from "react-redux";
import { REQUEST_TYPE } from "../../../../src/constants";

const names = ["5qhdbk1", "5qhdbk2", "5qhdbk3", "5qhdbk4", "5qhdbk5"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const CarsDetails = props => {
  const { cars } = useSelector(state => state.cars);
  const { agents } = useSelector(state => state.agents);

  const generateAttachedTrucks = transportId => {
    if (transportId)
      return props.trucks.filter(x => x.security_transport_id == transportId);
    return [];
  };
  const generateTruckLabel = option => {
    if (option) {
      let optionLabel = `${option.plate_number}`;
      if (option.brand) optionLabel = `${optionLabel} - ${option.brand}`;
      if (option.color) optionLabel = `${optionLabel} - ${option.color}`;
      return optionLabel;
    }
    return "";
  };
  const isTruckDisabled = (truck, transportId) => {
    if (!truck.security_transport_id) return false;
    if (truck.security_transport_id == transportId) return false;

    return true;
  };
  const handleChange = (selectedTrucks, transportId) => {
    let updatedTrucks = [...props.trucks];

    // for (let i = 0; i < updatedTrucks.length; i++) {
    //   if (updatedTrucks[i].security_transport_id == transportId)
    //     delete updatedTrucks[i].security_transport_id;
    // }
    for (let i = 0; i < selectedTrucks.length; i++) {
      updatedTrucks.filter(
        x => x.id == selectedTrucks[i].id
      )[0].security_transport_id = transportId;
    }
    props.onSetTrucks(updatedTrucks);
  };

  const onEmptyAgent = (agent, index) => {
    props.onSelect("agent_transport", null, index);
  };

  const onRemoveTransportDetails = () => {
    let updatedTrucks = [...props.trucks];
    for (let i = 0; i < updatedTrucks.length; i++) {
      if (updatedTrucks[i].security_car_id == props.transport.security_car.id)
        delete updatedTrucks[i].security_car_id;
    }
    props.onSetTrucks(updatedTrucks);
    props.onRemoveTransportDetails();
  };

  return (
    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
      <GridContainer justify="flex-start">
        <GridContainer justify="flex-end">
          {props.allowRemove && (
            <Button
              style={{
                float: "right",
                backgroundColor: "transparent",
                color: "#F44336"
              }}
              size="sm"
              variant="text"
              onClick={onRemoveTransportDetails}
            >
              <DeleteIcon fontSize="small" />
              Remove car details
            </Button>
          )}
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h5 style={{ float: "left" }}>Security car details</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomAutoCompleteInput
            id="success"
            autocompleteValue={{
              plate_number: ""
            }}
            optionLabel={option => {
              if (option) {
                let optionLabel = option.plate_number;
                if (option.brand)
                  optionLabel = `${optionLabel} - ${option.brand}`;
                if (option.color)
                  optionLabel = `${optionLabel} - ${option.color}`;

                return optionLabel;
              }
            }}
            getOptionDisabled={option =>
              props.transports.filter(
                item => item.security_car.id === option.id
              ).length > 0
            }
            filterKey="plate_number"
            onSelect={obj =>
              props.onSelect && props.onSelect("security_car", obj)
            }
            options={cars}
            noOptionsText="no car found in your stock please add it manually"
            placeholder="search existing cars"
            helperText={
              props.transport.security_car.plateNumberState === "error" &&
              "plate number is not valid"
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4} style={{ marginTop: 40 }}>
          <CustomInput
            id="success"
            freeSolo
            labelText="Plate number"
            helperText={
              props.transport.security_car.plateNumberState === "error" &&
              "plate number is not valid"
            }
            disabled={!!props.transport.security_car.id}
            success={
              props.transport.security_car.plateNumberState === "success"
            }
            error={props.transport.security_car.plateNumberState === "error"}
            onChange={text =>
              props.onChange("security_car", "plate_number", text.toUpperCase())
            }
            value={props.transport.security_car.plate_number}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} style={{ marginTop: 40 }}>
          <CustomInput
            id="success"
            disabled={!!props.transport.security_car.id}
            labelText="Brand(optional)"
            onChange={text => props.onChange("security_car", "brand", text)}
            value={props.transport.security_car.brand || ""}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} style={{ marginTop: 40 }}>
          <CustomInput
            id="success"
            disabled={!!props.transport.security_car.id}
            labelText="Color(optional)"
            onChange={text => props.onChange("security_car", "color", text)}
            value={props.transport.security_car.color || ""}
          />
        </GridItem>
        {props.transport.security_car.id && (
          <GridItem xs={12} sm={12} md={2} style={{ marginTop: 40 }}>
            <Button
              onClick={() => props.onSelect("security_car", null)}
              style={{
                float: "right",
                backgroundColor: "transparent",
                color: "orange",
                borderColor: "orange",
                marginTop: 15
              }}
              variant="outlined"
            >
              <CancelIcon fontSize="small" />
            </Button>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          {props.transport.security_car.plateNumberState === "success" &&
            !props.transport.security_car.id && (
              <Typography
                color="primary"
                style={{ float: "left", fontSize: 12 }}
                variant="body1"
              >
                This new car will be added to your stock after you send the
                request..
              </Typography>
            )}
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ marginTop: 20 }}>
          {props.request.type == REQUEST_TYPE.ESCORT && (
            <FormControl fullWidth>
              <InputLabel id="demo-mutiple-checkbox-label">
                Attach Truck(s)
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                fullWidth
                disabled={!props.transport.id}
                multiple
                value={generateAttachedTrucks(props.transport.id)}
                onChange={event =>
                  handleChange(event.target.value, props.transport.id)
                }
                input={<Input />}
                renderValue={selected =>
                  selected
                    .map(x => (x.truck ? x.truck.plate_number : "Truck" + x.id))
                    .join(", ")
                }
                MenuProps={MenuProps}
              >
                {props.trucks.map((item, index) => (
                  <MenuItem
                    // disabled={isTruckDisabled(item, props.transport.id)}
                    key={item.id + ""}
                    value={item}
                  >
                    <Checkbox
                      color="primary"
                      checked={item.security_transport_id == props.transport.id}
                    />
                    {item.truck ? (
                      <ListItemText primary={generateTruckLabel(item.truck)} />
                    ) : (
                      <ListItemText
                        primary={`Truck${item.id}: missing information`}
                      />
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </GridItem>

        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{ marginTop: 40, alignItems: "center" }}
        >
          <Divider />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
          <h5 style={{ float: "left" }}>Agents Details</h5>
        </GridItem>
        {props.transport &&
          props.transport.agent_transport.map((agent, index) => {
            // const agent = item.agent ? item.agent : item;
            return (
              <>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <CustomAutoCompleteInput
                    options={agents}
                    key={index + ""}
                    getOptionDisabled={option =>
                      props.transport.agent_transport.filter(
                        x => x.id === option.id
                      ).length > 0
                    }
                    filterKey="first_name"
                    onSelect={obj =>
                      props.onSelect &&
                      props.onSelect("agent_transport", obj, index)
                    }
                    placeholder="search existing agents"
                    getOptionDisabled={option =>
                      props.transports.filter(
                        x =>
                          x.agent_transport.filter(x => x.id === option.id)
                            .length > 0
                      ).length > 0
                    }
                    autocompleteValue={{ first_name: "" }}
                    optionLabel={option => {
                      if (option && option.first_name && option.last_name) {
                        let optionLabel = `${option.first_name} ${option.last_name}`;
                        if (option.phone_number)
                          optionLabel = `${optionLabel} - ${option.phone_number}`;
                        return optionLabel;
                      }
                      return "";
                    }}
                    id="success"
                    noOptionsText="no agent found in your stock"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3} style={{ marginTop: 20 }}>
                  <CustomInput
                    id="success"
                    labelText="First Name"
                    onChange={text =>
                      props.onChange(
                        "agent_transport",
                        "first_name",
                        text,
                        index
                      )
                    }
                    helperText={
                      agent.agentFirstNameState === "error" &&
                      "first name is required"
                    }
                    disabled={true}
                    success={agent.agentFirstNameState === "success"}
                    error={agent.agentFirstNameState === "error"}
                    value={agent.first_name}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3} style={{ marginTop: 20 }}>
                  <CustomInput
                    id="success"
                    labelText="Last Name"
                    disabled={true}
                    onChange={text =>
                      props.onChange(
                        "agent_transport",
                        "last_name",
                        text,
                        index
                      )
                    }
                    helperText={
                      !agent.last_name &&
                      agent.agentLastNameState === "error" &&
                      "last name required"
                    }
                    success={agent.agentLastNameState === "success"}
                    error={
                      !agent.last_name && agent.agentLastNameState === "error"
                    }
                    value={agent.last_name}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} style={{ marginTop: 20 }}>
                  <CustomInput
                    id="success"
                    disabled={true}
                    labelText={`Phone number ${
                      props.requestType !== REQUEST_TYPE.MONITORING
                        ? "(optional)"
                        : ""
                    }`}
                    onChange={text =>
                      props.onChange(
                        "agent_transport",
                        "phone_number",
                        text,
                        index
                      )
                    }
                    helperText={
                      agent.phone_number &&
                      agent.agentPhoneNumberState === "error" &&
                      "phone number is not valid"
                    }
                    success={
                      agent.phone_number &&
                      agent.agentPhoneNumberState === "success"
                    }
                    error={
                      agent.phone_number &&
                      agent.agentPhoneNumberState === "error"
                        ? true
                        : false
                    }
                    value={agent.phone_number}
                  />
                </GridItem>
                {!agent.id && (
                  <Typography
                    color="primary"
                    style={{ float: "left", fontSize: 12, marginLeft: 20 }}
                    variant="body1"
                  >
                    you can only select from existing agents.
                  </Typography>
                )}
                {agent.id && (
                  <GridItem xs={12} sm={12} md={2} style={{ marginTop: 20 }}>
                    <Button
                      onClick={() => onEmptyAgent(agent, index)}
                      style={{
                        float: "right",
                        backgroundColor: "transparent",
                        color: "orange",
                        borderColor: "orange",
                        marginTop: 15
                      }}
                      size="sm"
                      variant="outlined"
                    >
                      <CancelIcon fontSize="small" />
                    </Button>
                  </GridItem>
                )}
                {index > 0 && (
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      onClick={() => props.onRemoveAgent(index)}
                      style={{
                        float: "left",
                        backgroundColor: "transparent",
                        color: "#F44336",
                        borderColor: "#F44336",
                        marginTop: 15
                      }}
                      size="sm"
                      variant="outlined"
                    >
                      Remove agent
                      <DeleteIcon fontSize="small" />
                    </Button>
                  </GridItem>
                )}
              </>
            );
          })}
        {/* <Button  size="sm" color="primary" variant="contained">Remove Driver</Button> */}
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
          <Button
            onClick={props.onAddAgent}
            style={{
              float: "left"
            }}
            color="primary"
            variant="contained"
          >
            Add new Agent
          </Button>
        </GridItem>
      </GridContainer>
    </GridItem>
  );
};
export default CarsDetails;
