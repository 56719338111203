import React, { useState, useEffect } from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import XLSX from "xlsx";
import moment from "moment";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { trailersActions } from "../../redux/trailers/actions";
import DateRangeIcon from "@material-ui/icons/DateRange";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import NewTruckDialog from "./NewTruckDialog";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { requestsActions } from "redux/requests/actions";
import { Checkbox, LinearProgress } from "@material-ui/core";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  input: {
    paddingTop: 0
  }
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles
});

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
}

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export const ExportEscort = () => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = React.useState({});
  const { filterRequests, saving } = useSelector(state => state.requests);
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);

  useEffect(() => {
    const data = filterRequests.map((prop, key) => {
      const clientTransport = prop.client_transport[0];
      let uniqueAgents = "";
      let uniquesCars = "";
      if (prop.agent_transport.length > 0) {
        const agents = prop.agent_transport.map(x => x.agent);
        uniqueAgents = removeDuplicates(agents, "id")
          .map(x => x.first_name + " " + x.last_name)
          .join(" / ");
      }
      if (prop.request_shift.length > 0) {
        const agents = prop.request_shift.map(item =>
          item.request_team.map(x => x.security_car)
        );

        const reducedCars = [].concat(...agents);

        uniquesCars = removeDuplicates(reducedCars, "id")
          .map(x => x.plate_number)
          .join(" / ");
      }

      prop.checked = true;
      return {
        identifier: prop.identifier,
        pup: prop.pup,
        dop: prop.dop,
        start_date: moment(prop.start_date).format("dddd D MMMM YYYY"),
        start_time: moment(prop.start_date).format("HH:mm"),
        depart_time: prop.depart_time || "",
        deliver_day: prop.deliver_day || "",
        deliver_time: prop.deliver_time || "",
        truck_plate: clientTransport.truck
          ? clientTransport.truck.plate_number
          : "",
        trailer_plate: clientTransport.trailer
          ? clientTransport.trailer.plate_number
          : "",
        driver1: clientTransport.driver1
          ? clientTransport.driver1.first_name +
            " " +
            clientTransport.driver1.last_name
          : "",
        driver2: clientTransport.driver2
          ? clientTransport.driver2.first_name +
            " " +
            clientTransport.driver2.last_name
          : "",
        escort_plates: uniquesCars,
        escort_drivers: uniqueAgents,
        remarks: prop.security_notes || "",
        checked: prop.checked,
        id: key
      };
    });
    setData(data);
  }, [filterRequests]);

  const onSelectEscort = (event, id) => {
    const copyData = [...data];
    copyData.filter(x => x.id == id)[0].checked = event.target.checked;
    setData(copyData);
  };

  const onRequestFilter = range => {
    toggle();
    setDateRange(range);
    if (range.startDate) {
      const startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
      const endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");
      dispatch(requestsActions.filterEscortByDate({ startDate, endDate }));
    }
  };

  const onExportEscort = () => {
    const output = data
      .filter(x => x.checked == true)
      .map(function(obj) {
        // delete obj.id;
        // delete obj.checked;
        return Object.keys(obj)
          .filter(key => key !== "id" && key !== "checked")
          .map(function(key) {
            return obj[key];
          });
      });
    dispatch(requestsActions.exportEscorts({ body: output, dateRange }));
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button onClick={toggle} color="primary" round>
          <DateRangeIcon />
          {dateRange.startDate
            ? `From ${moment(dateRange.startDate).format(
                "DD/MM/YYYY"
              )} To ${moment(dateRange.endDate).format("DD/MM/YYYY")}`
            : "Select Loading Dates"}
        </Button>
        <DateRangePicker
          open={open}
          color="primary"
          toggle={toggle}
          onChange={range => onRequestFilter(range)}
        />
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <FileCopyIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Export Escorts ({data.length})
            </h4>
            <div style={{ float: "right" }}>
              <Button
                onClick={onExportEscort}
                disabled={
                  data.filter(x => x.checked == true).length === 0 || saving
                }
                color="primary"
              >
                Export {data.filter(x => x.checked == true).length} Escorts
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {saving && <LinearProgress color="primary" />}
            <ReactTable
              FilterComponent={({ filter, onChange }) => (
                <CustomInput
                  value={filter ? filter.value : ""}
                  onChange={value => onChange(value)}
                  id="success"
                  // labelText="Plate number"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.input
                  }}
                  inputProps={{
                    value: filter ? filter.value : ""
                  }}
                />
              )}
              data={data}
              filterable
              defaultFilterMethod={filterCaseInsensitive}
              columns={[
                {
                  Header: "Selected",
                  Cell: props => (
                    <Checkbox
                      color="primary"
                      onChange={e => onSelectEscort(e, props.original.id)}
                      checked={props.original.checked}
                    />
                  ),
                  sortable: false,
                  filterable: false
                },
                {
                  Header: "#Number",
                  accessor: "identifier"
                },
                {
                  Header: "Loading at",
                  accessor: "pup",
                  width: 250
                },
                {
                  Header: "Delivery address",
                  accessor: "dop",
                  width: 250
                },
                {
                  Header: "Loading day",
                  accessor: "start_date"
                },
                {
                  Header: "Loading time",
                  accessor: "start_time"
                },
                {
                  Header: "Departing Time",
                  accessor: "depart_time"
                },
                {
                  Header: "Deliver day",
                  accessor: "deliver_day"
                },
                {
                  Header: "Deliver time",
                  accessor: "deliver_time"
                },
                {
                  Header: "Truck Plate",
                  accessor: "truck_plate"
                },
                {
                  Header: "Trailer Plate",
                  accessor: "trailer_plate"
                },
                {
                  Header: "Driver1",
                  accessor: "driver1"
                },
                {
                  Header: "Driver2",
                  accessor: "driver2"
                },
                {
                  Header: "Escort Plate(s)",
                  accessor: "escort_plates"
                },
                {
                  Header: "Escort Driver(s)",
                  accessor: "escort_drivers"
                },
                {
                  Header: "Remarks",
                  accessor: "remarks",
                  width: 250
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ExportEscort;
