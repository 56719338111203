export const ECarsActionTypes = {
  GET_CARS: "GET_CARS",
  GET_CARS_SUCCESS: "GET_CARS_SUCCESS",
  GET_CARS_FAILED: "GET_CARS_FAILED",
  SAVE_CAR: "SAVE_CAR",
  DELETE_CAR: "DELETE_CAR",
  SAVING_SUCCESS: "SAVING_CAR_SUCCESS",
  SAVING_FAILED: "SAVING_CAR_FAILED",
  DELETING_SUCCESS: "DELETING_CAR_SUCCESS",
  DELETING_FAILED: "DELETING_CAR_FAILED",
  RESET_SUCCESS_DELETE: "RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE"
};

export const carsActions = {
  fetchCars: () => ({
    type: ECarsActionTypes.GET_CARS
  }),
  saveCar: payload => ({
    type: ECarsActionTypes.SAVE_CAR,
    payload
  }),
  deleteCar: payload => ({
    type: ECarsActionTypes.DELETE_CAR,
    payload
  }),
  resetSuccessDelete: () => ({
    type: ECarsActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: ECarsActionTypes.RESET_SUCCESS_SAVE
  })
};
