import { takeLatest, call, put, select } from "redux-saga/effects";
import { ERequestsActionTypes } from "./actions";
import axios from "../axios";
import moment from "moment";
import { REQUEST_HISTORY, REQUEST_STATUS } from "../../../src/constants";
import { EChatActionTypes } from "redux/chat/actions";

function* fetchRequests(action) {
  try {
    const { user } = yield select((state) => state.user);
    const requestUrl =
      process.env.REACT_APP_ROLE === "client"
        ? `/api/requests_by_branch/${user.branch_id}?startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`
        : `/api/requests?startDate=${action.payload.startDate}&endDate=${action.payload.endDate}&client_id=${action.payload.clientId}`;
    const response = yield call([axios, axios.get], requestUrl);

    if (response && response.data && response.data.success) {
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: response.data.success,
      });
    } else {
      yield put({ type: ERequestsActionTypes.GET_REQUESTS_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.GET_REQUESTS_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* getActiveRequests(action) {
  const { searchedRequest, requestHistory } = yield select(
    (state) => state.requests
  );
  console.log("action.payload.archived : ", action.payload.archived);
  if (!action.payload.archived) {
    try {
      const response = yield call([axios, axios.get], "/api/requests/active");

      if (response && response.data && response.data.success) {
        yield put({
          type: ERequestsActionTypes.GET_ACTIVE_REQUESTS_SUCCESS,
          payload: response.data.success,
        });
      } else {
        yield put({ type: ERequestsActionTypes.GET_ACTIVE_REQUESTS_FAILED });
      }
    } catch (e) {
      yield put({
        type: ERequestsActionTypes.GET_ACTIVE_REQUESTS_FAILED,
        payload: e,
      });
    }
  } else {
    console.log("requestHistory : ", requestHistory);
    yield put({
      type: ERequestsActionTypes.GET_ACTIVE_REQUESTS_SUCCESS,
      payload: [
        {
          ...searchedRequest,
          active_request_history: requestHistory,
        },
      ],
    });
    yield put({
      type: ERequestsActionTypes.GET_TEAM_HISTORY_SUCCESS,
      payload: requestHistory.filter(
        (x) => x.history_status != REQUEST_HISTORY.NOTE
      ),
    });
  }
}
function* getCoordsHistory(action) {
  try {
    // const url = action.payload.archived
    //   ? `/api/requests/history/coords/archived/${action.payload.team_id}`
    //   : `/api/requests/history/coords/${action.payload.team_id}`;
    const response = yield call(
      [axios, axios.get],
      `/api/requests/history/coords/${action.payload.team_id}`
    );

    if (response && response.data) {
      yield put({
        type: ERequestsActionTypes.GET_COORDS_HISTORY_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({ type: ERequestsActionTypes.GET_COORDS_HISTORY_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.GET_COORDS_HISTORY_FAILED,
      payload: e,
    });
  }
}
function* getTeamHistory(action) {
  try {
    const response = yield call(
      [axios, axios.get],
      `/api/requests/history/team/${action.payload}`
    );

    if (response && response.data) {
      yield put({
        type: ERequestsActionTypes.GET_TEAM_HISTORY_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({ type: ERequestsActionTypes.GET_TEAM_HISTORY_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.GET_TEAM_HISTORY_FAILED,
      payload: e,
    });
  }
}
function* searchRequest(action) {
  try {
    const requestUrl = `/api/requests/${action.payload}`;
    const response = yield call([axios, axios.get], requestUrl);

    if (response && response.data && response.data.success) {
      yield put({
        type: ERequestsActionTypes.SEARCH_REQUEST_SUCCESS,
        payload: response.data.success,
      });
    } else {
      yield put({
        type: ERequestsActionTypes.SEARCH_REQUEST_FAILED,
        payload: "Request Not found!",
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.SEARCH_REQUEST_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* getRequestHistory(action) {
  try {
    const { searchedRequest } = yield select((state) => state.requests);
    let requestUrl = `/api/requests/history/${action.payload}`;
    const response = yield call([axios, axios.post], requestUrl, {
      shifts: searchedRequest
        ? searchedRequest.request_shift.map((x) => x.id)
        : null,
    });

    if (response && response.data && response.data.success) {
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_HISTORY_SUCCESS,
        payload: response.data.success,
      });
    } else {
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_HISTORY_FAILED,
        payload: "Internal Server Error",
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.GET_REQUESTS_HISTORY_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* cancelRequest(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/requests/cancel/${action.payload.id}`,
      { reason: action.payload.reason }
    );

    if (response && response.status === 200 && !response.data.error) {
      yield put({ type: ERequestsActionTypes.CANCEL_REQUEST_SUCCESS });
      const requestIndex = requests.findIndex(
        (x) => x.id === action.payload.id
      );
      requests[requestIndex].status = REQUEST_STATUS.CANCELED;
      requests[requestIndex].rejection_reason = action.payload.reason;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({ type: ERequestsActionTypes.CANCEL_REQUEST_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.CANCEL_REQUEST_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* forwardRequest(action) {
  try {
    const { id, subcontractor, shifts, isCancel } = action.payload;
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/requests/forward/${id}`,
      {
        subcontractor_id: subcontractor.id,
        email: subcontractor.email,
        shifts: shifts,
        isCancel: isCancel ? 1 : 0,
      }
    );

    if (response && response.status === 200 && !response.data.error) {
      if (!isCancel)
        yield put({ type: ERequestsActionTypes.FORWARD_REQUEST_SUCCESS });
      else yield put({ type: ERequestsActionTypes.CANCEL_FORWARD_SUCCESS });
      const requestIndex = requests.findIndex((x) => x.id === id);
      requests[requestIndex].subcontractor_id = isCancel
        ? null
        : subcontractor.id;
      requests[requestIndex].subcontractor_status = null;
      requests[requestIndex].subcontractor = isCancel ? null : subcontractor;
      if (isCancel) {
        const subcShifts = [
          ...requests[requestIndex].request_shift.filter(
            (x) => !!x.subcontractor_id
          ),
        ];
        for (let i = 0; i < subcShifts.length; i++) {
          const shiftIndex = requests[requestIndex].request_shift.findIndex(
            (x) => x.id == subcShifts[i].id
          );
          requests[requestIndex].request_shift[shiftIndex].request_team = [];
          requests[requestIndex].request_shift[
            shiftIndex
          ].subcontractor_id = null;
          requests[requestIndex].request_shift[
            shiftIndex
          ].subcontractor_status = null;
          requests[requestIndex].request_shift[shiftIndex].subcontractor = null;
        }
      } else {
        for (
          let shiftIndex = 0;
          shiftIndex < action.payload.shifts.length;
          shiftIndex++
        ) {
          requests[requestIndex].request_shift[shiftIndex].subcontractor_id =
            subcontractor.id;
          requests[requestIndex].request_shift[
            shiftIndex
          ].subcontractor_status = null;
          requests[requestIndex].request_shift[
            shiftIndex
          ].subcontractor = subcontractor;
        }
      }

      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({ type: ERequestsActionTypes.FORWARD_REQUEST_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.FORWARD_REQUEST_FAILED,
      payload: "Internal server error",
    });
  }
}
function* forwardShift(action) {
  try {
    const { id, subcontractor, isCancel, request_id } = action.payload;
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/requests/shift/forward/${id}`,
      {
        subcontractor_id: subcontractor.id,
        email: subcontractor.email,
        request_id: request_id,
        isCancel: isCancel ? 1 : 0,
      }
    );
    if (response && response.status === 200 && !response.data.error) {
      if (!isCancel)
        yield put({ type: ERequestsActionTypes.FORWARD_SHIFT_SUCCESS });
      else yield put({ type: ERequestsActionTypes.CANCEL_FORWARD_SUCCESS });
      const requestIndex = requests.findIndex((x) => x.id === request_id);
      requests[requestIndex].subcontractor_id = null;
      requests[requestIndex].subcontractor_status = null;
      requests[requestIndex].subcontractor = null;

      const shiftIndex = requests[requestIndex].request_shift.findIndex(
        (x) => x.id == id
      );
      if (isCancel) {
        requests[requestIndex].request_shift[
          shiftIndex
        ].subcontractor_id = null;
        requests[requestIndex].request_shift[
          shiftIndex
        ].subcontractor_status = null;
        requests[requestIndex].request_shift[shiftIndex].subcontractor = null;
      } else {
        requests[requestIndex].request_shift[shiftIndex].subcontractor_id =
          subcontractor.id;
        requests[requestIndex].request_shift[
          shiftIndex
        ].subcontractor_status = null;
        requests[requestIndex].request_shift[
          shiftIndex
        ].subcontractor = subcontractor;
      }

      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({ type: ERequestsActionTypes.FORWARD_SHIFT_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.FORWARD_SHIFT_FAILED,
      payload: e,
    });
  }
}
function* onSubcontractorRequestChange(action) {
  try {
    const { requests, searchedRequest } = yield select(
      (state) => state.requests
    );
    const response = yield call(
      [axios, axios.put],
      `/api/requests/subc/status/${action.payload.id}`,
      {
        status: action.payload.status,
        shifts: action.payload.shifts,
      }
    );

    if (response && response.status === 200 && !response.data.error) {
      yield put({ type: ERequestsActionTypes.SUBC_REQUEST_CHANGE_SUCCESS });
      if (action.payload.status == 1) {
        const requestIndex = requests.findIndex(
          (x) => x.id === action.payload.id
        );
        if (requests[requestIndex].subcontractor_id)
          requests[requestIndex].subcontractor_status = action.payload.status;
        if (requests[requestIndex].request_shift.length > 0) {
          requests[requestIndex].request_shift[0].subcontractor_status =
            action.payload.status;
        }
        yield put({
          type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
          payload: [...requests],
        });
        console.log("searchedRequest : ", searchedRequest);
        if (
          searchedRequest &&
          searchedRequest.id == requests[requestIndex].id
        ) {
          const copyRequest = { ...searchedRequest };
          if (copyRequest.subcontractor_id)
            copyRequest.subcontractor_status = action.payload.status;
          if (copyRequest.request_shift.length > 0) {
            copyRequest.request_shift[0].subcontractor_status =
              action.payload.status;
          }
          yield put({
            type: ERequestsActionTypes.SEARCH_REQUEST_SUCCESS,
            payload: copyRequest,
          });
        }
      } else {
        yield put({
          type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
          payload: [...requests.filter((x) => x.id != action.payload.id)],
        });
        yield put({
          type: EChatActionTypes.GET_ACTIVE_CHANNELS,
        });
      }
    } else {
      yield put({ type: ERequestsActionTypes.SUBC_REQUEST_CHANGE_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.SUBC_REQUEST_CHANGE_FAILED,
      payload: "Internal server error",
    });
  }
}
function* archiveRequest(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/requests/archive/${action.payload.id}`
    );

    if (response && response.status === 200 && !response.data.error) {
      yield put({ type: ERequestsActionTypes.ARCHIVE_REQUEST_SUCCESS });
      const requestIndex = requests.findIndex(
        (x) => x.id === action.payload.id
      );
      requests[requestIndex].status = REQUEST_STATUS.ARCHIVED;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({ type: ERequestsActionTypes.ARCHIVE_REQUEST_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.ARCHIVE_REQUEST_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* checkRequest(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/requests/check/${action.payload.id}`,
      { note: action.payload.note }
    );

    if (response && response.status === 200 && !response.data.error) {
      yield put({ type: ERequestsActionTypes.CHECK_REQUEST_SUCCESS });
      const requestIndex = requests.findIndex(
        (x) => x.id === action.payload.id
      );
      requests[requestIndex].status = REQUEST_STATUS.CHECKED;
      requests[requestIndex].archive_note = action.payload.note;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({ type: ERequestsActionTypes.CHECK_REQUEST_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.CHECK_REQUEST_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* deleteRequest(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.delete],
      `/api/requests/${action.payload.id}`
    );

    if (response && response.status === 200 && !response.data.error) {
      yield put({ type: ERequestsActionTypes.DELETE_REQUEST_SUCCESS });

      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests.filter((x) => x.id != action.payload.id)],
      });
    } else {
      yield put({ type: ERequestsActionTypes.DELETE_REQUEST_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.DELETE_REQUEST_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* setRequestHistory(action) {
  try {
    const { requestHistory } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/requests/history/${action.payload.id}`,
      action.payload
    );

    if (response && response.status === 200 && !response.data.error) {
      yield put({ type: ERequestsActionTypes.SET_REQUEST_HISTORY_SUCCESS });
      const requestIndex = requestHistory.findIndex(
        (x) => x.id === action.payload.id
      );
      requestHistory[requestIndex] = action.payload;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_HISTORY_SUCCESS,
        payload: [...requestHistory],
      });
    } else {
      yield put({ type: ERequestsActionTypes.SET_REQUEST_HISTORY_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.SET_REQUEST_HISTORY_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* deleteRequestHistory(action) {
  try {
    const { requestHistory } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/requests/history/delete/${action.payload.id}`,
      action.payload
    );

    if (response && response.status === 200 && !response.data.error) {
      yield put({ type: ERequestsActionTypes.DELETE_REQUEST_HISTORY_SUCCESS });
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_HISTORY_SUCCESS,
        payload: [...requestHistory.filter((x) => x.id != action.payload.id)],
      });
    } else {
      yield put({ type: ERequestsActionTypes.DELETE_REQUEST_HISTORY_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.DELETE_REQUEST_HISTORY_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* setViewRequest(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/requests/view/${action.payload}`
    );

    if (response && response.status === 200 && !response.data.error) {
      yield put({ type: ERequestsActionTypes.SET_VIEW_REQUEST_SUCCESS });
      const requestIndex = requests.findIndex((x) => x.id === action.payload);
      requests[requestIndex].viewed = 1;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({ type: ERequestsActionTypes.SET_VIEW_REQUEST_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.SET_VIEW_REQUEST_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* confirmRequest(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      action.payload.is_new == 1
        ? `/api/requests/new/${action.payload.id}`
        : `/api/requests/${action.payload.id}`,
      action.payload.body
    );

    if (response && response.status === 200) {
      yield put({ type: ERequestsActionTypes.CONFIRMING_SUCCESS });
      const requestIndex = requests.findIndex(
        (x) => x.id === action.payload.id
      );
      requests[requestIndex] = response.data;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({
        type: ERequestsActionTypes.CONFIRMING_FAILED,
        payload: response,
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.CONFIRMING_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* editConfirmRequest(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      action.payload.is_new == 1
        ? `/api/requests/edit/new/${action.payload.id}`
        : `/api/requests/edit/${action.payload.id}`,
      action.payload.body
    );
    if (response && response.data && response.status === 200) {
      yield put({ type: ERequestsActionTypes.EDIT_CONFIRMING_SUCCESS });
      const requestIndex = requests.findIndex(
        (x) => x.id === action.payload.id
      );
      requests[requestIndex] = { ...response.data };
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({ type: ERequestsActionTypes.EDIT_CONFIRMING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.EDIT_CONFIRMING_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* rejectRequest(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/requests/reject/${action.payload.id}`,
      { rejection_reason: action.payload.body }
    );

    if (response && response.status === 200) {
      yield put({ type: ERequestsActionTypes.REJECT_REQUEST_SUCCESS });
      const requestIndex = requests.findIndex(
        (x) => x.id === action.payload.id
      );
      requests[requestIndex].rejection_reason = action.payload.body;
      requests[requestIndex].status = "2"; // rejected
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({ type: ERequestsActionTypes.CANCEL_REQUEST_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.CANCEL_REQUEST_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* editDateRequest(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/client/requests/date/${action.payload.id}`,
      {
        start_date: action.payload.start_date,
        start_date_key: action.payload.start_date_key,
      }
    );

    if (response && response.status === 200) {
      yield put({ type: ERequestsActionTypes.EDIT_DATE_REQUEST_SUCCESS });
      const requestIndex = requests.findIndex(
        (x) => x.id === action.payload.id
      );
      requests[requestIndex][action.payload.start_date_key] =
        action.payload.start_date;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({
        type: ERequestsActionTypes.EDIT_DATE_REQUEST_FAILED,
        payload: "Internal Server Error, Please try again",
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.EDIT_DATE_REQUEST_FAILED,
      payload: "Internal Server Error, Please try again",
    });
  }
}
function* updateRequestNotes(action) {
  try {
    const { requests } = yield select((state) => state.requests);
    const response = yield call(
      [axios, axios.put],
      `/api/client/requests/notes/${action.payload.id}`,
      {
        notes: action.payload.notes,
      }
    );

    if (response && response.status === 200) {
      yield put({ type: ERequestsActionTypes.UPDATE_REQUEST_NOTES_SUCCESS });
      const requestIndex = requests.findIndex(
        (x) => x.id === action.payload.id
      );
      requests[requestIndex].notes = action.payload.notes;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({
        type: ERequestsActionTypes.UPDATE_REQUEST_NOTES_FAILED,
        payload: "Internal Server Error, Please try again",
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.UPDATE_REQUEST_NOTES_FAILED,
      payload: "Internal Server Error, Please try again",
    });
  }
}
function* addRequests(action) {
  try {
    action.payload.created_at = moment().format("YYYY-MM-DD H:mm:ss");
    action.payload.original_start_date = action.payload.start_date;

    const response = yield call(
      [axios, axios.post],
      `/api/requests`,
      action.payload
    );

    if (response && response.data && response.data.success) {
      let { requests } = yield select((state) => state.requests);
      yield put({
        type: ERequestsActionTypes.ADDING_SUCCESS,
        payload: response.data.success,
      });
      requests.unshift(response.data.success);
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({ type: ERequestsActionTypes.ADDING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.ADDING_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* sendEscortRequestsExcel(action) {
  try {
    const response = yield call(
      [axios, axios.post],
      `/api/requests/excel/escort`,
      action.payload
    );

    if (response && response.data && response.data.success) {
      yield put({
        type: ERequestsActionTypes.SEND_ESCORT_REQUESTS_EXCEL_SUCCESS,
        payload: response.data.success,
      });
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS,
      });
    } else {
      yield put({
        type: ERequestsActionTypes.SEND_ESCORT_REQUESTS_EXCEL_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.SEND_ESCORT_REQUESTS_EXCEL_FAILED,
      payload: "Internal Server Error",
    });
  }
}
function* filterEscortByDate(action) {
  try {
    const { user } = yield select((state) => state.user);
    const response = yield call(
      [axios, axios.get],
      `/api/requests/escort/filter/${user.branch_id}?startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`
    );

    if (response && response.data && response.data.success) {
      yield put({
        type: ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_SUCCESS,
        payload: response.data.success,
      });
    } else {
      yield put({
        type: ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_FAILED,
      payload: "Internal Server Error",
    });
  }
}
function* filterEscortInvoiceByDate(action) {
  try {
    const response = yield call(
      [axios, axios.get],
      `/api/filter/requests?startDate=${action.payload.startDate}&endDate=${action.payload.endDate}&client_id=${action.payload.clientId}`
    );

    if (response && response.data && response.data.success) {
      yield put({
        type: ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_SUCCESS_INVOICE,
        payload: response.data.success,
      });
    } else {
      yield put({
        type: ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_FAILED_INVOICE,
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_FAILED_INVOICE,
      payload: "Internal Server Error",
    });
  }
}
function* exportEscorts(action) {
  try {
    const response = yield call(
      [axios, axios.post],
      `/api/requests/escort/export`,
      action.payload.body,
      { responseType: "blob" }
    );

    const dateRange = action.payload.dateRange;
    const startDate = moment(dateRange.startDate).format("DD-MM-YYYY");
    const endDate = moment(dateRange.endDate).format("DD-MM-YYYY");

    if (response && response.data) {
      yield put({
        type: ERequestsActionTypes.EXPORT_ESCORTS_SUCCESS,
        payload: response.data.success,
      });
      const data = response.data;
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `escorts_from_${startDate}_to_${endDate}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      yield put({
        type: ERequestsActionTypes.EXPORT_ESCORTS_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.EXPORT_ESCORTS_FAILED,
      payload: "Internal Server Error",
    });
  }
}
function* exportEscortsInvoice(action) {
  try {
    const response = yield call(
      [axios, axios.post],
      `/api/requests/escort/export/invoice`,
      action.payload.body,
      { responseType: "blob" }
    );

    const dateRange = action.payload.dateRange;
    const startDate = moment(dateRange.startDate).format("DD-MM-YYYY");
    const endDate = moment(dateRange.endDate).format("DD-MM-YYYY");

    if (response && response.data) {
      yield put({
        type: ERequestsActionTypes.EXPORT_ESCORTS_SUCCESS_INVOICE,
        payload: response.data.success,
      });
      const data = response.data;
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `escorts_from_${startDate}_to_${endDate}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      yield put({
        type: ERequestsActionTypes.EXPORT_ESCORTS_FAILED_INVOICE,
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.EXPORT_ESCORTS_FAILED_INVOICE,
      payload: "Internal Server Error",
    });
  }
}
function* editTransportsRequest(action) {
  try {
    const response = yield call(
      [axios, axios.put],
      `/api/requests/transports/${action.payload.id}`,
      action.payload
    );

    if (response && response.data && response.status === 200) {
      let { requests } = yield select((state) => state.requests);
      yield put({
        type: ERequestsActionTypes.EDIT_REQUEST_SUCCESS,
        payload: response.data,
      });
      const requestIndex = requests.findIndex(
        (x) => x.id == action.payload.request_id
      );
      requests[requestIndex] = response.data;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({
        type: ERequestsActionTypes.ADDING_FAILED,
        payload: "Internal Server Error",
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.ADDING_FAILED,
      payload: "Internal Server Error",
    });
  }
}
function* addTransportsRequest(action) {
  try {
    console.log("addTransportsRequest : ", action.payload);
    const response = yield call(
      [axios, axios.post],
      `/api/requests/${action.payload.request_id}/transports`,
      action.payload
    );

    console.log("response : ", response);

    if (response && response.data) {
      let { requests } = yield select((state) => state.requests);
      yield put({
        type: ERequestsActionTypes.ADD_TRANSPORT_REQUEST_SUCCESS,
        payload: response.data,
      });
      const requestIndex = requests.findIndex(
        (x) => x.id == action.payload.request_id
      );
      requests[requestIndex] = response.data;
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_SUCCESS,
        payload: [...requests],
      });
    } else {
      yield put({
        type: ERequestsActionTypes.ADDING_FAILED,
        payload: "Internal Server Error",
      });
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: ERequestsActionTypes.ADDING_FAILED,
      payload: "Internal Server Error",
    });
  }
}
function* getRequestsMembers(action) {
  try {
    const requestUrl = `/api/requests/members`;
    const response = yield call(
      [axios, axios.post],
      requestUrl,
      action.payload
    );

    if (response && response.data) {
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_MEMBERS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: ERequestsActionTypes.GET_REQUESTS_MEMBERS_FAILED,
        payload: "Request Not found!",
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.GET_REQUESTS_MEMBERS_FAILED,
      payload: e.response,
    });
  }
}

function* getRequestDetails(action) {
  try {
    const requestUrl = `/api/requests/details`;
    const response = yield call(
      [axios, axios.post],
      requestUrl,
      action.payload
    );

    console.log(response.data);
    if (response && response.data && response.data.success) {
      yield put({
        type: ERequestsActionTypes.GET_REQUEST_DETAILS_SUCCESS,
        payload: response.data.success,
      });
    } else {
      yield put({
        type: ERequestsActionTypes.GET_REQUEST_DETAILS_FAILED,
        payload: "Request Not found!",
      });
    }
  } catch (e) {
    yield put({
      type: ERequestsActionTypes.GET_REQUEST_DETAILS_FAILED,
      payload: e.response.statusText,
    });
  }
}
function* getRequestImages(action) {
  try {
    let channelImages = { data: [] };
    const { ids, channelId } = action.payload;
    const requestUrl = `/api/requests/images`;
    const response = yield call([axios, axios.post], requestUrl, ids);
    if (channelId) {
      channelImages = yield call(
        [axios, axios.get],
        `/api/channels/images/${channelId}`
      );
      console.log("channelImages : ", channelImages);
    }

    if (response && response.data && response.data.success && channelImages) {
      yield put({
        type: ERequestsActionTypes.GET_REQUEST_IMAGES_SUCCESS,
        payload: [...channelImages.data, ...response.data.success],
      });
    } else {
      yield put({
        type: ERequestsActionTypes.GET_REQUEST_IMAGES_FAILED,
        payload: "Request Not found!",
      });
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: ERequestsActionTypes.GET_REQUEST_IMAGES_FAILED,
      payload: e.response,
    });
  }
}

function* requestsSaga() {
  yield takeLatest(ERequestsActionTypes.GET_REQUESTS, fetchRequests);
  yield takeLatest(
    ERequestsActionTypes.GET_REQUESTS_HISTORY,
    getRequestHistory
  );
  yield takeLatest(ERequestsActionTypes.ADD_REQUEST, addRequests);
  yield takeLatest(
    ERequestsActionTypes.SEND_ESCORT_REQUESTS_EXCEL,
    sendEscortRequestsExcel
  );
  yield takeLatest(
    ERequestsActionTypes.FILTER_ESCORTS_BY_DATE,
    filterEscortByDate
  );
  yield takeLatest(
    ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_INVOICE,
    filterEscortInvoiceByDate
  );
  yield takeLatest(ERequestsActionTypes.EXPORT_ESCORTS, exportEscorts);
  yield takeLatest(
    ERequestsActionTypes.EXPORT_ESCORTS_INVOICE,
    exportEscortsInvoice
  );
  yield takeLatest(ERequestsActionTypes.EDIT_REQUEST, editTransportsRequest);
  yield takeLatest(
    ERequestsActionTypes.ADD_TRANSPORT_REQUEST,
    addTransportsRequest
  );
  yield takeLatest(ERequestsActionTypes.CANCEL_REQUEST, cancelRequest);
  yield takeLatest(ERequestsActionTypes.CONFIRM_REQUEST, confirmRequest);
  yield takeLatest(ERequestsActionTypes.SEARCH_REQUEST, searchRequest);
  yield takeLatest(
    ERequestsActionTypes.EDIT_CONFIRM_REQUEST,
    editConfirmRequest
  );
  yield takeLatest(ERequestsActionTypes.REJECT_REQUEST, rejectRequest);
  yield takeLatest(ERequestsActionTypes.SET_VIEW_REQUEST, setViewRequest);
  yield takeLatest(ERequestsActionTypes.SET_REQUEST_HISTORY, setRequestHistory);
  yield takeLatest(
    ERequestsActionTypes.DELETE_REQUEST_HISTORY,
    deleteRequestHistory
  );
  yield takeLatest(ERequestsActionTypes.ARCHIVE_REQUEST, archiveRequest);
  yield takeLatest(ERequestsActionTypes.CHECK_REQUEST, checkRequest);
  yield takeLatest(ERequestsActionTypes.DELETE_REQUEST, deleteRequest);
  yield takeLatest(ERequestsActionTypes.EDIT_DATE_REQUEST, editDateRequest);
  yield takeLatest(
    [
      ERequestsActionTypes.GET_ACTIVE_REQUESTS,
      ERequestsActionTypes.REFRESH_ACTIVE_REQUESTS,
    ],
    getActiveRequests
  );
  yield takeLatest(ERequestsActionTypes.FORWARD_REQUEST, forwardRequest);
  yield takeLatest(ERequestsActionTypes.FORWARD_SHIFT, forwardShift);
  yield takeLatest(
    ERequestsActionTypes.SUBC_REQUEST_CHANGE,
    onSubcontractorRequestChange
  );
  yield takeLatest(
    ERequestsActionTypes.UPDATE_REQUEST_NOTES,
    updateRequestNotes
  );
  yield takeLatest(ERequestsActionTypes.GET_COORDS_HISTORY, getCoordsHistory);
  yield takeLatest(ERequestsActionTypes.GET_TEAM_HISTORY, getTeamHistory);
  yield takeLatest(
    ERequestsActionTypes.GET_REQUESTS_MEMBERS,
    getRequestsMembers
  );
  yield takeLatest(ERequestsActionTypes.GET_REQUEST_DETAILS, getRequestDetails);
  yield takeLatest(ERequestsActionTypes.GET_REQUEST_IMAGES, getRequestImages);
}

export default requestsSaga;
