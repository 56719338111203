import React from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import { InputAdornment, DialogActions } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NewAgentDialog = props => {
  const classes = useStyles(styles);
  return (
    <Dialog
      fullScreen
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal
      }}
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>{props.title}</h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <CustomInput
          id="success"
          style={{ marginTop: 20 }}
          labelText="First Name"
          value={props.data.first_name}
          onChange={text => props.onDataChanged("first_name", text)}
          helperText={
            props.data.firstNameState === "error" && "first name is required"
          }
          success={props.data.firstNameState === "success"}
          error={props.data.firstNameState === "error"}
          formControlProps={{
            fullWidth: true
          }}
        />
        <CustomInput
          id="success"
          style={{ marginTop: 20 }}
          labelText="Last Name"
          value={props.data.last_name}
          onChange={text => props.onDataChanged("last_name", text)}
          helperText={
            props.data.lastNameState === "error" && "last name is required"
          }
          success={props.data.lastNameState === "success"}
          error={props.data.lastNameState === "error"}
          formControlProps={{
            fullWidth: true
          }}
        />
        <CustomInput
          id="success"
          style={{ marginTop: 20 }}
          labelText="Phone Number"
          value={props.data.phone_number ? props.data.phone_number : ""}
          onChange={text => props.onDataChanged("phone_number", text)}
          helperText={
            props.data.phoneNumberState === "error" &&
            "phone number is not valid"
          }
          success={props.data.phoneNumberState === "success"}
          error={props.data.phoneNumberState === "error"}
          formControlProps={{
            fullWidth: true
          }}
        />
        <CustomInput
          id="success"
          style={{ marginTop: 20 }}
          labelText="Email"
          value={props.data.email}
          disabled={!!props.data.id}
          onChange={text => props.onDataChanged("email", text)}
          helperText={props.data.email === "error" && "email is not valid"}
          success={props.data.emailState === "success"}
          error={props.data.emailState === "error"}
          formControlProps={{
            fullWidth: true
          }}
        />
        {props.data.emailState === "success" && !props.data.id && (
          <Typography
            color="primary"
            style={{ float: "left", fontSize: 12 }}
            variant="body1"
          >
            {props.data.first_name} will receive an Email to confirm his
            account.
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary"
          simple
        >
          Close
        </Button>
        {props.saving ? (
          <CircularProgress
            size={30}
            color="primary"
            style={{ marginRight: 30 }}
          />
        ) : (
          <Button onClick={props.onSubmit} color="primary" variant="contained">
            {!props.data.user_id ? "Invite" : "Save"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default NewAgentDialog;

NewAgentDialog.prototype = {
  isModalVisible: PropTypes.bool
};
