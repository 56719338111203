import { takeLatest, call, put, select } from "redux-saga/effects";
import { ETrailersActionTypes } from "./actions";
import axios from "../axios";

function* fetchTrucks() {
  const { user } = yield select(state => state.user);
  try {
    const response = yield call(
      [axios, axios.get],
      `/api/trucks_by_branch/${user.branch_id}`
    );
    if (response && response.data && response.data.success) {
      yield put({
        type: ETrailersActionTypes.GET_TRUCKS_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: ETrailersActionTypes.GET_TRUCKS_FAILED });
    }
  } catch (e) {
    yield put({
      type: ETrailersActionTypes.GET_TRUCKS_FAILED,
      payload: e.response.statusText
    });
  }
}
function* fetchTrailers() {
  try {
    const { user } = yield select(state => state.user);
    const response = yield call(
      [axios, axios.get],
      `/api/trailers_by_branch/${user.branch_id}`
    );
    if (response && response.data && response.data.success) {
      yield put({
        type: ETrailersActionTypes.GET_TRAILERS_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: ETrailersActionTypes.GET_TRAILERS_FAILED });
    }
  } catch (e) {
    yield put({
      type: ETrailersActionTypes.GET_TRAILERS_FAILED,
      payload: e.response.statusText
    });
  }
}
function* saveTrailer(action) {
  const { user } = yield select(state => state.user);
  const { trailers } = yield select(state => state.trailers);
  let response;
  const request = {
    ...action.payload,
    branch_id: user.branch_id
  };

  try {
    if (action.payload.id) {
      response = yield call(
        [axios, axios.put],
        `/api/trailers/${action.payload.id}`,
        request
      );
    } else {
      response = yield call([axios, axios.post], `/api/trailers`, request);
    }

    if (response && response.status === 200) {
      yield put({
        type: ETrailersActionTypes.SAVING_SUCCESS,
        payload: response.data.success
      });
      if (action.payload.id) {
        const trailerIndex = trailers.findIndex(
          x => x.id === action.payload.id
        );
        trailers[trailerIndex] = action.payload;
        yield put({
          type: ETrailersActionTypes.GET_TRAILERS_SUCCESS,
          payload: [...trailers]
        });
      } else {
        const id = response.data.success.id;
        const trailer = {
          id,
          ...action.payload
        };
        trailers.unshift(trailer);
        yield put({
          type: ETrailersActionTypes.GET_TRAILERS_SUCCESS,
          payload: [...trailers]
        });
      }
    } else {
      yield put({ type: ETrailersActionTypes.SAVING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ETrailersActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* deleteTrailer(action) {
  try {
    const response = yield call(
      [axios, axios.delete],
      `/api/trailers/${action.payload}`
    );
    if (response.status === 200) {
      const { trailers } = yield select(state => state.trailers);

      yield put({
        type: ETrailersActionTypes.DELETING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: ETrailersActionTypes.GET_TRAILERS_SUCCESS,
        payload: trailers.filter(x => x.id !== action.payload)
      });
    } else {
      yield put({ type: ETrailersActionTypes.DELETING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ETrailersActionTypes.DELETING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* requestsSaga() {
  yield takeLatest(ETrailersActionTypes.GET_TRAILERS, fetchTrailers);
  yield takeLatest(ETrailersActionTypes.SAVE_TRAILER, saveTrailer);
  yield takeLatest(ETrailersActionTypes.DELETE_TRAILER, deleteTrailer);
}

export default requestsSaga;
