import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { EErrorsActionTypes } from "./actions";
import { EUserActionTypes } from "../user/actions";
import { EDriversActionTypes } from "../drivers/actions";
import { EAccountsActionTypes } from "../accounts/actions";
import { EAgentsActionTypes } from "../agents/actions";
import { EClientsActionTypes } from "../clients/actions";
import { ETrailersActionTypes } from "../trailers/actions";
import { ETrucksActionTypes } from "../trucks/actions";
import { ECarsActionTypes } from "../cars/actions";
import { EBranchesActionTypes } from "../branches/actions";
import { ERequestsActionTypes } from "../requests/actions";

const defaultState = {
  errorMessage: null,
};

const persistConfig = {
  key: "error",
  storage,
  blackList: ["errorMessage"],
};

const errorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case EUserActionTypes.UPDATE_PASSWORD_FAILED:
    case EUserActionTypes.UPDATE_PROFILE_FAILED:
    case EUserActionTypes.CONFIRM_USER_ACCOUNT_FAILED:
    case EDriversActionTypes.GET_DRIVERS_FAILED:
    case EDriversActionTypes.SAVING_FAILED:
    case EDriversActionTypes.DELETING_FAILED:
    case EAccountsActionTypes.GET_ACCOUNTS_FAILED:
    case EAccountsActionTypes.ACTIVATING_FAILED:
    case EAccountsActionTypes.SAVING_FAILED:
    case EAccountsActionTypes.DELETING_FAILED:
    case EAccountsActionTypes.RESETTING_FAILED:
    case EAgentsActionTypes.GET_AGENTS_FAILED:
    case EAgentsActionTypes.ACTIVATING_FAILED:
    case EAgentsActionTypes.SAVING_FAILED:
    case EAgentsActionTypes.DELETING_FAILED:
    case EClientsActionTypes.GET_CLIENTS_FAILED:
    case EClientsActionTypes.SAVING_FAILED:
    case EClientsActionTypes.DELETING_FAILED:
    case ETrailersActionTypes.GET_TRAILERS_FAILED:
    case ETrailersActionTypes.SAVING_FAILED:
    case ETrailersActionTypes.DELETING_FAILED:
    case ETrucksActionTypes.GET_TRUCKS_FAILED:
    case ETrucksActionTypes.SAVING_FAILED:
    case ETrucksActionTypes.DELETING_FAILED:
    case ECarsActionTypes.GET_CARS_FAILED:
    case ECarsActionTypes.SAVING_FAILED:
    case ECarsActionTypes.DELETING_FAILED:
    case EBranchesActionTypes.GET_BRANCHES_FAILED:
    case EBranchesActionTypes.SAVING_FAILED:
    case EBranchesActionTypes.DELETING_FAILED:
    case ERequestsActionTypes.GET_REQUESTS_FAILED:
    case ERequestsActionTypes.ADDING_FAILED:
    case ERequestsActionTypes.CANCEL_REQUEST_FAILED:
    case ERequestsActionTypes.CONFIRMING_FAILED:
    case ERequestsActionTypes.DELETING_FAILED:
    case ERequestsActionTypes.EDIT_CONFIRMING_FAILED:
    case ERequestsActionTypes.EDIT_REQUEST_FAILED:
    case ERequestsActionTypes.ADD_TRANSPORT_REQUEST_FAILED:
    case ERequestsActionTypes.REJECT_REQUEST_FAILED:
    case ERequestsActionTypes.SEARCH_REQUEST_FAILED:
    case ERequestsActionTypes.GET_REQUESTS_HISTORY_FAILED:
    case ERequestsActionTypes.SET_REQUEST_HISTORY_FAILED:
    case ERequestsActionTypes.DELETE_REQUEST_HISTORY_FAILED:
    case ERequestsActionTypes.ARCHIVE_REQUEST_FAILED:
    case ERequestsActionTypes.CHECK_REQUEST_FAILED:
    case ERequestsActionTypes.DELETE_REQUEST_FAILED:
    case ERequestsActionTypes.EDIT_DATE_REQUEST_FAILED:
    case ERequestsActionTypes.SEND_ESCORT_REQUESTS_EXCEL_FAILED:
    case ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_FAILED:
    case ERequestsActionTypes.EXPORT_ESCORTS_FAILED:
    case ERequestsActionTypes.UPDATE_REQUEST_NOTES_FAILED:
    case ERequestsActionTypes.SUBC_REQUEST_CHANGE_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case EErrorsActionTypes.RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default errorReducer;
