import React from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import RequestHistory from "views/Admin/Requests/RequestHistory";
import { Box, Typography } from "@material-ui/core";
import RequestNewHistory from "views/Admin/Requests/RequestNewHistory";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const RequestSummaryDialog = (props) => {
  const classes = useStyles(styles);
  return (
    <Dialog
      fullScreen
      // classes={{
      //   root: classes.center + " " + classes.modalRoot,
      //   paper: classes.modal,
      // }}
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>
          Request Summary - #{props.request.identifier}
        </h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        {!!props.request.archive_note && (
          <Box style={{ marginTop: 20, textAlign: "left" }}>
            <Typography
              variant="h5"
              color="primary"
              style={{ fontWeight: 400, textDecoration: "underline" }}
            >
              Summary Note:
            </Typography>
            <Typography style={{ fontWeight: 400 }}>
              {props.request.archive_note}
            </Typography>
          </Box>
        )}
        {props.request.is_new == 1 ? (
          <RequestNewHistory {...props} />
        ) : (
          <RequestHistory {...props} />
        )}
      </DialogContent>
    </Dialog>
  );
};
export default RequestSummaryDialog;

RequestSummaryDialog.prototype = {
  isModalVisible: PropTypes.bool,
};
