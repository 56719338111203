import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import CustomPlaceInput from "components/CustomPlaceInput/CustomPlaceInput";
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  TextField,
  Typography,
  TextareaAutosize,
  CircularProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  isAdmin,
  isSubContractor,
  REQUEST_STATUS,
  REQUEST_TYPE,
} from "../../../constants";
import moment from "moment";
import { getUserOffice } from "redux/requests/selectors";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const RequestShiftTeam = ({ request, onConfirmRequest, isOffice }) => {
  const { cars } = useSelector((state) => state.cars);
  const isRoleOffice = useSelector(getUserOffice);
  const { agents } = useSelector((state) => state.agents);
  const [securityNotes, setSecurityNotes] = useState(
    request.security_notes || ""
  );
  const [isAttachError, setAttachError] = useState(false);
  const { saving } = useSelector((state) => state.requests);

  const defaultShift = [
    {
      id: Math.random(),
      pup: request.pup,
      dop: request.dop,
      start_date: moment(request.start_date),
      request_team: [
        {
          id: Math.random(),
          agents: [],
          team_client_transport: [],
        },
      ],
    },
  ];
  const prepareDefaultShift = () => {
    if (request.take_over_start_date) {
      return [
        {
          id: Math.random(),
          pup: request.pup,
          dop: request.take_over_dop,
          start_date: moment(request.start_date),
          request_team: [
            {
              id: Math.random(),
              agents: [],
              team_client_transport: [],
            },
          ],
        },
        {
          id: Math.random(),
          pup: request.take_over_pup,
          dop: request.dop,
          start_date: moment(request.take_over_start_date),
          request_team: [
            {
              id: Math.random(),
              agents: [],
              team_client_transport: [],
            },
          ],
        },
      ];
    }
    return defaultShift;
  };
  const prepareExistingShift = (existingShift) => {
    for (let i = 0; i < existingShift.length; i++) {
      existingShift[i].start_date = moment(existingShift[i].start_date);
    }
    return existingShift;
  };
  const requestSift = request.request_shift;
  const [shifts, setShifts] = React.useState(
    requestSift.length > 0
      ? prepareExistingShift([...requestSift])
      : prepareDefaultShift()
  );

  const onDataChanged = (key, value, index) => {
    const shiftCopy = [...shifts];

    shiftCopy[index] = {
      ...shiftCopy[index],
      [key]: value,
    };
    setShifts(shiftCopy);
  };

  const onAddNewShift = () => {
    const shiftCopy = [...shifts];
    shiftCopy.push({
      id: Math.random(),
      pup: "",
      dop: "",
      start_date: "",
      request_team: [
        {
          id: Math.random(),
          agents: [],
          team_client_transport: [],
        },
      ],
    });
    setShifts(shiftCopy);
  };

  const onAddNewTeam = (index) => {
    const shiftCopy = [...shifts];
    const { request_team } = shiftCopy[index];
    request_team.push({
      id: Math.random(),
      agents: [],
      team_client_transport: [],
    });
    shiftCopy[index].request_team = request_team;
    setShifts(shiftCopy);
  };

  const onRemoveTeam = (shiftIndex, team) => {
    const shiftCopy = [...shifts];
    let { request_team } = shiftCopy[shiftIndex];
    //delete request_team[teamIndex];
    shiftCopy[shiftIndex].request_team = request_team.filter(
      (x) => x.id != team.id
    );
    setShifts(shiftCopy);
  };

  const onTeamChanged = (shiftIndex, teamIndex, key, value) => {
    const shiftCopy = [...shifts];
    let { request_team } = shiftCopy[shiftIndex];
    request_team[teamIndex] = {
      ...request_team[teamIndex],
      [key]: value,
    };
    shiftCopy[shiftIndex].request_team = request_team;
    setShifts(shiftCopy);
  };
  const isValidDate = (date) => {
    if (date instanceof moment) {
      return true;
    }
    return false;
  };

  function uniqBy(a, key) {
    return [...new Map(a.map((x) => [key(x), x])).values()];
  }
  const validateRequest = (request, requestShifts) => {
    const client_transport = request.client_transport;
    for (let i = 0; i < requestShifts.length; i++) {
      let attachedTrucks = [];
      const requestShift = requestShifts[i];
      if (!requestShift.start_date || !isValidDate(requestShift.start_date))
        return `Shift ${i + 1} :  the start date is not valid `;
      if (!requestShift.pup) return `Shift ${i + 1} : the PUP is missing`;
      if (!requestShift.dop) return `Shift ${i + 1} : the DOP is missing`;
      for (let j = 0; j < requestShift.request_team.length; j++) {
        const team = requestShift.request_team[j];
        attachedTrucks = attachedTrucks.concat(
          team.team_client_transport.map((x) =>
            x.client_transport ? x.client_transport.id : x.id
          )
        );
        if (!team.security_car)
          return `Shift ${i + 1} - Team ${j +
            1} :  the Security car is missing`;
        if (team.agents.length === 0)
          return `Shift ${i + 1} - Team ${j + 1} : Agent(s) missing`;
        if (team.team_client_transport.length === 0)
          return `Shift ${i + 1} - Team ${j + 1} : attached truck(s) missing`;
      }
      const unique = uniqBy(attachedTrucks, (x) => x);
      if (unique.length < client_transport.length)
        return `Shift ${i + 1} : please attach all the trucks per shift`;
    }

    return true;
  };

  const onDeleteShift = (item) => {
    setShifts(shifts.filter((x) => x.id != item.id));
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {request.type != REQUEST_TYPE.MONITORING && (
          <>
            {isAdmin || (!isOffice && !!request.subcontractor_id) ? (
              <Button
                onClick={onAddNewShift}
                variant="outlined"
                color="primary"
              >
                Add new shift
              </Button>
            ) : null}
            {shifts.map((item, index) => (
              <Card key={item.id} style={{ padding: 10, position: "relative" }}>
                {index > 0 && !isOffice && !!request.subcontractor_id && (
                  <div style={{ position: "absolute", right: 10, top: 10 }}>
                    <Button onClick={() => onDeleteShift(item)} color="danger">
                      Delete Shift
                    </Button>
                  </div>
                )}
                <h4 style={{ fontWeight: "bold" }}>Shift {index + 1}</h4>
                <div style={{ marginTop: 20 }}>
                  <Datetime
                    timeFormat="HH:mm"
                    dateFormat="DD/MM/YYYY"
                    value={item.start_date}
                    onChange={(date) =>
                      onDataChanged("start_date", date, index)
                    }
                    renderInput={(props, openCalendar, closeCalendar) => {
                      return (
                        <CustomInput
                          id="success"
                          labelText="Start Date"
                          disabled={index === 0 || isOffice}
                          // helperText={
                          //   this.state.startDateState === "error" &&
                          //   "start date is not valid"
                          // }
                          value={props.value}
                          // success={this.state.startDateState === "success"}
                          // error={this.state.startDateState === "error"}
                          inputProps={{
                            onChange: props.onChange,
                            onFocus: () => openCalendar(),
                          }}
                        />
                      );
                    }}
                  />
                  <div style={{ marginTop: 20 }}>
                    <CustomPlaceInput
                      id="success"
                      disabled={index === 0 || isOffice}
                      labelText="Pick up point"
                      value={item.pup}
                      onChange={(text) => onDataChanged("pup", text, index)}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <CustomPlaceInput
                      id="success"
                      disabled={isOffice}
                      labelText="Drop off point"
                      value={item.dop}
                      onChange={(text) => onDataChanged("dop", text, index)}
                    />
                  </div>
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    {item.request_team.map((team, teamIndex) => (
                      <GridContainer style={{ marginTop: 10 }} key={teamIndex}>
                        <GridItem xs={3} sm={3} md={3}>
                          <Autocomplete
                            disabled={isOffice}
                            fullWidth
                            onChange={(option, value) =>
                              onTeamChanged(
                                index,
                                teamIndex,
                                "security_car",
                                value
                              )
                            }
                            value={team.security_car || null}
                            id="tags-outlined"
                            options={cars}
                            getOptionDisabled={(option) => {
                              if (option) {
                                return (
                                  item.request_team.filter(
                                    (x) =>
                                      (x.security_car &&
                                        x.security_car.id === option.id) ||
                                      x.id == option.id
                                  ).length > 0
                                );
                              }
                              return "";
                            }}
                            getOptionLabel={(option) => {
                              if (option) {
                                let optionLabel = option.plate_number;
                                if (option.brand)
                                  optionLabel = `${optionLabel} - ${option.brand}`;
                                if (option.color)
                                  optionLabel = `${optionLabel} - ${option.color}`;

                                return optionLabel;
                              }
                            }}
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                variant="outlined"
                                label="select security car"
                                placeholder="Security car"
                              />
                            )}
                          />
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <Autocomplete
                            disabled={isOffice}
                            multiple
                            fullWidth
                            value={
                              team.agents && team.agents.length > 0
                                ? team.agents.map((x) =>
                                    x.agent ? x.agent : x
                                  )
                                : []
                            }
                            id="tags-outlined"
                            options={agents}
                            onChange={(option, value) =>
                              onTeamChanged(index, teamIndex, "agents", value)
                            }
                            getOptionDisabled={(option) => {
                              if (option) {
                                return (
                                  item.request_team.filter(
                                    (x) =>
                                      x.agents.filter(
                                        (y) =>
                                          (y.agent &&
                                            y.agent.id == option.id) ||
                                          y.id == option.id
                                      ).length > 0
                                  ).length > 0
                                );
                              }
                              return "";
                            }}
                            getOptionLabel={(option) => {
                              if (
                                option &&
                                option.first_name &&
                                option.last_name
                              ) {
                                let optionLabel = `${option.first_name} ${option.last_name}`;
                                if (option.phone_number)
                                  optionLabel = `${optionLabel} - ${option.phone_number}`;
                                return optionLabel;
                              }
                              return "";
                            }}
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                variant="outlined"
                                label="Select security agents"
                                placeholder="Security agents"
                              />
                            )}
                          />
                        </GridItem>

                        <GridItem xs={4} sm={4} md={4}>
                          <Autocomplete
                            disabled={isOffice}
                            multiple
                            fullWidth
                            id="tags-outlined"
                            value={
                              team.team_client_transport &&
                              team.team_client_transport.length > 0
                                ? team.team_client_transport.map((x) =>
                                    x.client_transport ? x.client_transport : x
                                  )
                                : []
                            }
                            onChange={(option, value) =>
                              onTeamChanged(
                                index,
                                teamIndex,
                                "team_client_transport",
                                value
                              )
                            }
                            options={request.client_transport}
                            getOptionDisabled={(option) => {
                              if (option) {
                                return (
                                  item.request_team.filter(
                                    (x) =>
                                      x.id == team.id &&
                                      x.team_client_transport.filter(
                                        (y) =>
                                          (y.client_transport &&
                                            y.client_transport.id ==
                                              option.id) ||
                                          y.id == option.id
                                      ).length > 0
                                  ).length > 0
                                );
                              }
                              return "";
                            }}
                            getOptionLabel={(option) => {
                              if (option && option.truck) {
                                option = option.truck;
                                if (option) {
                                  let optionLabel = `${option.plate_number}`;
                                  if (option.brand)
                                    optionLabel = `${optionLabel} - ${option.brand}`;
                                  if (option.color)
                                    optionLabel = `${optionLabel} - ${option.color}`;
                                  return optionLabel;
                                }
                              }
                              return `Truck ${option.id}`;
                            }}
                            // filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                variant="outlined"
                                label="Attach Truck(s)"
                                placeholder="Trucks"
                              />
                            )}
                          />
                        </GridItem>
                        {teamIndex !== 0 && !isOffice && (
                          <GridItem xs={1} sm={1} md={1}>
                            <Button
                              justIcon
                              round
                              simple
                              onClick={() => onRemoveTeam(index, team)}
                              size="lg"
                              color="danger"
                              className="remove"
                            >
                              <DeleteForeverIcon />
                            </Button>{" "}
                          </GridItem>
                        )}
                      </GridContainer>
                    ))}
                  </div>
                  {!isOffice && (
                    <Button
                      onClick={() => onAddNewTeam(index)}
                      variant="outlined"
                      color="primary"
                    >
                      Add new team
                    </Button>
                  )}
                </div>
              </Card>
            ))}
          </>
        )}

        <GridItem style={{ marginTop: 20 }}>
          <Typography style={{ fontSize: 16, float: "left" }} variant="h6">
            Notes (Optional)
          </Typography>
        </GridItem>
        <TextareaAutosize
          style={{
            width: "100%",
            fontSize: 20,
            float: "left",
            padding: 10,
            marginLeft: 20,
            fontFamily: "Helvetica",
            fontWeight: 200,
            marginTop: 20,
          }}
          readOnly={
            request.status === REQUEST_STATUS.ARCHIVED ||
            request.status === REQUEST_STATUS.CHECKED
          }
          disabled={isOffice}
          value={securityNotes}
          rows={5}
          name="note"
          onChange={(event) => setSecurityNotes(event.target.value)}
          placeholder="add notes here..."
        />

        {saving ? (
          <CircularProgress
            size={30}
            color="primary"
            style={{ marginTop: 20 }}
          />
        ) : (
          <>
            {((isAdmin || isSubContractor) && !isRoleOffice) ||
            (isRoleOffice && request.status === REQUEST_STATUS.ACCEPTED) ? (
              <>
                <Button
                  disabled={
                    request.status === REQUEST_STATUS.ARCHIVED ||
                    request.status === REQUEST_STATUS.FINISHED ||
                    request.status === REQUEST_STATUS.CHECKED
                  }
                  onClick={() => {
                    // if (request.status === REQUEST_STATUS.ACCEPTED) {
                    //   alert("In Progress");
                    //   return;
                    // }
                    if (request.type != REQUEST_TYPE.MONITORING) {
                      setAttachError(false);
                      const valid = validateRequest(request, shifts);
                      if (valid === true) {
                        onConfirmRequest(request, [...shifts], securityNotes);
                      } else {
                        setAttachError(valid);
                      }
                    } else {
                      onConfirmRequest(request, null, securityNotes);
                    }
                  }}
                  style={{ marginTop: 20 }}
                  size="lg"
                  color="primary"
                >
                  {request.status === REQUEST_STATUS.ACCEPTED ? "Edit" : ""}{" "}
                  Confirm{" "}
                  {!!request.subcontractor_id ||
                  request.type == REQUEST_TYPE.MONITORING
                    ? "request"
                    : "shift"}
                </Button>
              </>
            ) : null}
          </>
        )}
        <GridItem xs={12} sm={12} md={12}>
          {isAttachError && (
            <div
              style={{
                color: "red",
                fontWeight: "bold",
                marginLeft: 10,
                marginTop: 10,
              }}
              color="error"
            >
              {isAttachError}
            </div>
          )}
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};

export default RequestShiftTeam;
