import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import { InputAdornment, DialogActions, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import XLSX from "xlsx";
import Table from "components/Table/Table";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useSelector, useDispatch } from "react-redux";
import { requestsActions } from "redux/requests/actions";

const useStyles = makeStyles(styles);

const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
const DragDropFile = (props) => {
  function suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  function onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) props.handleFile(files[0]);
  }

  return (
    <GridContainer
      style={{
        border: "4px dashed #607D8B",
        height: 100,
        alignItems: "center",
        justifyContent: "center",
      }}
      onDrop={onDrop}
      onDragEnter={suppress}
      onDragOver={suppress}
    >
      {props.children}
    </GridContainer>
  );
};

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
const DataInput = (props) => {
  const fileInput = React.createRef();

  function handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) props.handleFile(files[0]);
  }
  return (
    <form className="form-inline">
      <input
        ref={fileInput}
        type="file"
        style={{ display: "none" }}
        accept={SheetJSFT}
        onChange={handleChange}
      />
      <span style={{ fontSize: 18 }}>Drag and Drop file or</span>
      <Button
        style={{ marginLeft: 20, marginTop: -3 }}
        color="primary"
        onClick={() => fileInput.current.click()}
      >
        Choose File
      </Button>
    </form>
  );
};

const UploadEscortRequests = (props) => {
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [name, setName] = React.useState();
  const [cols, setCols] = React.useState([]);
  const [valid, setValid] = React.useState(false);
  const { user } = useSelector((state) => state.user);
  const { drivers } = useSelector((state) => state.drivers);
  const { trucks } = useSelector((state) => state.trucks);
  const { trailers } = useSelector((state) => state.trailers);
  const { saving, sendingSuccess } = useSelector((state) => state.requests);

  useEffect(() => {
    if (sendingSuccess) {
      props.onClose();
    }
  }, [sendingSuccess]);
  const onSubmit = () => {
    let pup;
    let requestList = [];
    let request = {};
    for (let i = 1; i < data.length; i++) {
      if (data[i].length > 0) {
        const identifier = data[i][0];
        pup = data[i][1];
        pup = !pup && i > 1 ? request.pup : pup;
        const dop = data[i][2];
        const loading_day = data[i][3];
        const loading_time = data[i][4];
        const depart_time = data[i][5];
        const deliver_day = data[i][6];
        const deliver_time = data[i][7];
        const truck_plate_number = data[i][8] || "";
        const trailer_plate_number = data[i][9] || "";
        const driver_1_name = data[i][10] || "";
        const driver_2_name = data[i][11] || "";

        const truck = trucks.find(
          (x) => x.plate_number === truck_plate_number.trim().toUpperCase()
        );
        const trailer = trailers.find(
          (x) => x.plate_number === trailer_plate_number.trim().toUpperCase()
        );

        const driver1 = drivers.find(
          (x) =>
            x.first_name.toUpperCase() + " " + x.last_name.toUpperCase() ==
            driver_1_name.trim().toUpperCase()
        );
        const driver2 = drivers.find(
          (x) =>
            x.first_name.toUpperCase() + " " + x.last_name.toUpperCase() ==
            driver_2_name.trim().toUpperCase()
        );
        const start_date =
          moment(`${loading_day}`, "dddd D MMMM YYYY").format("YYYY-MM-DD") +
          " " +
          loading_time;

        let clientTransport = {};
        if (truck_plate_number) {
          clientTransport.truck = {
            plate_number: truck ? truck.plate_number : truck_plate_number,
            id: truck ? truck.id : null,
          };
        } else {
          clientTransport.truck = {
            plate_number: "",
          };
        }
        if (driver_1_name) {
          clientTransport.driver1 = {
            first_name: driver1
              ? driver1.first_name
              : driver_1_name.split(" ")[0],
            last_name: driver1
              ? driver1.last_name
              : driver_1_name.replace(driver_1_name.split(" ")[0], "").trim(),
            id: driver1 ? driver1.id : null,
            phone_number: driver1 ? driver1.phone_number : null,
          };
        } else {
          clientTransport.driver1 = {
            first_name: "",
            last_name: "",
          };
        }
        if (trailer_plate_number) {
          clientTransport.trailer = {
            plate_number: trailer ? trailer.plate_number : trailer_plate_number,
            id: trailer ? trailer.id : null,
          };
        }
        if (driver_2_name) {
          clientTransport.driver2 = {
            first_name: driver2
              ? driver2.first_name
              : driver_2_name.split(" ")[0],
            last_name: driver2
              ? driver2.last_name
              : driver_2_name.replace(driver_2_name.split(" ")[0], "").trim(),
            id: driver2 ? driver2.id : null,
            phone_number: driver2 ? driver2.phone_number : null,
          };
        }
        request = {
          identifier,
          branch_id: user.branch_id,
          created_at: moment().format("YYYY-MM-DD H:mm:ss"),
          original_start_date: start_date,
          is_excel: 1,
          client: user.branch.client.name + "-" + user.branch.city,
          type: "Escort",
          start_date,
          depart_time,
          deliver_day,
          deliver_time,
          take_over_start_date: null,
          pup: pup || request.pup,
          status: 0,
          escort_number: 1,
          dop: dop,
          client_transport: clientTransport,
        };
        requestList.push(request);
      }
    }
    dispatch(requestsActions.sendEscortRequestsExcel(requestList));
  };

  const handleFile = (file /*:File*/) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
      /* Update state */
      setName(file.name);
      setData(data);
      setCols(make_cols(ws["!ref"]));
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };
  return (
    <Dialog
      fullScreen
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>Upload Excel Escorts</h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <GridItem>
          <DragDropFile handleFile={handleFile}>
            <GridItem
              style={{ borderWidth: 2, borderColor: "red" }}
              xs={12}
              sm={12}
              md={12}
            >
              <div className="col-xs-12">
                <DataInput handleFile={handleFile} />
                <div style={{ fontWeight: "bold" }}>{name}</div>
              </div>
            </GridItem>
          </DragDropFile>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {valid > 0 && (
            <div style={{ color: "red", marginTop: 20 }}>
              Your file contains {valid} errors, please fix them and import your
              file again
            </div>
          )}
          <div className="col-xs-12">
            {data.length > 0 && (
              <OutTable setValid={setValid} data={data} cols={cols} />
            )}
          </div>
        </GridItem>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary"
          simple
        >
          Close
        </Button>
        {saving ? (
          <CircularProgress
            size={30}
            color="primary"
            style={{ marginRight: 30 }}
          />
        ) : (
          <Button
            disabled={valid > 0}
            onClick={onSubmit}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const OutTable = ({ data, setValid }) => {
  const analyseData = (input) => {
    const rows = [];
    input = input.filter((x) => x.length > 0);
    let errorCount = 0;
    for (let i = 0; i < input.length; i++) {
      let columns = [];
      for (let j = 0; j < input[i].length; j++) {
        let valid = true;
        let value = input[i][j] || "";
        // pup

        if (j < 5 && j > 0) {
          valid = input[i][j] || (j == 1 && i > 0 && !input[i][j]);
          value = valid ? value : "(Missing)";
        } else if (j === 10 || j === 11) {
          valid = (value && value.split(" ").length > 1) || !value;
          value = valid ? value : value + " (last name missed)";
        }
        if (!valid) {
          errorCount++;
          columns.push(<Grid style={{ color: !valid && "red" }}>{value}</Grid>);
        } else {
          if (j === 3) {
            valid = moment(value, "dddd D MMMM YYYY").isValid();
            value = valid ? value : value + " (Invalid)";
            errorCount = valid ? errorCount : errorCount + 1;
          } else if (j === 4) {
            valid = moment("2020-08-20 " + value).isValid();
            value = valid ? value : value + " (Invalid)";
            errorCount = valid ? errorCount : errorCount + 1;
          } else if (j === 5) {
            valid = !value ? true : moment("2020-08-20 " + value).isValid();
            value = valid ? value : value + " (Invalid)";
            errorCount = valid ? errorCount : errorCount + 1;
          } else if (j === 6) {
            valid = !value ? true : moment(value, "dddd D MMMM YYYY").isValid();
            value = valid ? value : value + " (Invalid)";
            errorCount = valid ? errorCount : errorCount + 1;
          }
          columns.push(<Grid style={{ color: !valid && "red" }}>{value}</Grid>);
        }
      }
      rows.push(columns);
    }
    setValid(errorCount);
    return rows;
  };
  return <Table tableHead={data[0]} tableData={analyseData(data.slice(1))} />;
};
export default UploadEscortRequests;

UploadEscortRequests.prototype = {
  isModalVisible: PropTypes.bool,
};
