import React, { useEffect, useRef } from "react";
import Pusher from "pusher-js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { chatActions } from "redux/chat/actions";
import { audioRef } from "index";

// This is a custom hook to reference the previous state
// of a variable (in this file, used to check previous state
// of `authToken`)
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
let isRegisteredPusher = false;
let isRegisteredPusherBeams = false;
// let audio = new Audio("../assets/mp3/new_message.mp3");
const PusherController = () => {
  let chatChannel = null;
  let pusher = null;
  let userId = null;
  const dispatch = useDispatch();
  const { user, isRehydrated, auth, isUserLoggedIn } = useSelector(
    (state) => state.user
  );
  const chat = useSelector((state) => state.chat);

  const prevAuthToken = usePrevious(auth.access_token);

  useEffect(() => {
    if (pusher) console.log("pusher channels ", pusher.channels);

    if (auth.access_token && user && user.id && !isRegisteredPusherBeams) {
      console.log("init push notification");
      isRegisteredPusherBeams = true;
    } else {
      if (!isUserLoggedIn) {
        isRegisteredPusherBeams = false;
        isRegisteredPusher = false;
      }
    }

    if (
      !auth.access_token &&
      prevAuthToken &&
      auth.access_token !== prevAuthToken
    ) {
      isRegisteredPusher = false;
      isRegisteredPusherBeams = false;
      console.log("clear all state");
      if (chatChannel) {
        chatChannel.unbind_all();
      }
      if (pusher) {
        pusher.unsubscribe(`private-App.User.${user.id}`);
        // chatChannel.trigger("client-app-state", {
        //   user_id: user.id,
        //   state: "inactive",
        // });
      }
    }
  }, [auth, user]);

  useEffect(() => {
    if (!isRegisteredPusher && user && user.id && auth && auth.access_token) {
      console.log("subscribe");
      console.log("isRegisteredPusher : ", isRegisteredPusher);
      subscribeChannels();
      isRegisteredPusher = true;
    } else {
      if (isRehydrated && !isUserLoggedIn) {
        isRegisteredPusher = false;
        console.log("unsubscbire");
      }
    }
  }, [user]);

  const subscribeChannels = async () => {
    // console.log('messages form pusher : ', messages);

    const token = auth.access_token;
    console.log("token : ", token);
    const config = {
      appId: "1108644",
      enabledTransports: ["ws"],
      forceTLS: true,
      key: "a956ad1eb914c6a7897c",
      secret: "0bcb78b5eaaf0e7cb935",
      cluster: "eu",
      encrypted: true,
      authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
      auth: {
        params: { id_token: token },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    };
    pusher = new Pusher(config.key, config);
    var state = pusher.connection.state;
    // console.log('state : ', pusher);
    Pusher.logToConsole = false;
    chatChannel = pusher.subscribe(`private-App.User.${user.id}`);

    // const self = this;
    chatChannel.bind("pusher:subscription_succeeded", () => {
      console.log("subscription_succeeded");
      // chatChannel.trigger("client-app-state", {
      //   user_id: user.id,
      //   state: "active",
      // });
      chatChannel.bind("new-message", (data) => {
        console.log("new-message : ", data);
        // if (chatChannel)
        //   chatChannel.trigger('client-message-received', {
        //     message_id: data.message.id,
        //   });
        dispatch(chatActions.getNewMessage(data.message));
      });
      chatChannel.bind("channel-created", (data) => {
        console.log("new channel : ", data);
        dispatch(chatActions.getNewChannel({ data: data.message }));
      });
      chatChannel.bind("channel-updated", (data) => {
        console.log("channel updated : ", data);
        dispatch(chatActions.getUpdatedChannel({ data: data.message }));
      });
    });
    // chatChannel.bind('pusher:member_added', function (member) {
    //   // for example:
    //   console.log('member_added : ', member);
    //   //add_member(member.id, member.info);
    // });
    // chatChannel.bind('pusher:member_removed', function (member) {
    //   // for example:
    //   console.log('member_removed : ', member);
    //   //add_member(member.id, member.info);
    // });
    chatChannel.bind("pusher:subscription_error", (status) => {
      // console.log('error outside if ', status);

      // if (status === 408 || status === 503) {
      // retry?
      console.log("error connecting t pusher : ", status);
      // }
    });
    chatChannel.bind("pusher:state_change", (status) => {
      // console.log('error outside if ', status);

      // chatChannel.trigger("client-app-state", {
      //   user_id: user.id,
      //   state: "inactive",
      // });
      // if (status === 408 || status === 503) {
      // retry?
      console.log("state change : ", status);
      // }
    });
  };

  return null;
};

export default PusherController;
