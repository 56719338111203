import React, { useState } from "react";
import moment from "moment";
// @material-ui/core components
import { Grid, Divider, Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { getUserOffice } from "redux/requests/selectors";
import Table from "components/Table/Table";
// @material-ui/icons
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Primary from "components/Typography/Primary";
import {
  REQUEST_TYPE,
  REQUEST_STATUS,
  primaryColor,
  isAdmin,
  isSubContractor,
} from "../../../../src/constants";
import Typography from "@material-ui/core/Typography";
import RequestPlaceItem from "./RequestPlaceItem";
import RequestDateItem from "./RequestDateItem";
import Checkbox from "@material-ui/core/Checkbox";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import NewRequest from "../new.json";
import Lottie from "react-lottie";
import SubcontractorListDialog from "./SubcontractorListDialog";
import { useSelector } from "react-redux";

const RequestItemPending = (props) => {
  const request = props.request;

  return (
    <>
      <RequestDateItem
        fromDate={request.start_date}
        toDate={request.end_date}
        original_start_date={request.original_start_date}
      />
      {request.take_over_pup ? (
        <>
          <RequestPlaceItem pup={request.pup} dop={request.take_over_dop} />
          <label
            style={{
              color: primaryColor,
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            Take Over
          </label>
          <RequestDateItem
            original_start_date={request.take_over_original_start_date}
            fromDate={request.take_over_start_date}
          />

          <RequestPlaceItem pup={request.take_over_pup} dop={request.dop} />
        </>
      ) : (
        <RequestPlaceItem pup={request.pup} dop={request.dop} />
      )}
      <Grid style={{ marginTop: 0 }}>
        <Box display="flex">
          <label style={{ marginTop: 10, color: "#000" }}>
            <LocalShippingIcon />
          </label>
          <label
            style={{
              fontWeight: "bold",
              color: "#333",
              marginTop: 3,
            }}
          >
            Trucks :
            <label style={{ color: "#333" }}>
              {props.request.client_transport.map((item, index) => (
                <label
                  style={{
                    marginTop: 5,
                    display: "inline-block",
                    marginLeft: 10,
                    borderRadius: 5,
                    padding: 5,
                  }}
                  key={item.id}
                >
                  <label
                    style={{
                      color: primaryColor,
                      fontWeight: "bold",
                    }}
                  >
                    Truck {index + 1} :
                  </label>
                  {item.truck ? (
                    <label
                      style={{
                        color: "#333",
                        marginLeft: 10,
                        fontWeight: "bold",
                      }}
                    >
                      {item.truck.plate_number}{" "}
                      {item.trailer &&
                        "( Trailer: " + item.trailer.plate_number + ")"}
                    </label>
                  ) : (
                    <label style={{ color: "red" }}>
                      {" missing information"}
                    </label>
                  )}

                  <label
                    style={{
                      color: "#333",
                      marginLeft: 20,
                      fontWeight: "bold",
                    }}
                  >
                    Driver 1 :
                    {item.driver1 ? (
                      <label
                        style={{
                          color: "#333",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {item.driver1.first_name} {item.driver1.last_name}{" "}
                        {item.driver1.phone_number}
                      </label>
                    ) : (
                      <label style={{ color: "red" }}>
                        {" missing information"}
                      </label>
                    )}
                  </label>
                  {item.driver2 && (
                    <label
                      style={{
                        color: "#333",
                        marginLeft: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Driver 2 : {item.driver2.first_name}{" "}
                      {item.driver2.last_name} {item.driver2.phone_number}
                    </label>
                  )}
                </label>
              ))}
            </label>
          </label>
        </Box>
        {!!props.request.escort_number && props.request.escort_number != 1 && (
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <label
              style={{
                color: "#fff",
                padding: 5,
                borderRadius: 10,
                backgroundColor: "red",
              }}
            >
              Teams : {props.request.escort_number}
            </label>
          </div>
        )}
      </Grid>
    </>
  );
};

export default RequestItemPending;
