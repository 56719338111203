export const EBranchesActionTypes = {
  GET_BRANCHES: "GET_BRANCHES",
  GET_BRANCHES_SUCCESS: "GET_BRANCHES_SUCCESS",
  GET_BRANCHES_FAILED: "GET_BRANCHES_FAILED",
  SAVE_BRANCH: "SAVE_BRANCH",
  DELETE_BRANCH: "DELETE_BRANCH",
  SAVING_SUCCESS: "SAVING_BRANCH_SUCCESS",
  SAVING_FAILED: "SAVING_BRANCH_FAILED",
  DELETING_SUCCESS: "DELETING_BRANCH_SUCCESS",
  DELETING_FAILED: "DELETING_BRANCH_FAILED",
  RESET_SUCCESS_DELETE: "RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE"
};

export const branchesActions = {
  fetchBranches: payload => ({
    type: EBranchesActionTypes.GET_BRANCHES,
    payload
  }),
  saveBranch: payload => ({
    type: EBranchesActionTypes.SAVE_BRANCH,
    payload
  }),
  deleteBranch: payload => ({
    type: EBranchesActionTypes.DELETE_BRANCH,
    payload
  }),
  resetSuccessDelete: () => ({
    type: EBranchesActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: EBranchesActionTypes.RESET_SUCCESS_SAVE
  })
};
