export const EErrorsActionTypes = {
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  RESET_ERROR_MESSAGE: "RESET_ERROR_MESSAGE"
};

export const errorsActions = {
  setErrorMessage: payload => ({
    type: EErrorsActionTypes.SET_ERROR_MESSAGE,
    payload
  }),
  resetErrorMessage: () => ({
    type: EErrorsActionTypes.RESET_ERROR_MESSAGE
  })
};
