import React from "react";
import GridItem from "components/Grid/GridItem";
import {
  TextareaAutosize,
  Typography,
  CircularProgress
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useSelector } from "react-redux";
import { REQUEST_STATUS } from "../../../constants";
import Card from "components/Card/Card";
const RequestArchive = props => {
  const [note, setNote] = React.useState("");
  const { archiving } = useSelector(state => state.requests);
  const onClick = () => {
    if (
      props.request.status == REQUEST_STATUS.CHECKED ||
      props.request.status == REQUEST_STATUS.CANCELED
    )
      props.onArchiveRequest({ id: props.request.id });
    else {
      props.onCheckRequest({ id: props.request.id, note });
    }
  };
  return (
    <Card
      style={{
        minHeight: 300,
        padding: 40,
        justifyContent: "center",
        alignItens: "center"
      }}
    >
      {props.request.status == REQUEST_STATUS.FINISHED ||
      props.request.status == REQUEST_STATUS.CANCELED ? (
        <>
          <GridItem xs={12} sm={12}>
            <TextareaAutosize
              style={{
                width: "100%",
                fontSize: 20,
                padding: 10,
                fontFamily: "Helvetica",
                fontWeight: 200
              }}
              value={note}
              rows={10}
              name="note"
              onChange={e => setNote(e.target.value)}
              placeholder="add notes here..."
            />
          </GridItem>
          <GridItem xs={12} sm={12}>
            {archiving ? (
              <CircularProgress
                size={30}
                color="primary"
                style={{ marginRight: 30 }}
              />
            ) : (
              <Button onClick={onClick} variant="contained" color="primary">
                {props.request.status == REQUEST_STATUS.ARCHIVED ||
                props.request.status == REQUEST_STATUS.CANCELED
                  ? "Archive"
                  : "Send to Client"}
              </Button>
            )}
          </GridItem>
        </>
      ) : (
        <GridItem style={{ marginTop: 20 }}>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            {props.request.status == REQUEST_STATUS.CHECKED
              ? "The request history has been sent to the client!"
              : "the request is archived"}
          </Typography>
          <Typography color="primary">
            Note: {props.request.archive_note || "N/A"}
          </Typography>
          {props.request.status == REQUEST_STATUS.CHECKED && (
            <>
              {archiving ? (
                <CircularProgress
                  size={30}
                  color="primary"
                  style={{ marginRight: 30 }}
                />
              ) : (
                <Button onClick={onClick} variant="contained" color="primary">
                  Archive
                </Button>
              )}
            </>
          )}
        </GridItem>
      )}
    </Card>
  );
};

export default RequestArchive;
