export const ETrailersActionTypes = {
  GET_TRAILERS: "GET_TRAILERS",
  GET_TRAILERS_SUCCESS: "GET_TRAILERS_SUCCESS",
  GET_TRAILERS_FAILED: "GET_TRAILERS_FAILED",
  SAVE_TRAILER: "SAVE_TRAILER",
  DELETE_TRAILER: "DELETE_TRAILER",
  SAVING_SUCCESS: "SAVING_TRAILER_SUCCESS",
  SAVING_FAILED: "SAVING_TRAILER_FAILED",
  DELETING_SUCCESS: "DELETING_TRAILER_SUCCESS",
  DELETING_FAILED: "DELETING_TRAILER_FAILED",
  RESET_SUCCESS_DELETE: "RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE"
};

export const trailersActions = {
  saveTrailer: payload => ({
    type: ETrailersActionTypes.SAVE_TRAILER,
    payload
  }),
  deleteTrailer: payload => ({
    type: ETrailersActionTypes.DELETE_TRAILER,
    payload
  }),
  fetchTrailer: () => ({
    type: ETrailersActionTypes.GET_TRAILERS
  }),
  resetSuccessDelete: () => ({
    type: ETrailersActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: ETrailersActionTypes.RESET_SUCCESS_SAVE
  })
};
