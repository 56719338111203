import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { EAccountsActionTypes } from "./actions";

const defaultState = {
  accounts: [],
  saving: false,
  savingError: false,
  savingSuccess: false,
  deleteSuccess: false,
  deleteFailed: false,
  activateSuccess: false,
  activateFailed: false,
  resetSuccess: false,
  resetFailed: false
};

const persistConfig = {
  key: "subcaccounts",
  storage,
  whitelist: []
};

const subcAccountsReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case EAccountsActionTypes.GET_ACCOUNTS_SUCCESS:
        return {
          ...state,
          accounts: action.payload
        };
      case EAccountsActionTypes.SAVE_ACCOUNT:
        return {
          ...state,
          saving: true
        };
      case EAccountsActionTypes.SAVING_FAILED:
        return {
          ...state,
          saving: false,
          savingError: Math.random(),
          savingSuccess: false
        };
      case EAccountsActionTypes.SAVING_SUCCESS:
        return {
          ...state,
          saving: false,
          savingError: false,
          savingSuccess: Math.random()
        };
      case EAccountsActionTypes.DELETING_SUCCESS:
        return {
          ...state,
          deleteSuccess: Math.random()
        };
      case EAccountsActionTypes.RESET_SUCCESS_DELETE:
        return {
          ...state,
          deleteSuccess: false
        };
      case EAccountsActionTypes.RESET_SUCCESS_SAVE:
        return {
          ...state,
          savingSuccess: false
        };
      case EAccountsActionTypes.ACTIVATING_SUCCESS:
        return {
          ...state,
          activateSuccess: Math.random()
        };
      case EAccountsActionTypes.RESET_SUCCESS_ACTIVATE:
        return {
          ...state,
          activateSuccess: false
        };
      case EAccountsActionTypes.RESETTING_SUCCESS:
        return {
          ...state,
          resetSuccess: Math.random()
        };
      case EAccountsActionTypes.RESET_SUCCESS_RESET:
        return {
          ...state,
          resetSuccess: false
        };
      default:
        return state;
    }
  }
);

export default subcAccountsReducer;
