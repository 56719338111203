import { takeLatest, call, put, select } from "redux-saga/effects";
import { EClientsActionTypes } from "./actions";
import axios from "../axios";

function* fetchClients() {
  const { user } = yield select(state => state.user);
  try {
    const response = yield call([axios, axios.get], `/api/clients`);
    if (response && response.data && response.data.success) {
      yield put({
        type: EClientsActionTypes.GET_CLIENTS_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: EClientsActionTypes.GET_CLIENTS_FAILED });
    }
  } catch (e) {
    yield put({
      type: EClientsActionTypes.GET_CLIENTS_FAILED,
      payload: e.response.statusText
    });
  }
}

function* saveClientWithLogo(action) {
  try {
    const client = action.payload;
    const formData = new FormData();
    formData.append("image", client.file);
    formData.append("folder", "clients");
    const config = {
      headers: { "content-type": "multipart/form-data" }
    };
    const response = yield call(
      [axios, axios.post],
      `/api/imageUpload`,
      formData,
      config
    );
    if (response && response.data && response.data.success) {
      yield put({
        type: EClientsActionTypes.SAVE_CLIENT,
        payload: {
          ...client,
          logo: response.data.success
        }
      });
    } else {
      yield put({ type: EClientsActionTypes.SAVING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EClientsActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* saveClient(action) {
  const { clients } = yield select(state => state.clients);
  let response;
  const request = action.payload;

  try {
    if (action.payload.id) {
      response = yield call(
        [axios, axios.put],
        `/api/clients/${action.payload.id}`,
        request
      );
    } else {
      response = yield call([axios, axios.post], `/api/clients`, request);
    }

    if (response && response.status === 200) {
      yield put({
        type: EClientsActionTypes.SAVING_SUCCESS,
        payload: response.data.success
      });
      if (action.payload.id) {
        const clientIndex = clients.findIndex(x => x.id === action.payload.id);
        clients[clientIndex] = action.payload;
        yield put({
          type: EClientsActionTypes.GET_CLIENTS_SUCCESS,
          payload: [...clients]
        });
      } else {
        const id = response.data.success.id;
        const client = {
          id,
          ...action.payload
        };
        clients.unshift(client);
        yield put({
          type: EClientsActionTypes.GET_CLIENTS_SUCCESS,
          payload: [...clients]
        });
      }
    } else {
      yield put({ type: EClientsActionTypes.SAVING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EClientsActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* deleteClient(action) {
  try {
    const response = yield call(
      [axios, axios.delete],
      `/api/clients/${action.payload}`
    );
    if (response.status === 200) {
      const { clients } = yield select(state => state.clients);

      yield put({
        type: EClientsActionTypes.DELETING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: EClientsActionTypes.GET_CLIENTS_SUCCESS,
        payload: clients.filter(x => x.id !== action.payload)
      });
    } else {
      yield put({ type: EClientsActionTypes.DELETING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EClientsActionTypes.DELETING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* requestsSaga() {
  yield takeLatest(EClientsActionTypes.GET_CLIENTS, fetchClients);
  yield takeLatest(EClientsActionTypes.SAVE_CLIENT, saveClient);
  yield takeLatest(
    EClientsActionTypes.SAVE_CLIENT_WITH_LOGO,
    saveClientWithLogo
  );
  yield takeLatest(EClientsActionTypes.DELETE_CLIENT, deleteClient);
}

export default requestsSaga;
