import axios from "axios";
import { generateOauth } from "./user/oauth";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const setAuthToken = (token) => {
  axiosWithTokenInterceptors.defaults.headers.common["Authorization"] = "";
  delete axiosWithTokenInterceptors.defaults.headers.common["Authorization"];

  if (token) {
    axiosWithTokenInterceptors.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  }
};

const createAxiosInstance = (baseURL) =>
  axios.create({
    baseURL,
    responseType: "json",
    withCredentials: true,
  });

export const refreshTokenInterceptor = async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest.skipIntercept) {
    const request = generateOauth("refresh_token");
    const response = await axiosWithTokenInterceptors.post(
      "/api/oauth/token",
      request,
      {
        skipIntercept: true,
      }
    );
    if (response && response.data && !response.data.error) {
      const accessToken = response.data.access_token;
      setAuthToken(accessToken);
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      originalRequest.baseURL = undefined;
      return axiosWithTokenInterceptors.request(originalRequest);
    }
  }
  return Promise.reject(error);
};

export const withTokenInterceptors = (client) => {
  client.interceptors.response.use((res) => res, refreshTokenInterceptor);
  return client;
};

const axiosWithTokenInterceptors = withTokenInterceptors(
  createAxiosInstance(BASE_URL)
);

export const axiosWithoutTokenInterceptors = createAxiosInstance(BASE_URL);

export default axiosWithTokenInterceptors;
