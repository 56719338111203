import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { EClientsActionTypes } from "./actions";

const defaultState = {
  clients: [],
  saving: false,
  savingError: false,
  savingSuccess: false,
  deleteSuccess: false,
  deleteFailed: false
};

const persistConfig = {
  key: "clients",
  storage,
  whitelist: ["clients"]
};

const clientsReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case EClientsActionTypes.GET_CLIENTS_SUCCESS:
        return {
          ...state,
          clients: action.payload
        };
      case EClientsActionTypes.SAVE_CLIENT:
        return {
          ...state,
          saving: true
        };
      case EClientsActionTypes.SAVE_CLIENT_WITH_LOGO:
        return {
          ...state,
          saving: true
        };
      case EClientsActionTypes.SAVING_FAILED:
        return {
          ...state,
          saving: false,
          savingError: Math.random(),
          savingSuccess: false
        };
      case EClientsActionTypes.SAVING_SUCCESS:
        return {
          ...state,
          saving: false,
          savingError: false,
          savingSuccess: Math.random()
        };
      case EClientsActionTypes.DELETING_SUCCESS:
        return {
          ...state,
          deleteSuccess: Math.random()
        };
      case EClientsActionTypes.RESET_SUCCESS_DELETE:
        return {
          ...state,
          deleteSuccess: false
        };
      case EClientsActionTypes.RESET_SUCCESS_SAVE:
        return {
          ...state,
          savingSuccess: false
        };
      default:
        return state;
    }
  }
);

export default clientsReducer;
