import React from "react";
// @material-ui/core components
import { Box, Grid } from "@material-ui/core";
import Table from "components/Table/Table";
// @material-ui/icons

// core components
import GridItem from "components/Grid/GridItem";
import {
  REQUEST_TYPE,
  REQUEST_STATUS,
  primaryColorRGBA,
  primaryColor,
} from "../../../constants";
import Typography from "@material-ui/core/Typography";
import GridContainer from "components/Grid/GridContainer";

const ResumedSecurityDetails = ({ request }) => {
  const status = request.status;

  const generateAgents = (agents) => {
    let rows = [];
    for (let i = 0; i < agents.length; i++) {
      let columns = [];
      let agent = agents[i].agent;
      columns.push(
        <Grid>
          {agent.first_name} {agent.last_name}
        </Grid>
      );
      columns.push(
        <Grid>{agent.phone_number ? agent.phone_number : "N/A"}</Grid>
      );
      rows.push(columns);
    }
    return rows;
  };
  const generateSecurityCars = (cars) => {
    let rows = [];
    for (let i = 0; i < cars.length; i++) {
      let columns = [];
      let car = cars[i].security_car;
      columns.push(<Grid>{car.plate_number}</Grid>);
      columns.push(<Grid>{car.brand ? car.brand : "N/A"}</Grid>);
      columns.push(<Grid>{car.color ? car.color : "N/A"}</Grid>);
      rows.push(columns);
    }
    return rows;
  };
  const generateEscortSecurityCars = (shifts) => {
    let rows = [];
    // if (shifts.length == 1) {
    //   const newshoft = shifts[0];
    //   newshoft.id = 200;
    //   shifts.push(newshoft);
    //   shifts.push(newshoft);
    // }
    for (let i = 0; i < shifts.length; i++) {
      for (let j = 0; j < shifts[i].request_team.length; j++) {
        let columns = {};
        const team = shifts[i].request_team[j];
        const car = team.security_car;
        const agents = team.agents;
        const attachedTruck = team.team_client_transport.map(
          (x) => x.client_transport
        );

        columns.car = car
          ? `${car.plate_number} ${car.brand ? " - " + car.brand : ""} ${
              car.color ? " - " + car.color : ""
            }`
          : "N/A";

        columns.agents = agents
          .map(
            (item) =>
              item.agent.first_name +
              " " +
              item.agent.last_name +
              " (" +
              item.agent.phone_number +
              ")"
          )
          .join(" / ");

        columns.drivers = attachedTruck
          .map(
            (item, index) =>
              `${
                item.driver1
                  ? item.driver1.first_name +
                    " " +
                    item.driver1.last_name +
                    " " +
                    `${
                      item.driver1.phone_number ? item.driver1.phone_number : ""
                    }`
                  : "N/A"
              }` +
              " " +
              `${
                item.driver2
                  ? " / " +
                    item.driver2.first_name +
                    " " +
                    item.driver2.last_name +
                    " " +
                    `${
                      item.driver2.phone_number ? item.driver2.phone_number : ""
                    }`
                  : ""
              }`
          )
          .join(" / ");
        columns.trucks = attachedTruck
          .map((item, index) =>
            item.truck
              ? item.truck.plate_number +
                " " +
                `${item.truck.brand ? +" - " + item.truck.brand : ""}` +
                " " +
                `${item.truck.color ? " - " + item.truck.color : ""}`
              : "Truck " + (index + 1) + ": missing information"
          )
          .join(" / ");

        rows.push(columns);
      }
    }
    return rows;
  };

  const rows = request.request_shift
    ? generateEscortSecurityCars(request.request_shift)
    : [];
  return (
    <>
      {(status == REQUEST_STATUS.ACCEPTED ||
        status == REQUEST_STATUS.FINISHED ||
        status == REQUEST_STATUS.CHECKED ||
        status == REQUEST_STATUS.PENDING ||
        status == REQUEST_STATUS.ARCHIVED) && (
        <>
          {request.type == REQUEST_TYPE.ESCORT ||
          request.type == REQUEST_TYPE.INTERVENTION ? (
            <>
              {request.request_shift &&
                request.request_shift.length > 0 &&
                rows.map((item, index) => (
                  <GridItem xs={4} sm={4} md={4} key={index}>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: primaryColor,
                        marginLeft: 10,
                      }}
                    >
                      Shift {index + 1} :{" "}
                    </div>
                    <div
                      style={{
                        border: "1px solid" + primaryColor,
                        marginLeft: 10,
                        marginRight: 10,
                        padding: 10,
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: "bold" }}>Trucks : </span>
                        {item.trucks}
                      </div>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Drivers : </span>
                        {item.drivers}
                      </div>
                      {item.car && (
                        <div>
                          <span style={{ fontWeight: "bold" }}>Cars : </span>{" "}
                          {item.car}
                        </div>
                      )}
                      {item.agents && item.agents.length > 0 && (
                        <div>
                          {" "}
                          <span style={{ fontWeight: "bold" }}>Agents : </span>
                          {item.agents}
                        </div>
                      )}
                    </div>
                  </GridItem>
                ))}
            </>
          ) : (
            <>
              {request.agent_transport && request.agent_transport.length > 0 && (
                <>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      fontWeight: 400,
                      textDecoration: "underline",
                    }}
                  >
                    Agents
                  </Typography>
                  <Table
                    tableHead={["Full Name", "Phone Number"]}
                    tableData={generateAgents(request.agent_transport)}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default ResumedSecurityDetails;
