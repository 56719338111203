import DashboardClient from "views/Admin/Dashboard.js";
import LoginPage from "views/Pages/LoginPage.js";
import RequestTracking from "views/Admin/Tracking/RequestTracking";
import CarsManagement from "views/Admin/CarsManagement";
import RoomIcon from "@material-ui/icons/Room";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";

const officeRoutes = [
  {
    path: "/dashboard",
    name: "Requests",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: DashboardClient,
    layout: "/admin",
  },
  {
    path: "/tracking",
    name: "Tracking",
    rtlName: "لوحة القيادة",
    icon: RoomIcon,
    component: RequestTracking,
    layout: "/admin",
  },
  {
    path: "/cars-management",
    name: "Cars",
    rtlName: "لوحة القيادة",
    icon: DirectionsCarIcon,
    component: CarsManagement,
    layout: "/admin",
  },
  {
    path: "/login-page",
    name: "Log out",
    rtlName: "لوحة القيادة",
    icon: MeetingRoomIcon,
    component: LoginPage,
    layout: "/auth",
  },
];
export default officeRoutes;
