import { primaryColor } from "../../../constants";
import React from "react";
import { useSelector } from "react-redux";
import { ListItem, Typography } from "@material-ui/core";

export const getRole = (member, user) => {
  if (user.id == member.id) return "You";
  if (member.roles[0] == "ROLE_ADMIN") return "Protectrans";
  if (member.roles[0] == "ROLE_SUBCONTRACTOR") return "Sub C";
  if (member.agent_id) return "Agent";
};

const ChannelMembers = () => {
  const { user } = useSelector((state) => state.user);
  const { currentChannel } = useSelector((state) => state.chat);
  const members = [...new Set(currentChannel.members.map((x) => x.user))];

  return (
    <div className="shift-frame" style={{ marginTop: 20 }}>
      {members.map((item) => (
        <ListItem key={item.id} style={{ justifyContent: "space-between" }}>
          <div>
            <Typography style={{ fontSize: 16, color: "#333" }}>
              {item.first_name} {item.last_name}
            </Typography>
            <Typography style={{ fontSize: 14, color: primaryColor, top: 5 }}>
              {item.subcontractor ? item.subcontractor.company_name : ""}
            </Typography>
          </div>
          <div>
            <Typography
              style={{ color: user.id == item.id ? primaryColor : "grey" }}
            >
              {getRole(item, user)}
            </Typography>
          </div>
        </ListItem>
      ))}
    </div>
  );
};

export default ChannelMembers;
