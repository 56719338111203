export const EAgentsActionTypes = {
  GET_AGENTS: "GET_AGENTS",
  GET_AGENTS_SUCCESS: "GET_AGENTS_SUCCESS",
  GET_AGENTS_FAILED: "GET_AGENTS_FAILED",
  SAVE_AGENT: "SAVE_AGENT",
  DELETE_AGENT: "DELETE_AGENT",
  SAVING_SUCCESS: "SAVING_AGENT_SUCCESS",
  SAVING_FAILED: "SAVING_AGENT_FAILED",
  DELETING_SUCCESS: "DELETING_AGENT_SUCCESS",
  DELETING_FAILED: "DELETING_AGENT_FAILED",
  ACTIVATE_AGENT: "ACTIVATE_AGENT",
  ACTIVATING_SUCCESS: "ACTIVATING_AGENT_SUCCESS",
  ACTIVATING_FAILED: "ACTIVATING_AGENT_FAILED",
  RESET_SUCCESS_ACTIVATE: "RESET_SUCCESS_ACTIVATE",
  RESET_SUCCESS_DELETE: "RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE",
  RESET_SUCCESS_RESET: "RESET_SUCCESS_RESET_AGENT",
  RESET_ACCOUNT: "RESET_ACCOUNT_AGENT",
  RESETTING_SUCCESS: "RESETTING_ACCOUNT_AGENT_SUCCESS",
  RESETTING_FAILED: "RESETTING_ACCOUNT_AGENT_FAILEd"
};

export const agentsActions = {
  saveAgent: payload => ({
    type: EAgentsActionTypes.SAVE_AGENT,
    payload
  }),
  deleteAgent: payload => ({
    type: EAgentsActionTypes.DELETE_AGENT,
    payload
  }),
  activateAgent: payload => ({
    type: EAgentsActionTypes.ACTIVATE_AGENT,
    payload
  }),
  resetActivateDelete: () => ({
    type: EAgentsActionTypes.RESET_SUCCESS_ACTIVATE
  }),
  fetchAgents: () => ({
    type: EAgentsActionTypes.GET_AGENTS
  }),
  resetSuccessDelete: () => ({
    type: EAgentsActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: EAgentsActionTypes.RESET_SUCCESS_SAVE
  }),
  resetAccount: payload => ({
    type: EAgentsActionTypes.RESET_ACCOUNT,
    payload
  }),
  resetSuccessReset: () => ({
    type: EAgentsActionTypes.RESET_SUCCESS_RESET
  })
};
