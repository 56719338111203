import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { createRootReducer } from "./index";
import { createHashHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import loadingMiddleware from "./loadingMiddleware";
import logger from "redux-logger";
import sagas from "./sagas";

export const history = createHashHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
let middlewares;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middlewares = applyMiddleware(
    routerMiddleware(history),
    loadingMiddleware,
    sagaMiddleware,
    logger
  );
} else {
  middlewares = applyMiddleware(
    routerMiddleware(history),
    loadingMiddleware,
    sagaMiddleware
  );
}

const store = createStore(
  createRootReducer(history),
  /* preloadedState, */
  composeEnhancers(middlewares)
);
export const persistor = persistStore(store);
sagaMiddleware.run(sagas);

export default store;
