import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { branchesActions } from "../../redux/branches/actions";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import NewBranchDialog from "./NewBranchDialog";
import { history } from "../../redux/store";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  input: {
    paddingTop: 0,
  },
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles,
});

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
    : true;
}

export const BranchManagement = ({ match }) => {
  const dispatch = useDispatch();
  const [isNewBranchVisible, setNewBranchVisible] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [branch, setBranch] = useState({});
  const [nameState, setNameState] = useState("");
  const { branches, saving, savingSuccess, deleteSuccess } = useSelector(
    (state) => state.branches
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(branchesActions.fetchBranches(match.params.id));
  }, []);

  useEffect(() => {
    if (savingSuccess) {
      setNewBranchVisible(false);
      const action = isEdit ? "updated" : "added";
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={`Branch ${action}`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Branch has been {isEdit ? "updated" : "added"}.
        </SweetAlert>
      );
    }
  }, [savingSuccess]);
  useEffect(() => {
    if (deleteSuccess) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={onCancel}
          onCancel={onCancel}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Branch has been deleted.
        </SweetAlert>
      );
    }
  }, [deleteSuccess]);

  const onCancel = () => {
    setAlert(null);
    dispatch(branchesActions.resetSuccessDelete());
  };

  const onDismissSave = () => {
    setAlert(null);
    dispatch(branchesActions.resetSuccessSave());
  };

  const warningWithConfirmMessage = (obj) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onDelete(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover the branch.
      </SweetAlert>
    );
  };

  const onDelete = (obj) => {
    dispatch(branchesActions.deleteBranch(obj.id));
  };

  const onDataChanged = (key, value) => {
    setBranch({
      ...branch,
      [key]: value,
    });
    if (key === "name") {
      if (value) {
        setNameState("success");
      } else {
        setNameState("error");
      }
    }
  };

  const onSubmit = () => {
    dispatch(
      branchesActions.saveBranch({
        ...branch,
        client_id: match.params.id,
      })
    );
  };

  const onOpenDialog = () => {
    setBranch({
      name: "",
      logo: "",
    });
    setEdit(false);
    setNameState("");
    setNewBranchVisible(true);
  };

  const onEdit = (branch) => {
    setEdit(true);
    setBranch(branch);
    setNameState("success");
    setNewBranchVisible(true);
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button
          onClick={onOpenDialog}
          style={{ float: "right", marginBottom: 10 }}
          size="lg"
          color="primary"
          variant="contained"
        >
          Add New Branch
        </Button>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <BusinessCenterIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Branches Management ({branches.length})
            </h4>
          </CardHeader>
          <CardBody />
        </Card>
        <GridContainer>
          {!!branches &&
            branches.map((branch) => (
              <GridItem key={branch.id} xs={12} sm={12} md={6}>
                <Card style={{ cursor: "pointer", float: "right" }}>
                  <Box
                    display="flex"
                    className="actions-right"
                    style={{
                      justifyContent: "flex-end",
                      float: "right",
                      width: "100%",
                      marginRight: 10,
                      justifyContent: "flex-between",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      flexGrow={1}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(
                          `/admin/accounts/${branch.client_id}/${branch.id}`
                        )
                      }
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ margin: 20, marginBottom: 5 }}
                      >
                        {branch.country} - {branch.city}
                      </Typography>
                      <Typography variant="body1" style={{ marginLeft: 20 }}>
                        {branch.address}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginLeft: 20, marginBottom: 20 }}
                      >
                        {branch.postcode}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => onEdit(branch)}
                        size="lg"
                        color="primary"
                        className="edit"
                      >
                        <EditIcon />
                      </Button>{" "}
                      {/* use this button to remove the data row */}
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => warningWithConfirmMessage(branch)}
                        size="lg"
                        color="danger"
                        className="remove"
                      >
                        <DeleteForeverIcon />
                      </Button>{" "}
                    </Box>
                  </Box>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </GridItem>
      {isNewBranchVisible && (
        <NewBranchDialog
          title={branch.id ? "Edit Branch" : "Add new Branch"}
          onClose={() => setNewBranchVisible(false)}
          onSubmit={onSubmit}
          onDataChanged={onDataChanged}
          data={branch}
          nameState={nameState}
          isModalVisible={isNewBranchVisible}
          saving={saving}
        />
      )}
      {alert}
    </GridContainer>
  );
};

export default BranchManagement;
