import { takeLatest, call, put, select } from "redux-saga/effects";
import { ESubContractorsActionTypes } from "./actions";
import axios from "../axios";

function* fetchSubcontractors() {
  try {
    const response = yield call([axios, axios.get], `/api/subcontractors`);
    if (response && response.data && response.status === 200) {
      yield put({
        type: ESubContractorsActionTypes.FETCH_SUBCONTRACTORS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: ESubContractorsActionTypes.FETCH_SUBCONTRACTORS_FAILED,
        payload: "Internal Server Error"
      });
    }
  } catch (e) {
    yield put({
      type: ESubContractorsActionTypes.FETCH_SUBCONTRACTORS_FAILED,
      payload: "Internal Server Error"
    });
  }
}
function* saveSubcontractor(action) {
  const { list } = yield select(state => state.subcontractors);
  let response;
  const request = action.payload;

  try {
    if (action.payload.id) {
      response = yield call(
        [axios, axios.put],
        `/api/subcontractors/${action.payload.id}`,
        request
      );
    } else {
      response = yield call(
        [axios, axios.post],
        `/api/subcontractors`,
        request
      );
    }

    if (response && response.status === 200) {
      yield put({
        type: ESubContractorsActionTypes.SAVING_SUCCESS,
        payload: response.data.success
      });
      if (action.payload.id) {
        const subcIndex = list.findIndex(x => x.id === action.payload.id);
        list[subcIndex] = action.payload;
        yield put({
          type: ESubContractorsActionTypes.FETCH_SUBCONTRACTORS_SUCCESS,
          payload: [...list]
        });
      } else {
        const id = response.data.success.id;
        const subc = {
          id,
          ...action.payload
        };
        list.unshift(subc);
        yield put({
          type: ESubContractorsActionTypes.FETCH_SUBCONTRACTORS_SUCCESS,
          payload: [...list]
        });
      }
    } else {
      yield put({ type: ESubContractorsActionTypes.SAVING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ESubContractorsActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* deleteSubcontractor(action) {
  try {
    const response = yield call(
      [axios, axios.delete],
      `/api/subcontractors/${action.payload}`
    );
    if (response.status === 200) {
      const { list } = yield select(state => state.subcontractors);

      yield put({
        type: ESubContractorsActionTypes.DELETING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: ESubContractorsActionTypes.FETCH_SUBCONTRACTORS_SUCCESS,
        payload: list.filter(x => x.id !== action.payload)
      });
    } else {
      yield put({ type: ESubContractorsActionTypes.DELETING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ESubContractorsActionTypes.DELETING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* subcontractorsSaga() {
  yield takeLatest(
    ESubContractorsActionTypes.FETCH_SUBCONTRACTORS,
    fetchSubcontractors
  );
  yield takeLatest(ESubContractorsActionTypes.SAVE_SUBC, saveSubcontractor);

  yield takeLatest(ESubContractorsActionTypes.DELETE_SUBC, deleteSubcontractor);
}

export default subcontractorsSaga;
