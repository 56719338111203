import React from "react";
import { Checkbox, Typography, Box, Divider, Button } from "@material-ui/core";
import { polylinecolors } from "redux/utils";
import moment from "moment";
import { IMAGE_URL, REQUEST_HISTORY } from "../../../constants";
// import blueMarker from "../../../assets/img/bluemarker.png";
import pinkMarker from "../../../assets/img/pinkmarker.png";
import orangeMarker from "../../../assets/img/orangemarker.png";
import greenMarker from "../../../assets/img/greenmarker.png";
import { useDispatch, useSelector } from "react-redux";
import { requestsActions } from "redux/requests/actions";
const TeamHistory = ({
  team,
  checkedRouteAgents,
  onChangeRouteAgents,
  onChangeTeamHistoryChecked,
  teamHistoryChecked,
  teamHistory,
  selectedRequest,
  archived,
}) => {
  const dispatch = useDispatch();
  const { coords, loadingHistory } = useSelector((state) => state.requests);
  const agents = team.agents;
  const images = teamHistory.filter(
    (x) => x.history_status == REQUEST_HISTORY.IMAGE
  );

  return (
    <div style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}>
      <Typography style={{ fontWeight: "bold" }}>Team History</Typography>
      {coords.length == 0 ? (
        <Button
          disabled={loadingHistory}
          onClick={() =>
            dispatch(
              requestsActions.getCoordsHistory({ team_id: team.id, archived })
            )
          }
          color="primary"
          variant="contained"
        >
          {" "}
          Get routes
        </Button>
      ) : (
        agents.map((item, index) => (
          <Box display="flex" justifyContent="space-between" key={item.id}>
            <div>
              <Typography>
                {item.agent.first_name} {item.agent.last_name}
              </Typography>
              <div
                style={{
                  width: 100,
                  height: 5,
                  backgroundColor: polylinecolors[index],
                }}
              />
            </div>
            <Checkbox
              onChange={(e) => onChangeRouteAgents(e, item.agent.id)}
              checked={checkedRouteAgents.includes(item.agent.id)}
              color="primary"
            />
          </Box>
        ))
      )}

      {teamHistory
        .filter((x) => x.history_status != REQUEST_HISTORY.IMAGE)
        .map((item) => (
          <>
            <Box key={item.id} display="flex" justifyContent="space-between">
              <img
                style={{ marginTop: 10 }}
                width={32}
                height={32}
                src={item.history_status == "STOP" ? orangeMarker : greenMarker}
              />
              <div>
                <Typography color="primary">{item.history_status}</Typography>
                <Typography>
                  {moment(item.created_at).format("DD-MM-YYYY H:m")}
                </Typography>
              </div>
              <Checkbox
                onChange={(e) => onChangeTeamHistoryChecked(e, item.id)}
                checked={teamHistoryChecked.includes(item.id)}
                color="primary"
              />
            </Box>
            <Divider />
          </>
        ))}
      {images.map((item) => (
        <>
          <Box key={item.id} display="flex" justifyContent="space-between">
            <img
              style={{ marginTop: 10 }}
              width={32}
              height={32}
              src={pinkMarker}
            />
            <div key={item.id}>
              <a
                href={`${IMAGE_URL}requests/'${selectedRequest.type}_${selectedRequest.identifier}/${item.image}`}
                target="_blank"
              >
                <img
                  className="imageDiv"
                  // onClick={() => props.setImage(item)}
                  style={{ width: 200, height: 200, margin: 10 }}
                  src={`${IMAGE_URL}requests/'${selectedRequest.type}_${selectedRequest.identifier}/${item.image}`}
                />
              </a>
            </div>
            <Checkbox
              onChange={(e) => onChangeTeamHistoryChecked(e, item.id)}
              checked={teamHistoryChecked.includes(item.id)}
              color="primary"
            />
          </Box>
          <Divider />
        </>
      ))}
    </div>
  );
};

export default TeamHistory;
