import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import InfoIcon from "@material-ui/icons/Info";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormHelperText from "@material-ui/core/FormHelperText";
import HttpIcon from "@material-ui/icons/Http";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import CustomInput from "components/CustomInput/CustomInput";
import { InputAdornment, Divider } from "@material-ui/core";
import Accordion from "components/Accordion/Accordion";
// import Button from "components/CustomButtons/Button";
import TransportDetails from "./TransportDetails";
import { REQUEST_TYPE } from "../../../../../../src/constants";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...regularFormsStyle,
};

const emptyDriver = {
  key: Math.random(),
  first_name: "",
  last_name: "",
  phone_number: "",
};
const emptyTruck = {
  known: true,
  known_drivers: true,
  id: Math.random(),
  truck: {
    plate_number: "",
    color: "",
    brand: "",
  },
  trailer: {
    plate_number: "",
    color: "",
    brand: "",
  },
  drivers: [{ ...emptyDriver }],
};
const initialTransports = [{ ...emptyTruck }];

export const MONITORING_TYPE = {
  ACTIVE: "active",
  PASSIVE: "passive",
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    const request = props.request;
    if (request && request.client_transport) {
      for (let i = 0; i < request.client_transport.length; i++) {
        const driver1 = request.client_transport[i].driver1;
        const driver2 = request.client_transport[i].driver2;
        let drivers = [driver1];
        if (driver2) drivers.push(driver2);
        request.client_transport[i].drivers = drivers;
      }
    }
    this.state = {
      transports: request ? request.client_transport : [...initialTransports],
      monitoringType: request
        ? request.Monitoring_type
        : MONITORING_TYPE.ACTIVE,
      agentCount: request ? request.number_agents : null,
      responsibleName: request ? request.responsible_name : "",
      phoneNumber: request ? request.phone_number : "",
      instruction: request ? request.instructions : "",
      gps_url: request ? request.gps_url : "",
      gps_username: request ? request.gps_username : "",
      gps_password: request ? request.gps_password : "",
      agentCountState: "",
      responsibleNameState: "",
      phoneNumberState: "",
      instructionState: "",
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onDataChanged = (key, value) => {
    if (key === "phoneNumber") {
      if (key === "phoneNumber" && value.length < 9)
        this.setState({ phoneNumberState: "error" });
      else this.setState({ phoneNumberState: "success" });
    } else {
      if (value && value !== "0") this.setState({ [key + "State"]: "success" });
      else this.setState({ [key + "State"]: "error" });
    }
    this.setState({ [key]: value });
  };
  isValidated() {
    const selectedRequest = this.props.allStates["step1"]
      ? this.props.allStates["step1"].selectedRequest
      : "";

    const {
      agentCount,
      responsibleName,
      phoneNumber,
      instruction,
    } = this.state;
    let isValid = true;
    if (selectedRequest === REQUEST_TYPE.STATIC) {
      if (!agentCount || agentCount === "0") {
        this.setState({ agentCountState: "error" });
        isValid = false;
      }
      if (!responsibleName) {
        this.setState({ responsibleNameState: "error" });
        isValid = false;
      }
      if (phoneNumber.length < 9) {
        this.setState({ phoneNumberState: "error" });
        isValid = false;
      }
      if (!instruction) {
        this.setState({ instructionState: "error" });
        isValid = false;
      }
      return isValid;
    } else {
      if (selectedRequest === REQUEST_TYPE.MONITORING) {
        const { gps_url, gps_username, gps_password } = this.state;
        if (!gps_url) {
          this.setState({ gps_urlState: "error" });
          isValid = false;
        } else {
          this.setState({ gps_urlState: "" });
        }
        if (!gps_username) {
          this.setState({ gps_usernameState: "error" });
          isValid = false;
        } else {
          this.setState({ gps_usernameState: "" });
        }
        if (!gps_password) {
          this.setState({ gps_passwordState: "error" });
          isValid = false;
        } else {
          this.setState({ gps_passwordState: "" });
        }
        return isValid;
      }
      const { transports } = this.state;
      for (let index = 0; index < transports.length; index++) {
        let updatedTruckTransport = this.validateTransport(
          transports[index],
          "truck",
          "plate_number",
          transports[index].truck.plate_number
        );
        transports[index] = updatedTruckTransport.updatedTransport;
        if (
          updatedTruckTransport.isValidTransport === false &&
          transports[index].known
        )
          isValid = false;

        let updatedTrailerTransport = this.validateTransport(
          updatedTruckTransport.updatedTransport,
          "trailer",
          "plate_number",
          updatedTruckTransport.updatedTransport.trailer
            ? updatedTruckTransport.updatedTransport.trailer.plate_number
            : ""
        );
        transports[index] = updatedTrailerTransport.updatedTransport;
        if (updatedTrailerTransport.isValidTransport === false) isValid = false;
        let drivers = transports[index].drivers;
        let updatedTransport = transports[index];
        for (let driverIndex = 0; driverIndex < drivers.length; driverIndex++) {
          let updatedDriver = this.validateDriver(
            transports[index],
            "drivers",
            "first_name",
            drivers[driverIndex].first_name,
            driverIndex
          );
          drivers[driverIndex] = updatedDriver.driver;
          if (
            updatedDriver.isValid === false &&
            transports[index].known_drivers
          )
            isValid = false;
          updatedTransport = { ...transports[index], drivers: [...drivers] };
          updatedDriver = this.validateDriver(
            updatedTransport,
            "drivers",
            "last_name",
            updatedDriver.driver.last_name,
            driverIndex
          );
          drivers[driverIndex] = updatedDriver.driver;
          if (
            updatedDriver.isValid === false &&
            transports[index].known_drivers
          )
            isValid = false;
          updatedTransport = { ...transports[index], drivers: [...drivers] };
          updatedDriver = this.validateDriver(
            updatedTransport,
            "drivers",
            "phone_number",
            updatedDriver.driver.phone_number,
            driverIndex
          );
          drivers[driverIndex] = updatedDriver.driver;
          if (
            updatedDriver.isValid === false &&
            transports[index].known_drivers
          )
            isValid = false;
          updatedTransport = { ...transports[index], drivers: [...drivers] };
        }

        transports[index] = updatedTransport;
      }

      this.setState({ transports });
    }
    return isValid;
    // return true;
  }

  validateTransport = (transport, key, nestedKey, value) => {
    let plateNumberState = transport[key].plateNumberState;
    let isValidTransport = true;
    const selectedRequest = this.props.allStates["step1"]
      ? this.props.allStates["step1"].selectedRequest
      : "";
    if (nestedKey === "plate_number") {
      if (key === "truck" || selectedRequest === REQUEST_TYPE.MONITORING) {
        if (value.length < 5) {
          plateNumberState = "error";
          isValidTransport = false;
        }
      } else {
        if (value && value.length < 5) {
          plateNumberState = "error";
          isValidTransport = false;
        }
      }
    }
    let updatedTransport = {
      ...transport,
      [key]: {
        ...transport[key],
        [nestedKey]: value,
        plateNumberState,
      },
    };
    return { updatedTransport, isValidTransport };
  };

  validateDriver = (transport, key, nestedKey, value, driverIndex) => {
    let driverFirstNameState = transport[key][driverIndex].driverFirstNameState;
    let driverLastNameState = transport[key][driverIndex].driverLastNameState;
    let driverPhoneNumberState =
      transport[key][driverIndex].driverPhoneNumberState;
    let isValid = true;

    if (nestedKey === "first_name") {
      if (!value) {
        driverFirstNameState = "error";
        isValid = false;
      }
    } else if (nestedKey === "last_name") {
      if (!value) {
        driverLastNameState = "error";
        isValid = false;
      }
    } else if (nestedKey === "phone_number") {
      if (!value) driverPhoneNumberState = "";
      else {
        if (value.length < 9) {
          driverPhoneNumberState = "error";
          isValid = false;
        }
      }
    }

    let driver = transport[key][driverIndex];
    driver = {
      ...driver,
      driverFirstNameState,
      driverLastNameState,
      driverPhoneNumberState,
    };

    return { driver, isValid };
  };

  onAddDriver = (index) => {
    const { transports } = this.state;
    const drivers = [...transports[index].drivers];
    drivers.push({ key: Math.random() });
    transports[index].drivers = [...drivers];
    this.setState({ transports });
  };

  onRemoveDriver = (index, driver) => {
    const { transports } = this.state;
    const drivers = transports[index].drivers;
    drivers.splice(driver, 1);
    transports[index].drivers = drivers;
    this.setState({ transports });
  };

  onRemoveTransportDetails = (index) => {
    const { transports } = this.state;
    const transport = transports[index];
    transports.splice(transport, 1);
    this.setState({ transports });
  };

  generateTrucks = () => {
    const accordion = [];
    const { transports } = this.state;
    const selectedRequest = this.props.allStates["step1"]
      ? this.props.allStates["step1"].selectedRequest
      : "";
    for (let i = 0; i < transports.length; i++) {
      accordion.push({
        title: `${i + 1}. Truck-Trailer-Drivers`,
        content: (
          <TransportDetails
            key={i + ""}
            onChangeTransportToggle={(key, value) => {
              const { transports } = this.state;
              transports[i][key] = value;
              this.setState({ transports });
            }}
            onSelect={(key, value, driverIndex) =>
              this.onSelectTransport(key, value, i, driverIndex)
            }
            transport={transports[i]}
            transports={transports}
            onChange={(key, nestedKey, value, driverIndex) =>
              this.onTransportChanged(key, nestedKey, value, i, driverIndex)
            }
            onRemoveDriver={(driver) => this.onRemoveDriver(i, driver)}
            onAddDriver={() => this.onAddDriver(i)}
            allowRemove={i > 0}
            requestType={selectedRequest}
            onRemoveTransportDetails={() => this.onRemoveTransportDetails(i)}
          />
        ),
      });
    }
    return accordion;
  };

  onAddNewTruck = () => {
    const { transports } = this.state;

    transports.push({
      ...emptyTruck,
      id: Math.random(),
    });
    this.setState({ transports: [...transports] });
  };

  onMonitoringTypeChanged = (event) => {
    this.setState({ monitoringType: event.target.value });
  };

  onSelectTransport = (key, value, index, driverIndex) => {
    const { transports } = this.state;
    let updatedTransport;
    if (driverIndex != undefined) {
      let drivers = [...transports[index].drivers];
      if (value) {
        drivers[driverIndex] = {
          ...value,
          driverFirstNameState: "success",
          driverLastNameState: "success",
          driverPhoneNumberState: "success",
        };
      } else {
        drivers[driverIndex] = {
          first_name: "",
          last_name: "",
          phone_number: "",
          driverFirstNameState: "",
          driverLastNameState: "",
          driverPhoneNumberState: "",
        };
      }

      updatedTransport = {
        ...transports[index],
        [key]: [...drivers],
      };
    } else {
      if (value) {
        updatedTransport = {
          ...transports[index],
          [key]: {
            ...value,
            plateNumberState: "success",
          },
        };
      } else {
        updatedTransport = {
          ...transports[index],
          [key]: {
            plate_number: "",
            color: "",
            brand: "",
            plateNumberState: "",
          },
        };
      }
    }
    transports[index] = updatedTransport;
    this.setState({ transports });
  };

  onTransportChanged = (key, nestedKey, value, index, driverIndex) => {
    const { transports } = this.state;
    const { requests } = this.props;
    let updatedTransport;
    if (driverIndex !== undefined) {
      let driverFirstNameState =
        transports[index][key][driverIndex].driverFirstNameState;
      let driverLastNameState =
        transports[index][key][driverIndex].driverLastNameState;
      let driverPhoneNumberState =
        transports[index][key][driverIndex].driverPhoneNumberState;

      if (nestedKey === "first_name") {
        if (!value) driverFirstNameState = "error";
        else driverFirstNameState = "success";
      } else if (nestedKey === "last_name") {
        if (!value) driverLastNameState = "error";
        else driverLastNameState = "success";
      } else if (nestedKey === "phone_number") {
        if (!value) driverPhoneNumberState = "";
        else {
          if (value.length < 9) driverPhoneNumberState = "error";
          else driverPhoneNumberState = "success";
        }
      }

      let drivers = transports[index][key];
      drivers[driverIndex] = {
        ...drivers[driverIndex],
        [nestedKey]: value,
        driverFirstNameState,
        driverLastNameState,
        driverPhoneNumberState,
      };

      updatedTransport = {
        ...transports[index],
        [key]: [...drivers],
      };
      transports[index] = updatedTransport;
      this.setState({ transports });
    } else {
      let plateNumberState = transports[index][key].plateNumberState;
      if (nestedKey === "plate_number") {
        if (value.length < 5) plateNumberState = "error";
        else plateNumberState = "success";
      }
      if (nestedKey === "plate_number" && plateNumberState === "success") {
        const existTransport = requests[key + "s"][key + "s"].find(
          (x) => x.plate_number.toUpperCase() === value.toUpperCase()
        );
        if (existTransport) {
          if (
            transports.filter((item) => item[key].id === existTransport.id)
              .length > 0
          ) {
            // transport have been already chosen
            updatedTransport = {
              ...transports[index],
              [key]: {
                ...transports[index][key],
                [nestedKey]: "",
                id: undefined,
                plateNumberState: "error",
              },
            };
            transports[index] = updatedTransport;
            this.setState({ transports });
          } else {
            updatedTransport = {
              ...transports[index],
              [key]: {
                ...existTransport,
                plateNumberState,
              },
            };
          }
          transports[index] = updatedTransport;
          this.setState({ transports });
          return;
        }
      }
      updatedTransport = {
        ...transports[index],
        [key]: {
          ...transports[index][key],
          [nestedKey]: value,
          id: undefined,
          plateNumberState,
        },
      };
      transports[index] = updatedTransport;
      this.setState({ transports });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allStates && nextProps.allStates.step1) {
      let selectedRequest = nextProps.allStates.step1.selectedRequest;
      if (selectedRequest === REQUEST_TYPE.MONITORING) {
        let { transports } = prevState;
        if (transports.length > 1) {
          return { transports: [transports[0]] };
        }
      }
    }
    return null;
  }

  render() {
    const { classes, allStates } = this.props;
    const { transports, monitoringType } = this.state;

    const selectedRequest =
      allStates && allStates["step1"] ? allStates["step1"].selectedRequest : "";

    return (
      <div>
        {/* <GridContainer style={{ paddingLeft: 10 }}> */}
        {selectedRequest !== REQUEST_TYPE.STATIC ? (
          <>
            {selectedRequest === REQUEST_TYPE.MONITORING && (
              <div style={{ marginLeft: 15, marginRight: 15 }}>
                <GridItem xs={12} sm={12} md={12} display="flex">
                  <h5 style={{ float: "left", marginRight: 20 }}>
                    Monitoring type
                  </h5>
                  <Box display="flex">
                    <FormControlLabel
                      control={
                        <Radio
                          checked={monitoringType === MONITORING_TYPE.ACTIVE}
                          onChange={this.onMonitoringTypeChanged}
                          value={MONITORING_TYPE.ACTIVE}
                          name="radio button demo"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="Active"
                    />
                    <Tooltip
                      style={{ marginTop: 13 }}
                      id="tooltip-top"
                      title="we will send you a status report like location/temperature every hour or a specific time."
                    >
                      <InfoIcon fontSize="small" color="primary" />
                    </Tooltip>
                    <FormControlLabel
                      style={{ marginLeft: 10 }}
                      control={
                        <Radio
                          checked={monitoringType === MONITORING_TYPE.PASSIVE}
                          onChange={this.onMonitoringTypeChanged}
                          value={MONITORING_TYPE.PASSIVE}
                          name="radio button demo"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="Passive"
                    />
                    <Tooltip
                      style={{ marginTop: 13 }}
                      id="tooltip-top"
                      title="we only react on alarms"
                    >
                      <InfoIcon fontSize="small" color="primary" />
                    </Tooltip>
                  </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    id="success"
                    placeholder="GPS Url"
                    onChange={(text) => this.onDataChanged("gps_url", text)}
                    value={this.state.gps_url}
                    error={this.state.gps_urlState === "error"}
                    helperText={
                      this.state.gps_urlState === "error" &&
                      "GPS Url is required"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <HttpIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5} style={{ marginTop: 20 }}>
                  <CustomInput
                    id="success"
                    // labelText="GPS username"
                    placeholder="GPS username"
                    error={this.state.gps_usernameState === "error"}
                    value={this.state.gps_username}
                    onChange={(text) =>
                      this.onDataChanged("gps_username", text)
                    }
                    helperText={
                      this.state.gps_usernameState === "error" &&
                      "GPS Username is required"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <VerifiedUserIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5} style={{ marginTop: 20 }}>
                  <CustomInput
                    id="success"
                    placeholder="GPS password"
                    error={this.state.gps_passwordState === "error"}
                    value={this.state.gps_password}
                    onChange={(text) =>
                      this.onDataChanged("gps_password", text)
                    }
                    helperText={
                      this.state.gps_passwordState === "error" &&
                      "GPS Password is required"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <VpnKeyIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: 40, alignItems: "center" }}
                >
                  <Divider />
                </GridItem>
              </div>
            )}
            {selectedRequest !== REQUEST_TYPE.MONITORING && (
              <Button
                onClick={this.onAddNewTruck}
                style={{ backgroundColor: "transparent" }}
                color="primary"
                variant="outlined"
              >
                Add new Truck details
              </Button>
            )}
            <GridItem xs={12} sm={12} md={12}>
              {transports.length === 1 ? (
                <TransportDetails
                  onChangeTransportToggle={(key, value) => {
                    const { transports } = this.state;
                    transports[0][key] = value;
                    this.setState({ transports });
                  }}
                  onChange={(key, nestedKey, value, driverIndex) =>
                    this.onTransportChanged(
                      key,
                      nestedKey,
                      value,
                      0,
                      driverIndex
                    )
                  }
                  transports={transports}
                  onSelect={(key, value, driverIndex) =>
                    this.onSelectTransport(key, value, 0, driverIndex)
                  }
                  transport={transports[0]}
                  onAddDriver={() => this.onAddDriver(0)}
                  onRemoveDriver={(driver) => this.onRemoveDriver(0, driver)}
                  allowRemove={false}
                  requestType={selectedRequest}
                />
              ) : (
                <Accordion
                  active={transports.length - 1}
                  collapses={this.generateTrucks()}
                />
              )}
            </GridItem>
          </>
        ) : (
          <>
            <GridItem xs={12} sm={12} md={12} fullWidth>
              <h4 style={{ float: "left" }}>
                How many security guard do you need ?{" "}
              </h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="number of security guards"
                value={this.state.agentCount}
                helperText={
                  this.state.agentCountState === "error" &&
                  "agent count is required"
                }
                success={this.state.agentCountState === "success"}
                error={this.state.agentCountState === "error"}
                formControlProps={{
                  fullWidth: true,
                }}
                onChange={(text) => this.onDataChanged("agentCount", text)}
                inputProps={{
                  type: "number",
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    />
                  ),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} fullWidth>
              <h4 style={{ float: "left" }}>How we can reach you ? </h4>
              <GridContainer direction="row" xs={12} sm={12} md={12}>
                <GridItem xs={6} sm={12} md={6}>
                  <CustomInput
                    id="success"
                    labelText="responsible name"
                    value={this.state.responsibleName}
                    helperText={
                      this.state.responsibleNameState === "error" &&
                      "responsible name is required"
                    }
                    success={this.state.responsibleNameState === "success"}
                    error={this.state.responsibleNameState === "error"}
                    onChange={(text) =>
                      this.onDataChanged("responsibleName", text)
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                        />
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={12} md={6}>
                  <CustomInput
                    id="success"
                    labelText="phone number"
                    value={this.state.phoneNumber}
                    helperText={
                      this.state.phoneNumberState === "error" &&
                      "phone number is not valid"
                    }
                    success={this.state.phoneNumberState === "success"}
                    error={this.state.phoneNumberState === "error"}
                    onChange={(text) => this.onDataChanged("phoneNumber", text)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                        />
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <h4 style={{ float: "left" }}>what we can help you with ? </h4>
              <GridItem xs={12} sm={12}>
                <TextareaAutosize
                  style={{
                    width: "100%",
                    fontSize: 20,
                    padding: 10,
                    fontFamily: "Helvetica",
                    fontWeight: 200,
                    borderColor:
                      this.state.instructionState &&
                      (this.state.instructionState === "error"
                        ? "#f44336"
                        : "#4caf50"),
                  }}
                  value={this.state.instruction}
                  helperText={
                    this.state.instructionState === "error" &&
                    "instruction is required"
                  }
                  success={this.state.instructionState === "success"}
                  error={this.state.instructionState === "error"}
                  onChange={(event) =>
                    this.onDataChanged("instruction", event.target.value)
                  }
                  rows={5}
                  placeholder="add the instruction here..."
                />
                {this.state.instructionState === "error" && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    instruction is required
                  </FormHelperText>
                )}
              </GridItem>
            </GridItem>
          </>
        )}
        {/* </GridContainer> */}
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
  allStates: PropTypes.object,
};

const mapToProps = (state) => {
  return {
    requests: state,
  };
};

export default connect(
  mapToProps,
  null
)(withStyles(style)(Step2));
