import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { EAgentsActionTypes } from "./actions";

const defaultState = {
  agents: [],
  saving: false,
  savingError: false,
  savingSuccess: false,
  deleteSuccess: false,
  deleteFailed: false,
  activateSuccess: false,
  activateFailed: false,
  resetSuccess: false
};

const persistConfig = {
  key: "agents",
  storage,
  whitelist: ["agents"]
};

const agentsReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case EAgentsActionTypes.GET_AGENTS_SUCCESS:
        return {
          ...state,
          agents: action.payload
        };
      case EAgentsActionTypes.SAVE_AGENT:
        return {
          ...state,
          saving: true
        };
      case EAgentsActionTypes.SAVING_FAILED:
        return {
          ...state,
          saving: false,
          savingError: Math.random(),
          savingSuccess: false
        };
      case EAgentsActionTypes.SAVING_SUCCESS:
        return {
          ...state,
          saving: false,
          savingError: false,
          savingSuccess: Math.random()
        };
      case EAgentsActionTypes.DELETING_SUCCESS:
        return {
          ...state,
          deleteSuccess: Math.random()
        };
      case EAgentsActionTypes.RESET_SUCCESS_DELETE:
        return {
          ...state,
          deleteSuccess: false
        };
      case EAgentsActionTypes.ACTIVATING_SUCCESS:
        return {
          ...state,
          activateSuccess: Math.random()
        };
      case EAgentsActionTypes.RESET_SUCCESS_ACTIVATE:
        return {
          ...state,
          activateSuccess: false
        };
      case EAgentsActionTypes.RESET_SUCCESS_SAVE:
        return {
          ...state,
          savingSuccess: false
        };
      case EAgentsActionTypes.RESETTING_SUCCESS:
        return {
          ...state,
          resetSuccess: Math.random()
        };
      case EAgentsActionTypes.RESET_SUCCESS_RESET:
        return {
          ...state,
          resetSuccess: false
        };
      default:
        return state;
    }
  }
);

export default agentsReducer;
