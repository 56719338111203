import { takeLatest, call, put, select } from "redux-saga/effects";
import { EAgentsActionTypes } from "./actions";
import axios from "../axios";
import { isAdmin } from "../../constants";
function* fetchAgents() {
  try {
    const response = yield call([axios, axios.get], `/api/agents`);
    if (response && response.data && response.data.success) {
      yield put({
        type: EAgentsActionTypes.GET_AGENTS_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: EAgentsActionTypes.GET_AGENTS_FAILED });
    }
  } catch (e) {
    yield put({
      type: EAgentsActionTypes.GET_AGENTS_FAILED,
      payload: e.response.statusText
    });
  }
}
function* saveAgent(action) {
  // const { user } = yield select(state => state.user);
  let { agents } = yield select(state => state.agents);
  let response;
  let request = action.payload;
  // if (isAdmin) {
  //   request = {
  //     ...request,
  //     branch_id: user.branch_id
  //   };
  // }

  try {
    if (action.payload.id) {
      response = yield call(
        [axios, axios.put],
        `/api/agents/${action.payload.id}`,
        request
      );
    } else {
      request.status = 1;
      response = yield call([axios, axios.post], `/api/agents`, request);
    }

    if (response.data && !response.data.error) {
      yield put({
        type: EAgentsActionTypes.SAVING_SUCCESS,
        payload: response.data.success
      });
      if (action.payload.id) {
        const agentIndex = agents.findIndex(x => x.id === action.payload.id);
        agents[agentIndex] = action.payload;
        yield put({
          type: EAgentsActionTypes.GET_AGENTS_SUCCESS,
          payload: [...agents]
        });
      } else {
        const id = response.data.success.id;
        const agent = {
          id,
          ...action.payload,
          status: 1
        };
        agents.unshift(agent);
        yield put({
          type: EAgentsActionTypes.GET_AGENTS_SUCCESS,
          payload: [...agents]
        });
      }
    } else {
      yield put({
        type: EAgentsActionTypes.SAVING_FAILED,
        payload: response.data.error.message
      });
    }
  } catch (e) {
    yield put({
      type: EAgentsActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* deleteAgent(action) {
  try {
    const response = yield call(
      [axios, axios.delete],
      `/api/agents/${action.payload}`
    );
    if (response.status === 200) {
      const { agents } = yield select(state => state.agents);

      const agentIndex = agents.findIndex(x => x.id === action.payload);
      agents[agentIndex].status = "0";
      yield put({
        type: EAgentsActionTypes.DELETING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: EAgentsActionTypes.GET_AGENTS_SUCCESS,
        payload: [...agents]
      });
    } else {
      yield put({ type: EAgentsActionTypes.DELETING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EAgentsActionTypes.DELETING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* activateAgent(action) {
  try {
    const response = yield call(
      [axios, axios.put],
      `/api/agents/activate/${action.payload}`
    );
    if (response.status === 200) {
      const { agents } = yield select(state => state.agents);

      const agentIndex = agents.findIndex(x => x.id === action.payload);
      agents[agentIndex].status = "1";
      yield put({
        type: EAgentsActionTypes.ACTIVATING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: EAgentsActionTypes.GET_AGENTS_SUCCESS,
        payload: [...agents]
      });
    } else {
      yield put({ type: EAgentsActionTypes.ACTIVATING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EAgentsActionTypes.ACTIVATING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* resetAccount(action) {
  try {
    const response = yield call(
      [axios, axios.put],
      `/api/agents/reset/${action.payload}`
    );
    if (response.status === 200) {
      yield put({
        type: EAgentsActionTypes.RESETTING_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: EAgentsActionTypes.RESETTING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EAgentsActionTypes.RESETTING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* requestsSaga() {
  yield takeLatest(EAgentsActionTypes.GET_AGENTS, fetchAgents);
  yield takeLatest(EAgentsActionTypes.SAVE_AGENT, saveAgent);
  yield takeLatest(EAgentsActionTypes.DELETE_AGENT, deleteAgent);
  yield takeLatest(EAgentsActionTypes.ACTIVATE_AGENT, activateAgent);
  yield takeLatest(EAgentsActionTypes.RESET_ACCOUNT, resetAccount);
}

export default requestsSaga;
