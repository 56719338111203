import { Card, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
}));

const ChatContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
      style={{ marginBottom: 80, width: 500, height: 550 }}
    >
      {children}
    </Card>
  );
};

export default ChatContainer;
