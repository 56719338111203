import DashboardClient from "views/Admin/Dashboard.js";
import LoginPage from "views/Pages/LoginPage.js";
import ExportEscortInvoice from "views/Admin/ExportSubcontractorEscortInvoice";
import RequestTracking from "views/Admin/Tracking/RequestTracking";
import AgentsManagement from "views/Admin/AgentsManagement";
import CarsManagement from "views/Admin/CarsManagement";
import RoomIcon from "@material-ui/icons/Room";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const subcRoutes = [
  {
    path: "/dashboard",
    name: "Requests",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: DashboardClient,
    layout: "/subc",
  },
  {
    path: "/export-invoices",
    name: "Export Invoices",
    rtlName: "لوحة القيادة",
    icon: FileCopyIcon,
    component: ExportEscortInvoice,
    layout: "/subc",
  },
  {
    path: "/tracking",
    name: "Tracking",
    rtlName: "لوحة القيادة",
    icon: RoomIcon,
    component: RequestTracking,
    layout: "/subc",
  },
  {
    path: "/cars-management",
    name: "Cars",
    rtlName: "لوحة القيادة",
    icon: DirectionsCarIcon,
    component: CarsManagement,
    layout: "/subc",
  },
  {
    path: "/agents-management",
    name: "Agents",
    rtlName: "لوحة القيادة",
    icon: SupervisedUserCircleIcon,
    component: AgentsManagement,
    layout: "/subc",
  },
  {
    path: "/login-page",
    name: "Log out",
    rtlName: "لوحة القيادة",
    icon: MeetingRoomIcon,
    component: LoginPage,
    layout: "/auth",
  },
];
export default subcRoutes;
