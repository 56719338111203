import { takeLatest, call, put, select } from "redux-saga/effects";
import { ETrucksActionTypes } from "./actions";
import axios from "../axios";

function* fetchTrucks() {
  const { user } = yield select(state => state.user);
  try {
    const response = yield call(
      [axios, axios.get],
      `/api/trucks_by_branch/${user.branch_id}`
    );
    if (response && response.data && response.data.success) {
      yield put({
        type: ETrucksActionTypes.GET_TRUCKS_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: ETrucksActionTypes.GET_TRUCKS_FAILED });
    }
  } catch (e) {
    yield put({
      type: ETrucksActionTypes.GET_TRUCKS_FAILED,
      payload: e.response.statusText
    });
  }
}

function* saveTruck(action) {
  const { user } = yield select(state => state.user);
  const { trucks } = yield select(state => state.trucks);
  let response;
  const request = {
    ...action.payload,
    branch_id: user.branch_id
  };

  try {
    if (action.payload.id) {
      response = yield call(
        [axios, axios.put],
        `/api/trucks/${action.payload.id}`,
        request
      );
    } else {
      response = yield call([axios, axios.post], `/api/trucks`, request);
    }

    if (response && response.status === 200) {
      yield put({
        type: ETrucksActionTypes.SAVING_SUCCESS,
        payload: response.data.success
      });
      if (action.payload.id) {
        const truckIndex = trucks.findIndex(x => x.id === action.payload.id);
        trucks[truckIndex] = action.payload;
        yield put({
          type: ETrucksActionTypes.GET_TRUCKS_SUCCESS,
          payload: [...trucks]
        });
      } else {
        const id = response.data.success.id;
        const truck = {
          id,
          ...action.payload
        };
        trucks.unshift(truck);
        yield put({
          type: ETrucksActionTypes.GET_TRUCKS_SUCCESS,
          payload: [...trucks]
        });
      }
    } else {
      yield put({ type: ETrucksActionTypes.SAVING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ETrucksActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* deleteTruck(action) {
  try {
    const response = yield call(
      [axios, axios.delete],
      `/api/trucks/${action.payload}`
    );
    if (response.status === 200) {
      const { trucks } = yield select(state => state.trucks);

      yield put({
        type: ETrucksActionTypes.DELETING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: ETrucksActionTypes.GET_TRUCKS_SUCCESS,
        payload: trucks.filter(x => x.id !== action.payload)
      });
    } else {
      yield put({ type: ETrucksActionTypes.DELETING_FAILED });
    }
  } catch (e) {
    yield put({
      type: ETrucksActionTypes.DELETING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* requestsSaga() {
  yield takeLatest(ETrucksActionTypes.GET_TRUCKS, fetchTrucks);
  yield takeLatest(ETrucksActionTypes.SAVE_TRUCK, saveTruck);
  yield takeLatest(ETrucksActionTypes.DELETE_TRUCK, deleteTruck);
}

export default requestsSaga;
