import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import NewDriverDialog from "./NewDriverDialog";
import { driversActions } from "redux/drivers/actions";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  input: {
    paddingTop: 0
  }
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles
});

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
}

export const DriversManagement = () => {
  const dispatch = useDispatch();
  const [isNewTruckVisible, setNewTruckVisible] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [driver, setDriver] = useState({});
  const { drivers, saving, savingSuccess, deleteSuccess } = useSelector(
    state => state.drivers
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(driversActions.fetchDrivers());
  }, []);
  useEffect(() => {
    if (savingSuccess) {
      setNewTruckVisible(false);
      const action = isEdit ? "updated" : "added";
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={`Driver ${action}`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Driver has been {isEdit ? "updated" : "added"}.
        </SweetAlert>
      );
    }
  }, [savingSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={onCancel}
          onCancel={onCancel}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Driver has been deleted.
        </SweetAlert>
      );
    }
  }, [deleteSuccess]);

  const onCancel = () => {
    setAlert(null);
    dispatch(driversActions.resetSuccessDelete());
  };

  const onDismissSave = () => {
    setAlert(null);
    dispatch(driversActions.resetSuccessSave());
  };

  const warningWithConfirmMessage = obj => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onDelete(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover the driver.
      </SweetAlert>
    );
  };

  const onDelete = obj => {
    dispatch(driversActions.deleteDriver(obj.id));
  };

  const onSubmit = () => {
    let firstNameState = driver.firstNameState;
    let lastNameState = driver.lastNameState;
    let phoneNumberState = driver.phoneNumberState;

    if (!driver.first_name) {
      firstNameState = "error";
    }
    if (!driver.last_name) {
      lastNameState = "error";
    }
    if (
      firstNameState === "error" ||
      lastNameState === "error" ||
      phoneNumberState === "error"
    ) {
      setDriver({
        ...driver,
        firstNameState,
        lastNameState,
        phoneNumberState
      });
    } else {
      dispatch(driversActions.saveDriver(driver));
    }
  };

  const onDataChanged = (key, value) => {
    let firstNameState = driver.firstNameState;
    let lastNameState = driver.lastNameState;
    let phoneNumberState = driver.phoneNumberState;
    if (key === "first_name") {
      if (!value) firstNameState = "error";
      else firstNameState = "success";
    }
    if (key === "last_name") {
      if (!value) lastNameState = "error";
      else lastNameState = "success";
    }
    if (key === "phone_number") {
      if (value) {
        if (value.length < 9) phoneNumberState = "error";
        else phoneNumberState = "success";
      } else {
        phoneNumberState = "";
      }
    }

    setDriver({
      ...driver,
      [key]: value,
      firstNameState,
      lastNameState,
      phoneNumberState
    });
  };

  const onOpenDialog = () => {
    setDriver({
      first_name: "",
      last_name: "",
      phone_number: "",
      firstNameState: "",
      lastNameState: "",
      phoneNumberState: ""
    });
    setEdit(false);
    setNewTruckVisible(true);
  };

  const onEdit = driver => {
    setEdit(true);
    setDriver(driver);
    setNewTruckVisible(true);
  };

  useEffect(() => {
    setData(
      drivers.map(prop => {
        return {
          id: prop.id,
          first_name: prop.first_name,
          last_name: prop.last_name,
          phone_number: prop.phone_number || "N/A",
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  let driver = drivers.find(o => o.id === prop.id);
                  onEdit(driver);
                }}
                size="lg"
                color="primary"
                className="edit"
              >
                <EditIcon />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  const obj = drivers.find(o => o.id === prop.id);
                  warningWithConfirmMessage(obj);
                }}
                size="lg"
                color="danger"
                className="remove"
              >
                <DeleteForeverIcon />
              </Button>{" "}
            </div>
          )
        };
      })
    );
  }, [drivers]);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button
          onClick={onOpenDialog}
          style={{ float: "right", marginBottom: 10 }}
          size="lg"
          color="primary"
          variant="contained"
        >
          Add New Driver
        </Button>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <SupervisedUserCircleIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Drivers Management ({drivers.length})
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              FilterComponent={({ filter, onChange }) => (
                <CustomInput
                  value={filter ? filter.value : ""}
                  onChange={value => onChange(value)}
                  id="success"
                  // labelText="Plate number"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.input
                  }}
                  inputProps={{
                    value: filter ? filter.value : ""
                  }}
                />
              )}
              data={data}
              filterable
              defaultFilterMethod={filterCaseInsensitive}
              columns={[
                {
                  Header: "First Name",
                  accessor: "first_name"
                },
                {
                  Header: "Last Name",
                  accessor: "last_name"
                },
                {
                  Header: "Phone Number",
                  accessor: "phone_number"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      <NewDriverDialog
        onClose={() => setNewTruckVisible(false)}
        onSubmit={onSubmit}
        onDataChanged={onDataChanged}
        data={driver}
        isModalVisible={isNewTruckVisible}
        saving={saving}
      />
      {alert}
    </GridContainer>
  );
};

export default DriversManagement;
