import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { ETrailersActionTypes } from "./actions";

const defaultState = {
  trailers: [],
  saving: false,
  savingError: false,
  savingSuccess: false,
  deleteSuccess: false,
  deleteFailed: false
};

const persistConfig = {
  key: "trailers",
  storage,
  whitelist: ["trailers"]
};

const trailersReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case ETrailersActionTypes.GET_TRAILERS_SUCCESS:
        return {
          ...state,
          trailers: action.payload
        };
      case ETrailersActionTypes.SAVE_TRAILER:
        return {
          ...state,
          saving: true
        };
      case ETrailersActionTypes.SAVING_FAILED:
        return {
          ...state,
          saving: false,
          savingError: Math.random(),
          savingSuccess: false
        };
      case ETrailersActionTypes.SAVING_SUCCESS:
        return {
          ...state,
          saving: false,
          savingError: false,
          savingSuccess: Math.random()
        };
      case ETrailersActionTypes.DELETING_SUCCESS:
        return {
          ...state,
          deleteSuccess: Math.random()
        };
      case ETrailersActionTypes.RESET_SUCCESS_DELETE:
        return {
          ...state,
          deleteSuccess: false
        };
      case ETrailersActionTypes.RESET_SUCCESS_SAVE:
        return {
          ...state,
          savingSuccess: false
        };
      default:
        return state;
    }
  }
);

export default trailersReducer;
