import React from "react";
import { Grid, Box } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import PinDropIcon from "@material-ui/icons/PinDrop";

const RequestPlaceItem = props => (
  <Grid style={{ marginTop: 10 }}>
    <Box display="flex">
      <PlaceIcon />
      <p style={{ marginTop: 3, fontSize: 16 }}>
        <label
          style={{
            fontWeight: 500,
            marginTop: 3,
            color: "#000",
            marginRight: 10
          }}
        >
          Pick up point:
        </label>
        {props.pup}
      </p>
    </Box>
    {props.dop && (
      <Box display="flex">
        <PinDropIcon />
        <p style={{ marginTop: 3, fontSize: 16 }}>
          <label
            style={{
              fontWeight: 500,
              marginTop: 3,
              color: "#000",
              marginRight: 10
            }}
          >
            Drop off point:
          </label>
          {props.dop}
        </p>
      </Box>
    )}
  </Grid>
);

export default RequestPlaceItem;
