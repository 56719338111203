import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import { useSelector, useDispatch } from "react-redux";
import FaceIcon from "@material-ui/icons/Face";
import { userActions } from "../../redux/user/actions";
import SnackbarContent from "components/Snackbar/SnackbarContent";

const Profile = () => {
  const { user, successPassword, successProfile } = useSelector(
    state => state.user
  );
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    dispatch(userActions.resetProfileSuccess());
  }, []);
  useEffect(() => {
    setProfileData({
      first_name: user.first_name,
      last_name: user.last_name,
      phone_number: user.phone_number,
      email: user.email
    });
    setData({
      email: user.email
    });
  }, [user]);

  const onSubmitChangeProfile = () => {
    dispatch(userActions.updateProfile(profileData));
  };
  const onSubmitChangePassword = () => {
    dispatch(userActions.updatePassword(data));
  };
  const onDataChanged = (key, value) => {
    setData({
      ...data,
      [key]: value
    });
  };
  const onProfileDataChanged = (key, value) => {
    setProfileData({
      ...profileData,
      [key]: value
    });
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <FaceIcon />
            </CardIcon>
            <Typography color="primary" variant="h5">
              Profile
            </Typography>
          </CardHeader>
          <CardBody style={{ margin: 10 }}>
            <ValidatorForm onSubmit={onSubmitChangeProfile}>
              <TextValidator
                style={{ marginTop: 20 }}
                value={profileData.first_name}
                onChange={e =>
                  onProfileDataChanged("first_name", e.target.value)
                }
                label="First name"
                validators={["required"]}
                errorMessages={["First name is required"]}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextValidator
                style={{ marginTop: 20 }}
                value={profileData.last_name}
                onChange={e =>
                  onProfileDataChanged("last_name", e.target.value)
                }
                label="Last name"
                validators={["required"]}
                errorMessages={["Last name is required"]}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextValidator
                style={{ marginTop: 20 }}
                value={profileData.phone_number}
                onChange={e =>
                  onProfileDataChanged("phone_number", e.target.value)
                }
                label="Phone Number"
                validators={["required"]}
                errorMessages={["Phone number is required"]}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextValidator
                style={{ marginTop: 20 }}
                value={profileData.email}
                onChange={e => onDataChanged("email", e.target.value)}
                label="Email"
                validators={["required"]}
                errorMessages={["Email is required"]}
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true
                }}
              />
              <Button type="submit" style={{ marginTop: 20 }} color="primary">
                Submit
              </Button>
              {successProfile && (
                <div style={{ marginTop: 20 }}>
                  <SnackbarContent
                    message={"Your profile is updated with success"}
                    color="success"
                  />
                </div>
              )}
            </ValidatorForm>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <LockOpenIcon />
            </CardIcon>
            <Typography color="primary" variant="h5">
              Change password
            </Typography>
          </CardHeader>
          <CardBody style={{ margin: 10 }}>
            <ValidatorForm onSubmit={onSubmitChangePassword}>
              <TextValidator
                style={{ marginTop: 20 }}
                value={data.old_password}
                onChange={e => onDataChanged("old_password", e.target.value)}
                label="Current Password"
                validators={["required"]}
                type="password"
                errorMessages={["Current password is required"]}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextValidator
                style={{ marginTop: 20 }}
                value={data.password}
                onChange={e => onDataChanged("password", e.target.value)}
                label="New Password"
                type="password"
                validators={["required"]}
                errorMessages={["New password is required"]}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextValidator
                style={{ marginTop: 20 }}
                value={data.c_password}
                onChange={e => onDataChanged("c_password", e.target.value)}
                label="Confirm Password"
                type="password"
                validators={["required"]}
                errorMessages={[
                  "Confirm password does not math with your new password!"
                ]}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <Button type="submit" style={{ marginTop: 20 }} color="primary">
                Submit
              </Button>
              {successPassword && (
                <div style={{ marginTop: 20 }}>
                  <SnackbarContent
                    message={"Your password is updated with success"}
                    color="success"
                  />
                </div>
              )}
            </ValidatorForm>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Profile;
