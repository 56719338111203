import React, { useState, useEffect } from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import XLSX from "xlsx";
import moment from "moment";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DateRangeIcon from "@material-ui/icons/DateRange";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { requestsActions } from "redux/requests/actions";
import { Checkbox, LinearProgress, MenuItem, Select } from "@material-ui/core";
import { excelRecords } from "redux/requests/selectors";
import { isAdmin } from "../../constants";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  input: {
    paddingTop: 0,
  },
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles,
});

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
}

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export const ExportEscortInvoice = () => {
  const dispatch = useDispatch();
  const [selectedClient, setSelectedClient] = useState(-1);
  const { clients } = useSelector((state) => state.clients);
  const [dateRange, setDateRange] = React.useState({});
  const { filterRequestsInvoices, saving } = useSelector(
    (state) => state.requests
  );
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen(!open);

  useEffect(() => {
    let requests = [];
    for (let k = 0; k < filterRequestsInvoices.length; k++) {
      const prop = filterRequestsInvoices[k];
      const key = k;
      const clientTransport = prop.client_transport[0];
      let uniqueAgents = "";
      let uniquesCars = "";
      // if (prop.agent_transport.length > 0) {
      //   const agents = prop.agent_transport.map(x => x.agent);
      //   uniqueAgents = removeDuplicates(agents, "id")
      //     .map(x => x.first_name + " " + x.last_name)
      //     .join(" / ");
      // }
      const request_shift = prop.request_shift;
      for (let i = 0; i < request_shift.length; i++) {
        const teams = request_shift[i].request_team;
        for (let j = 0; j < teams.length; j++) {
          const agents = teams[j].agents;
          const records = excelRecords(
            prop.request_history.filter((x) => x.request_team_id == teams[j].id)
          );
          prop.checked = true;
          requests.push({
            start_date: moment(request_shift[i].start_date).format(
              "MM/DD/YYYY"
            ),
            start_time: moment(request_shift[i].start_date).format("HH:mm"),
            pup: request_shift[i].pup,
            dop: request_shift[i].dop,
            status: prop.status == 3 ? "YES" : "NO",
            escort_driver_1: `${agents[0].agent.first_name} ${agents[0].agent.last_name}`,
            escort_driver_2: agents[1]
              ? `${agents[1].agent.first_name} ${agents[1].agent.last_name}`
              : "-",
            escort_plate: teams[j].security_car.plate_number,
            fixed: "",
            waiting_pup:
              records.waiting_pup != "0:0:" ? records.waiting_pup : "",
            waiting_dop:
              records.waiting_dop != "0:0:" ? records.waiting_dop : "",
            waiting_escort:
              records.waiting_escort != "0:0:" ? records.waiting_escort : "",
            waiting_total:
              records.waiting_total != "0:0:" ? records.waiting_total : "",
            waiting_cost_per_hour: "",
            total_waiting_costs: "",
            cancellation_cost: "",
            costLabel: records.costLabel,
            total_cost: "",
            created_by: prop.created_by.email,
            type_of_security_escort: "",
            identifier: prop.identifier,
            waiting_total_hours:
              records.waiting_total != "0:0:" ? records.waiting_total : "",
            checked: prop.checked,
            id: key,
          });
        }
      }
    }
    setData(requests);
  }, [filterRequestsInvoices]);

  const onSelectEscort = (event, id) => {
    const copyData = [...data];
    copyData.filter((x) => x.id == id)[0].checked = event.target.checked;
    setData(copyData);
  };

  const onRequestFilter = (range) => {
    toggle();
    setDateRange(range);
    if (range.startDate) {
      const startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
      const endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");
      dispatch(
        requestsActions.filterEscortInvoiceByDate({
          startDate,
          endDate,
          clientId: selectedClient,
        })
      );
    }
  };

  const onExportEscort = () => {
    const output = data
      .filter((x) => x.checked == true)
      .map(function(obj) {
        // delete obj.id;
        // delete obj.checked;
        return Object.keys(obj)
          .filter((key) => key !== "id" && key !== "checked")
          .map(function(key) {
            return obj[key];
          });
      });
    dispatch(requestsActions.exportEscortsInvoice({ body: output, dateRange }));
  };

  const classes = useStyles();

  const handleChangeClient = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    const startDate = moment(dateRange.startDate).format("YYYY-MM-DD 00:00");
    const endDate = moment(dateRange.endDate).format("YYYY-MM-DD 23:59");
    dispatch(
      requestsActions.filterEscortInvoiceByDate({
        startDate,
        endDate,
        clientId,
      })
    );
  };

  return (
    <GridContainer justify="center">
      {isAdmin && (
        <GridItem style={{ alignItems: "center" }}>
          <Select
            placeholder="Clients"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedClient}
            onChange={handleChangeClient}
          >
            <MenuItem value={-1}>All clients</MenuItem>
            {clients.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
      )}
      <GridItem xs={12}>
        <Button onClick={toggle} color="primary" round>
          <DateRangeIcon />
          {dateRange.startDate
            ? `From ${moment(dateRange.startDate).format(
                "DD/MM/YYYY"
              )} To ${moment(dateRange.endDate).format("DD/MM/YYYY")}`
            : "Select Loading Dates"}
        </Button>
        <DateRangePicker
          open={open}
          color="primary"
          toggle={toggle}
          onChange={(range) => onRequestFilter(range)}
        />
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <FileCopyIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Export Escorts ({data.length})
            </h4>
            <div style={{ float: "right" }}>
              <Button
                onClick={onExportEscort}
                disabled={
                  data.filter((x) => x.checked == true).length === 0 || saving
                }
                color="primary"
              >
                Export {data.filter((x) => x.checked == true).length} Escorts
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {saving && <LinearProgress color="primary" />}
            <ReactTable
              FilterComponent={({ filter, onChange }) => (
                <CustomInput
                  value={filter ? filter.value : ""}
                  onChange={(value) => onChange(value)}
                  id="success"
                  // labelText="Plate number"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.input,
                  }}
                  inputProps={{
                    value: filter ? filter.value : "",
                  }}
                />
              )}
              data={data}
              filterable
              defaultFilterMethod={filterCaseInsensitive}
              columns={[
                {
                  Header: "Selected",
                  Cell: (props) => (
                    <Checkbox
                      color="primary"
                      onChange={(e) => onSelectEscort(e, props.original.id)}
                      checked={props.original.checked}
                    />
                  ),
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "#Reference",
                  accessor: "identifier",
                },
                {
                  Header: "Date at PUP",
                  accessor: "start_date",
                },
                {
                  Header: "Time at PUP",
                  accessor: "start_time",
                },
                {
                  Header: "From",
                  accessor: "pup",
                  width: 250,
                },
                {
                  Header: "To",
                  accessor: "dop",
                  width: 250,
                },
                {
                  Header: "Was this escort cancelled?",
                  accessor: "status",
                },
                {
                  Header: "Escort Driver I",
                  accessor: "escort_driver_1",
                },
                {
                  Header: "Escort Driver II",
                  accessor: "escort_driver_2",
                },
                {
                  Header: "Escort Vehicle",
                  accessor: "escort_plate",
                },
                {
                  Header: "Waiting at PUP",
                  accessor: "waiting_pup",
                },
                {
                  Header: "Waiting at DOP",
                  accessor: "waiting_dop",
                },
                {
                  Header: "Waiting during escort",
                  accessor: "waiting_escort",
                },
                {
                  Header: "Total waiting hours",
                  accessor: "waiting_total",
                },
                {
                  Header: "Tol/Vignet costs",
                  accessor: "costLabel",
                },
                {
                  Header: "Requested By",
                  accessor: "created_by",
                  width: 250,
                },
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ExportEscortInvoice;
