import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router";

const renderRedirect = (props, redirect = "/") => {
  return (
    <Redirect
      to={{
        pathname: redirect,
        state: {
          from: props.location
        }
      }}
    />
  );
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const { user, auth } = useSelector(state => state.user);
  const redirectPath = `/${process.env.REACT_APP_ROLE}/dashboard`;
  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
  return (
    <Route
      {...rest}
      render={props =>
        !user.id || !auth.access_token || !isUserLoggedIn ? (
          <Component {...props} />
        ) : (
          renderRedirect(props, redirectPath)
        )
      }
    />
  );
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { auth, refreshing, user } = useSelector(state => state.user);
  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
  return (
    <Route
      {...rest}
      render={props =>
        user.status == 1 &&
        isUserLoggedIn &&
        (refreshing || auth.access_token) ? (
          <Component {...props} />
        ) : (
          renderRedirect(props, "/auth/login-page")
        )
      }
    />
  );
};

export { AuthRoute, ProtectedRoute };
