import { takeLatest, call, put, select } from "redux-saga/effects";
import { EBranchesActionTypes } from "./actions";
import axios from "../axios";

function* fetchBranches(action) {
  const { user } = yield select(state => state.user);
  try {
    const response = yield call(
      [axios, axios.get],
      `/api/branchesByClient/${action.payload}`
    );
    if (response && response.data && response.data.success) {
      yield put({
        type: EBranchesActionTypes.GET_BRANCHES_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: EBranchesActionTypes.GET_BRANCHES_FAILED });
    }
  } catch (e) {
    yield put({
      type: EBranchesActionTypes.GET_BRANCHES_FAILED,
      payload: e.response.statusText
    });
  }
}

function* saveBranch(action) {
  const { branches } = yield select(state => state.branches);
  let response;
  const request = action.payload;

  try {
    if (action.payload.id) {
      response = yield call(
        [axios, axios.put],
        `/api/branches/${action.payload.id}`,
        request
      );
    } else {
      response = yield call([axios, axios.post], `/api/branches`, request);
    }

    if (response && response.status === 200) {
      yield put({
        type: EBranchesActionTypes.SAVING_SUCCESS,
        payload: response.data.success
      });
      if (action.payload.id) {
        const branchIndex = branches.findIndex(x => x.id === action.payload.id);
        branches[branchIndex] = action.payload;
        yield put({
          type: EBranchesActionTypes.GET_BRANCHES_SUCCESS,
          payload: [...branches]
        });
      } else {
        const id = response.data.success.id;
        const branch = {
          id,
          ...action.payload
        };
        branches.unshift(branch);
        yield put({
          type: EBranchesActionTypes.GET_BRANCHES_SUCCESS,
          payload: [...branches]
        });
      }
    } else {
      yield put({ type: EBranchesActionTypes.SAVING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EBranchesActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* deleteBranch(action) {
  try {
    const response = yield call(
      [axios, axios.delete],
      `/api/branches/${action.payload}`
    );
    if (response.status === 200) {
      const { branches } = yield select(state => state.branches);

      yield put({
        type: EBranchesActionTypes.DELETING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: EBranchesActionTypes.GET_BRANCHES_SUCCESS,
        payload: branches.filter(x => x.id !== action.payload)
      });
    } else {
      yield put({ type: EBranchesActionTypes.DELETING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EBranchesActionTypes.DELETING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* requestsSaga() {
  yield takeLatest(EBranchesActionTypes.GET_BRANCHES, fetchBranches);
  yield takeLatest(EBranchesActionTypes.SAVE_BRANCH, saveBranch);
  yield takeLatest(EBranchesActionTypes.DELETE_BRANCH, deleteBranch);
}

export default requestsSaga;
