export const EDriversActionTypes = {
  GET_DRIVERS: "GET_DRIVERS",
  GET_DRIVERS_SUCCESS: "GET_DRIVERS_SUCCESS",
  GET_DRIVERS_FAILED: "GET_DRIVERS_FAILED",
  SAVE_DRIVER: "SAVE_DRIVER",
  DELETE_DRIVER: "DELETE_DRIVER",
  SAVING_SUCCESS: "SAVING_DRIVER_SUCCESS",
  SAVING_FAILED: "SAVING_DRIVER_FAILED",
  DELETING_SUCCESS: "DELETING_DRIVER_SUCCESS",
  DELETING_FAILED: "DELETING_DRIVER_FAILED",
  RESET_SUCCESS_DELETE: "RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE"
};

export const driversActions = {
  saveDriver: payload => ({
    type: EDriversActionTypes.SAVE_DRIVER,
    payload
  }),
  deleteDriver: payload => ({
    type: EDriversActionTypes.DELETE_DRIVER,
    payload
  }),
  fetchDrivers: () => ({
    type: EDriversActionTypes.GET_DRIVERS
  }),
  resetSuccessDelete: () => ({
    type: EDriversActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: EDriversActionTypes.RESET_SUCCESS_SAVE
  })
};
