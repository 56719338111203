/* eslint-disable no-use-before-define */
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `blue !important`
    }
  }
});

const CustomAutoCompleteInput = ({
  options,
  labelText,
  value,
  helperText,
  success,
  error,
  onChange,
  freeSolo,
  onSelect,
  filterKey,
  optionLabel,
  autocompleteValue,
  placeholder,
  noOptionsText,
  getOptionDisabled
}) => {
  const classes = useStyle();

  return (
    <Autocomplete
      id="grouped-demo"
      options={options}
      includeInputInList
      forcePopupIcon
      autoComplete
      getOptionDisabled={getOptionDisabled}
      autoHighlight={true}
      value={autocompleteValue}
      noOptionsText={noOptionsText}
      // inputValue={value || ""}
      onChange={(event, value) => onSelect && onSelect(value)}
      //onInputChange={e => e && onChange &&  onChange(e.target.value || "")}
      //groupBy={option => option.firstLetter}
      getOptionLabel={optionLabel || ""}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          // onChange ={(event) => {
          //   onChange(event.target.value)
          // }}
          InputLabelProps={{
            shrink: true,
            root: classes.cssOutlinedInput
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password"
          }}
          id="success"
          label={labelText}
          // // value={value}
          // helperText={helperText}
          // success={success}
          // error={error}
          fullWidth
        />
      )}
    />
  );
};

export default CustomAutoCompleteInput;
