import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import Table from "components/Table/Table.js";
import {
  getNewRequestRecords,
  getNewClientRequestRecords
} from "redux/requests/selectors";
import { isClient } from "../../../constants";

const RequestNewAgentRecords = ({ request }) => {
  const records = useSelector(state =>
    getNewRequestRecords(state, request.type)
  );
  const clientRecords = useSelector(state =>
    getNewClientRequestRecords(state, request.type)
  );
  if (isClient) {
    return (
      <Grid>
        <Table
          tableHeaderColor="primary"
          tableHead={["Total hours", "Total Kilometers", "Car"]}
          tableData={clientRecords}
          coloredColls={[1, 2]}
          colorsColls={["primary"]}
        />
      </Grid>
    );
  }
  return (
    <Grid>
      <Table
        tableHeaderColor="primary"
        tableHead={[
          "Agent Name",
          "Total costs",
          "Total hours",
          "Total waiting time",
          "Total Kilometers",
          "Car"
        ]}
        tableData={records}
        coloredColls={[1, 2]}
        colorsColls={["primary"]}
      />
    </Grid>
  );
};

export default RequestNewAgentRecords;
