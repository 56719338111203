import { takeLatest, call, put, select } from "redux-saga/effects";
import { EAccountsActionTypes } from "./actions";
import axios from "../axios";
function* fetchAccounts(action) {
  try {
    const response = yield call(
      [axios, axios.get],
      `/api/subcAccountsBySubcontractor/${action.payload}`
    );
    if (response && response.data && response.data.success) {
      yield put({
        type: EAccountsActionTypes.GET_ACCOUNTS_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: EAccountsActionTypes.GET_ACCOUNTS_FAILED });
    }
  } catch (e) {
    yield put({
      type: EAccountsActionTypes.GET_ACCOUNTS_FAILED,
      payload: e.response.statusText
    });
  }
}
function* saveAccount(action) {
  let { accounts } = yield select(state => state.subcaccounts);
  let response;
  try {
    if (action.payload.id) {
      response = yield call(
        [axios, axios.put],
        `/api/subcAccounts/${action.payload.id}`,
        action.payload
      );
    } else {
      response = yield call(
        [axios, axios.post],
        `/api/subcAccounts`,
        action.payload
      );
    }

    if (response.data && !response.data.error) {
      yield put({
        type: EAccountsActionTypes.SAVING_SUCCESS,
        payload: response.data.success
      });
      if (action.payload.id) {
        const accountIndex = accounts.findIndex(
          x => x.id === action.payload.id
        );
        accounts[accountIndex] = action.payload;
        yield put({
          type: EAccountsActionTypes.GET_ACCOUNTS_SUCCESS,
          payload: [...accounts]
        });
      } else {
        const id = response.data.success.id;
        const account = {
          id,
          ...action.payload
        };
        accounts.unshift(account);
        yield put({
          type: EAccountsActionTypes.GET_ACCOUNTS_SUCCESS,
          payload: [...accounts]
        });
      }
    } else {
      yield put({
        type: EAccountsActionTypes.SAVING_FAILED,
        payload: response.data.error.message
      });
    }
  } catch (e) {
    yield put({
      type: EAccountsActionTypes.SAVING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* deleteAccount(action) {
  try {
    const response = yield call(
      [axios, axios.delete],
      `/api/subcAccounts/${action.payload}`
    );
    if (response.status === 200) {
      const { accounts } = yield select(state => state.subcaccounts);
      const accountIndex = accounts.findIndex(x => x.id === action.payload);
      accounts[accountIndex].status = "0";

      yield put({
        type: EAccountsActionTypes.DELETING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: EAccountsActionTypes.GET_ACCOUNTS_SUCCESS,
        payload: [...accounts]
      });
    } else {
      yield put({ type: EAccountsActionTypes.DELETING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EAccountsActionTypes.DELETING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* activateAccount(action) {
  try {
    const response = yield call(
      [axios, axios.put],
      `/api/subcAccounts/activate/${action.payload}`
    );
    if (response.status === 200) {
      const { accounts } = yield select(state => state.subcaccounts);

      const accountIndex = accounts.findIndex(x => x.id === action.payload);
      accounts[accountIndex].status = "1";
      yield put({
        type: EAccountsActionTypes.ACTIVATING_SUCCESS,
        payload: response.data.success
      });
      yield put({
        type: EAccountsActionTypes.GET_ACCOUNTS_SUCCESS,
        payload: [...accounts]
      });
    } else {
      yield put({ type: EAccountsActionTypes.ACTIVATING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EAccountsActionTypes.ACTIVATING_FAILED,
      payload: e.response.statusText
    });
  }
}
function* resetAccount(action) {
  try {
    const response = yield call(
      [axios, axios.put],
      `/api/subcAccounts/reset/${action.payload}`
    );
    if (response.status === 200) {
      yield put({
        type: EAccountsActionTypes.RESETTING_SUCCESS,
        payload: response.data.success
      });
    } else {
      yield put({ type: EAccountsActionTypes.RESETTING_FAILED });
    }
  } catch (e) {
    yield put({
      type: EAccountsActionTypes.RESETTING_FAILED,
      payload: e.response.statusText
    });
  }
}

function* requestsSaga() {
  yield takeLatest(EAccountsActionTypes.GET_ACCOUNTS, fetchAccounts);
  yield takeLatest(EAccountsActionTypes.SAVE_ACCOUNT, saveAccount);
  yield takeLatest(EAccountsActionTypes.DELETE_ACCOUNT, deleteAccount);
  yield takeLatest(EAccountsActionTypes.ACTIVATE_ACCOUNT, activateAccount);
  yield takeLatest(EAccountsActionTypes.RESET_ACCOUNT, resetAccount);
}

export default requestsSaga;
