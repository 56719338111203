export const EAccountsActionTypes = {
  GET_ACCOUNTS: "GET_ACCOUNTS",
  GET_ACCOUNTS_SUCCESS: "GET_ACCOUNTS_SUCCESS",
  GET_ACCOUNTS_FAILED: "GET_ACCOUNTS_FAILED",
  SAVE_ACCOUNT: "SAVE_ACCOUNT",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  SAVING_SUCCESS: "SAVING_ACCOUNT_SUCCESS",
  SAVING_FAILED: "SAVING_ACCOUNT_FAILED",
  DELETING_SUCCESS: "DELETING_ACCOUNT_SUCCESS",
  DELETING_FAILED: "DELETING_ACCOUNT_FAILED",
  RESET_SUCCESS_DELETE: "RESET_SUCCESS_DELETE",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE",
  ACTIVATE_ACCOUNT: "ACTIVATE_ACCOUNT",
  ACTIVATING_SUCCESS: "ACTIVATING_ACCOUNT_SUCCESS",
  RESETTING_SUCCESS: "RESETTING_ACCOUNT_SUCCESS",
  RESETTING_FAILED: "RESETTING_ACCOUNT_FAILEd",
  ACTIVATING_FAILED: "ACTIVATING_ACCOUNT_FAILED",
  RESET_SUCCESS_ACTIVATE: "RESET_SUCCESS_ACTIVATE",
  RESET_SUCCESS_RESET: "RESET_SUCCESS_RESET",
  RESET_ACCOUNT: "RESET_ACCOUNT"
};

export const accountsActions = {
  saveAccount: payload => ({
    type: EAccountsActionTypes.SAVE_ACCOUNT,
    payload
  }),
  deleteAccount: payload => ({
    type: EAccountsActionTypes.DELETE_ACCOUNT,
    payload
  }),
  fetchAccounts: payload => ({
    type: EAccountsActionTypes.GET_ACCOUNTS,
    payload
  }),
  resetSuccessDelete: () => ({
    type: EAccountsActionTypes.RESET_SUCCESS_DELETE
  }),
  resetSuccessSave: () => ({
    type: EAccountsActionTypes.RESET_SUCCESS_SAVE
  }),
  activateAccount: payload => ({
    type: EAccountsActionTypes.ACTIVATE_ACCOUNT,
    payload
  }),
  resetActivateDelete: () => ({
    type: EAccountsActionTypes.RESET_SUCCESS_ACTIVATE
  }),
  resetAccount: payload => ({
    type: EAccountsActionTypes.RESET_ACCOUNT,
    payload
  }),
  resetSuccessReset: () => ({
    type: EAccountsActionTypes.RESET_SUCCESS_RESET
  })
};
